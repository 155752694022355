/* eslint-disable require-jsdoc */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Modal from '../../../../../core/Modal';
import PaymentMethodTemplate from './PaymentMethodTemplate';
import DashboardAccordion from '../../DashboardAccordion';
import '../PaymentOptions/paymentOptions.scss';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const ModalType = {
  DirectDeposit: 'DirectDeposit',
  PayCard: 'PayCard',
  PaperCheck: 'PaperCheck',
};

/**
 * @description - Payment Option Component
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */

const PaymentOptions = ({ data, fields, t }) => {
  const {
    directDepositTitle,
    directDepositDescription,
    directDepositConsent,
    directDepositFields,
    payCardTitle,
    payCardDescription,
    payCardInfo,
    payCardConsent,
    paperCheckTitle,
    paperCheckDescription,
    paperCheckInfo,
    paperCheckConsent,
    CancelCTAText,
    SubmitCTAText,
    infoIcon,
  } = fields;

  const [currentModal, setCurrentModal] = useState();
  const profileInfoReducer = useSelector((state) => state.profileInfoReducer);
  const userData = profileInfoReducer?.data?.PersonalDetails.data[0];
  const entity = userData?.bhBranchEntity;

  const handleModalOpen = (type) => {
    setCurrentModal(type);
  };

  const isModalOpen = (type) => {
    return currentModal === type;
  };

  const handleCloseModal = () => {
    setCurrentModal(null);
  };

  return (
    <div className="payment-options">
      {Object.values(ModalType).map((modalType) => (
        <Modal
          key={modalType}
          showModal={isModalOpen(modalType)}
          handleModal={handleCloseModal}
          size="md"
          hideFooter
          backdrop="static"
        >
          {modalType === 'DirectDeposit' ? (
            <PaymentMethodTemplate
              key="body"
              type={modalType}
              t={t}
              fields={fields}
              icons={infoIcon}
              userData={userData}
              items={directDepositFields}
              handleModal={handleCloseModal}
              title={directDepositTitle}
              description={directDepositDescription}
              consent={directDepositConsent}
              submitCta={SubmitCTAText?.value}
              cancelCta={CancelCTAText?.value}
            />
          ) : (
            <PaymentMethodTemplate
              key="body"
              type={modalType}
              t={t}
              fields={fields}
              icons={infoIcon}
              userData={userData}
              handleModal={handleCloseModal}
              title={
                modalType === ModalType.PayCard ? payCardTitle : paperCheckTitle
              }
              description={
                modalType === ModalType.PayCard
                  ? payCardDescription
                  : paperCheckDescription
              }
              info={modalType === ModalType.PayCard ? payCardInfo : paperCheckInfo}
              consent={
                modalType === ModalType.PayCard
                  ? payCardConsent?.value
                  : paperCheckConsent?.value
              }
              submitCta={SubmitCTAText?.value}
              cancelCta={CancelCTAText?.value}
            />
          )}
        </Modal>
      ))}
      {data.map((item) => (
        <>
          {(item?.fields?.entity?.value === 'MPUSA' ||
            item?.fields?.entity?.value === 'MPCAN') &&
            (entity === 'MPUSA' || entity === 'MPCAN') && (
              <div className="row row-divider" key={item?.id}>
                <div className="col-md-7 col-lg-7 col-xl-8">
                  <p className="title pb-1">{item?.fields?.title?.value}</p>
                  <p className="sub-title">
                    <RichText
                      field={item?.fields?.description}
                      className="description"
                      id="infodesc"
                    />
                  </p>
                </div>
                <div className="col-md-5 col-lg-5 col-xl-4 px-4">
                  {item?.fields?.ctaText?.value && (
                    <button
                      onClick={() => handleModalOpen(item?.displayName)}
                      className="primary-button orange-sd float-right payment-option-btn"
                      disabled={
                        userData?.customEncryptedText1 === '071922476' &&
                        item?.name === 'PayCard'
                      }
                    >
                      {item?.fields?.ctaText?.value}
                    </button>
                  )}
                </div>
              </div>
            )}

          {item?.fields?.entity?.value === 'Others' &&
            entity !== 'MPUSA' &&
            entity !== 'MPCAN' && (
              <div className="row row-divider" key={item?.id}>
                <div className="col-md-7 col-lg-7 col-xl-8">
                  <p className="title pb-1">{item?.fields?.title?.value}</p>
                  <p className="sub-title">
                    <RichText
                      field={item?.fields?.description}
                      className="description"
                      id="infodesc"
                    />
                  </p>
                </div>
                <div className="col-md-5 col-lg-5 col-xl-4 px-4">
                  {item?.fields?.ctaText?.value && (
                    <button
                      onClick={() => handleModalOpen(item?.displayName)}
                      className="primary-button orange-sd float-right payment-option-btn"
                      disabled={
                        userData?.customEncryptedText1 === '071922476' &&
                        item?.name === 'PayCard'
                      }
                    >
                      {item?.fields?.ctaText?.value}
                    </button>
                  )}
                </div>
              </div>
            )}
        </>
      ))}
    </div>
  );
};

/**
 * @description - Change Payment Option Component
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const ChangePaymentOption = ({ fields, t }) => {
  const { changePaymentTitle, changePayment } = fields;
  const [open, setOpen] = useState(true);

  return (
    <div>
      <DashboardAccordion
        id={changePaymentTitle?.value}
        title={changePaymentTitle}
        isOpen={open}
        handleToggle={() => setOpen(!open)}
        content={<PaymentOptions data={changePayment} fields={fields} t={t} />}
      />
    </div>
  );
};

ChangePaymentOption.defaultProps = {
  t: (val) => val,
  fields: {},
};

ChangePaymentOption.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
};

export default withTranslation()(ChangePaymentOption);
