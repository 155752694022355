/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { API_STATUS_CODES, DASHBOARD } from '../../../../../constants';
import RecruiterCard from './RecruiterCard';
import LocatorCard from './LocatorCard';
import { dataFetcher } from '../../../../../dataFetcher';
import { isNorthAmericanCountry } from '../../../../../utils/helperUtils';

const DashboardRecruiterCard = ({ t, fields, sitecoreContext }) => {
  const { items = {} } = fields;
  const locatorCardDetails = items?.length > 0 ? items[0] : {};
  const recruiterCardDetails = items?.length > 0 ? items[1] : {};
  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);
  const [loading, setLoading] = useState(true);
  const [corpDetails, setCorpDetails] = useState({});
  const [locatorData, setLocatorData] = useState([]);
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';

  useEffect(() => {
    setLoading(true);
    if (profileInfo && profileInfo.Id && profileInfo.PersonalDetails) {
      const assignmentsData = profileInfo?.PersonalDetails;
      const recId = assignmentsData?.data[0]?.owner?.id;
      if (recId) {
        dataFetcher(
          `${DASHBOARD.GET_RECRUITER_DETAILS.RECRUITER_DETAILS}?entity=CorporateUser&id=${recId}&forceUpdate=false`,
          'GET'
        )
          .then((response) => {
            if (response.status === API_STATUS_CODES.OK) {
              setCorpDetails(response?.data?.data?.CorporateUserDetails?.data[0]);
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          });
      }
      setLoading(false);
    }
    profileInfo && setLocatorData(profileInfo?.BranchDetails);
  }, [profileInfo]);

  return (
    <>
      {profileInfo && isNorthAmericanCountry(countryName) ? (
        <section className="dashboard-cards">
          <div className="card-deck">
            {profileInfo?.isAssociate && (
              <RecruiterCard
                cardDetails={recruiterCardDetails}
                assignment={corpDetails}
                loading={loading}
              />
            )}
            {profileInfo?.BranchDetails && (
              <LocatorCard
                cardDetails={locatorCardDetails}
                location={locatorData}
                t={t}
              />
            )}
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default withTranslation()(withSitecoreContext()(DashboardRecruiterCard));
