/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Table from '../../../../../core/Table';
import DashboardAccordion from '../../DashboardAccordion';
import { DATE_FORMATS } from '../../../../../../utils/enums';
import moment from 'moment';
import { checkMobileSource, dataURItoBlob } from '../../../../../../utils/helperUtils';
import { saveAs } from 'file-saver';
/**
 * @description - MyTaxForms .
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const MyTaxForms = ({ t, fields, sitecoreContext, loading, data }) => {
  const { icon } = fields;
  const [taxFormsData, setTaxFormsData] = useState([]);
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';

  /**
   * @description - call to card scroll api
   * @param {object} queryParams - request body
   * @returns {undefined}
   */
  const columns = [
    {
      selector: 'createdOn',
      name: t('created-on'),
      grow: 1.5,
      cell: (row) => {
        return (
          <div className="taxformdata">
            {countryName === 'ca'
              ? moment(row?.createdOn).format(DATE_FORMATS.CANADA_FORMAT)
              : moment(row?.createdOn).format(DATE_FORMATS.DEFAULT_US_MM_DD_YYYY)}
          </div>
        );
      },
    },
    {
      selector: 'title',
      name: t('form'),
      grow: 3,
      cell: (row) => {
        return <div className="taxformdata">{row?.title}</div>;
      },
    },
    {
      selector: 'fileId',
      name: t('form-number'),
      grow: 1.5,
      cell: (row) => {
        return <div className='taxform-filedid'>{row?.fileId}</div>;
      },
    },

    {
      selector: 'pdf',
      name: '',
      grow: 1.5,
      /**
       * @description Function to generate Action column template.
       * @param {Object} row - Job details.
       * @returns {Node} HTML Template.
       */
      cell: function actionColumn(row) {
        return (
          <div className="view-or-remove">
            <button
              onClick={() => openPdfInNewTab(row?.pdf,row?.title)}
              className="button-text taxformbutton"
            >
              {t('view')}
            </button>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (data && data.length > 0) {
      prepareData(data);
    }
  }, [data]);
  const prepareData = (responseData) => {
    const taxFromsTableData =
      responseData &&
      responseData.map((object, index) => ({
        ...object,
        id: index + 1,
      }));
    setTaxFormsData(taxFromsTableData);
  };

  const binaryToBlob = (data) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const url = URL.createObjectURL(blob, { oneTimeOnly: true });

    return window.open(url, '_blank');
  };

  const openPdfInNewTab = (pdfData, title) => {
    if(checkMobileSource()){
      const b64 = pdfData;
      const contentType = 'application/pdf'
      const blob = dataURItoBlob(b64, contentType);
      saveAs(blob, title);
    }else{
      binaryToBlob(pdfData);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        icon={icon}
        row={taxFormsData}
        pagination={taxFormsData?.length > 5 ? true : false}
        noHeader={true}
        showHeader={false}
        noTableHead={false}
        progressPending={loading}
        noDataMessage={t('empty-tax-forms')}
      />
    </>
  );
};
const MyTaxFormsTable = ({ fields, t, profileInfo, loading, data }) => {
  const { title } = fields;
  const [open, setOpen] = useState(true);

  return (
    <div>
      <DashboardAccordion
        id={title?.value}
        title={title}
        isOpen={open}
        handleToggle={() => setOpen(!open)}
        content={
          <MyTaxForms
            loading={loading}
            data={data}
            profileInfo={profileInfo}
            fields={fields}
            t={t}
          />
        }
      />
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(MyTaxFormsTable));
