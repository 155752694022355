/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React from 'react';
import { Text, mediaApi } from '@sitecore-jss/sitecore-jss-react';

const RecruiterCard = ({ cardDetails, assignment }) => {
  const { fields = {} } = cardDetails;
  const { title, emailIcon, phoneIcon } = fields;
  return (
    <>
      <div className="card col-md-6">
      {assignment !== undefined &&
        (<div className="card-body recruiter-card">
          {title && <Text field={title} tag="h5" className="title" role="heading" />}
          {assignment?.firstName && (
            <div className="recruiter-name">{assignment?.firstName} {assignment?.lastName}</div>
          )}
          {assignment?.phone && (
            <a
              href={`tel: ${assignment?.phone}`}
              className="recruiter-card-icon"
              aria-label="Phone number opens in a new window/tab"
            >
              {phoneIcon?.value?.src || phoneIcon?.value?.alt ? (
                <img
                  className={phoneIcon?.value?.src ? 'phone-icon' : ''}
                  src={
                    phoneIcon?.value?.src &&
                    mediaApi.updateImageUrl(phoneIcon?.value?.src)
                  }
                  alt={phoneIcon?.value?.alt ?? 'phone'}
                />
              ) : (
                <div className="icon">
                  <div className="icon-phone"></div>
                </div>
              )}
              <div className="phone-text">{assignment?.phone}</div>
            </a>
          )}
          {assignment?.email && (
            <a
              href={`mailto: ${assignment?.email}`}
              className={`recruiter-card-icon`}
              aria-label="Email opens in a new window/tab"
            >
              {emailIcon?.value?.src || emailIcon?.value?.alt ? (
                <img
                  className={emailIcon?.value?.src ? 'email-icon' : ''}
                  src={
                    emailIcon?.value?.src &&
                    mediaApi.updateImageUrl(emailIcon?.value?.src)
                  }
                  alt={emailIcon?.value?.alt ?? 'email'}
                />
              ) : (
                <div className="icon">
                  <div className="icon-email"></div>
                </div>
              )}
              <div className="email-text">{assignment?.email}</div>
            </a>
          )}
        </div>)}
      </div>
    </>
  );
};

export default RecruiterCard;
