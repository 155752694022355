import React, { useState } from 'react';
import ModalComponent from '../../../../core/Modal';
import { withTranslation } from 'react-i18next';
import Button from '../../../../core/globals/buttons/Button';

import { useSelector,useDispatch } from 'react-redux';
import { isNullorEmpty } from '../../../../../../src/utils/helperUtils';
import { toast } from 'react-toastify';
import { saveCandidateDetails } from '../../../../../services/apiServices/candidateService';
import { API_STATUS_CODES, SAVE_CANDIDATE_ATTACHMENT, RESUME_PDF_API, CREATE_PROFILE } from '../../../../../constants';
import { dataFetcher } from '../../../../../dataFetcher';

const GeneratePdfModal = ({ t,countryName }) => {
  const [showModal, setShowModal] = useState(false);
  const [inputDisable, setInputDisable] = useState(false);
  const [saveClickStatus, setSaveClickStatus] = useState(false);
  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);
  const buildProfileReducer = useSelector((state) => state.buildProfileFormReducer);
  const resumeListLength = buildProfileReducer?.resumeLength

  const [pdfValue, setpdfValue] = useState('');
  const checkValidResumeName = profileInfo?.AttachmentDetails.find(
    (i) =>
      i.name.substring(0, i.name.lastIndexOf('.')).toLowerCase() ===
      pdfValue.toLowerCase()
  );

  const handleModal = () => {
    setShowModal(!showModal);
    const date = new Date()
    //us format - MM/DD/YYYY
    const timestampUS = [date.getMonth()+1,date.getDate(),
               date.getFullYear()].join('')+'_'+
              [date.getHours(),
               date.getMinutes(),
               date.getSeconds()].join('');
    //ca format - YYYY/MM/DD 
    const timestampCA = [date.getFullYear(),date.getMonth()+1,
                date.getDate()].join('')+'_'+
               [date.getHours(),
                date.getMinutes(),
                date.getSeconds()].join('');
    setpdfValue(countryName === 'ca' ? t('default-resume-title') + timestampCA : t('default-resume-title') + timestampUS );
  };

  const resumeExceeds = () => {
    toast.error(t('resume-limit-exceeds'), {
      position: 'top-center',
    });
  }


// new added
const checkValidation = !isNullorEmpty(checkValidResumeName)
? Object.keys(checkValidResumeName).length
: '0';
const checkEmpty = pdfValue === '' ? 0 : 1;
const checkDuplicates = checkEmpty > 0 && checkValidation > 0;
const checkTitleEmpty = checkEmpty < 1 && checkValidation < 1;
//const checkBoth = checkDuplicates === true || checkTitleEmpty === true;
const onSubmit = () => {
  if(!checkValidResumeName){
    downloadPdfFromApi()
  }
}

// new added

const dispatch = useDispatch();
const downloadPdfFromApi = () => {
  if(resumeListLength < 5){
    setSaveClickStatus(true);
    setInputDisable(true)
    const params = {
      "email" : profileInfo?.PersonalDetails?.data[0]?.email,
      "id" : profileInfo?.PersonalDetails?.data[0]?.id
    }
    dataFetcher(RESUME_PDF_API.RESUME_PDF, 'GET', {}, params).then((response) => {
      const byteCharacters = atob(response.data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const formData = new FormData();

      formData.append('Resume', blob, `${pdfValue}.pdf`);
  
      formData.append(
        'profileData',
        JSON.stringify({
          EditCoverLetters: { Resume: 'upload' },
        })
      );

      if(formData){
        uploadPDFToAPI(formData)
      }
    },(error) => {
      handleModal();
      toast.error(error, {
        position: 'top-center',
      });
    });
  }else{
    toast.error(t('resume-limit-exceeds'), {
      position: 'top-center',
    });
  }
};

const uploadPDFToAPI = async (formData) => {
  dataFetcher(
    SAVE_CANDIDATE_ATTACHMENT,
    'POST',
    formData
  ).then(
    (res) => {
      if (res.status === API_STATUS_CODES.OK) {
        const inputParams = {
          entity: 'all',
          forceUpdate: false,
        };
        dataFetcher(
          `${CREATE_PROFILE.GET_CREATE_PROFILE.CANDIDATE_DETAILS}`,
          'GET',
          {},
          inputParams
        )
          .then((response) => {
            if (response?.data?.status === 1000) {
              saveCandidateDetails(response.data.data, dispatch);
                handleModal()
                toast.success(t('resume-saved-successfully'), {
                  position: 'top-center',
                });
                setSaveClickStatus(false);
                setInputDisable(false)
                // only for generate resume scenario
                setTimeout(() => {
                  window.location.reload();
                },3000)
            }
          })
          .catch((error) => {
            toast.error(error, {
              position: 'top-center',
            });
          });

      }
    },
    () => {
      handleModal();
      toast.error(t('error-message'), {
        position: 'top-center',
      });
    }
  );
};

  if (!profileInfo.PersonalDetails) {
    return null;
  }

  return (
    <>
      <Button
        text={t('generate-resume')}
        cssClass="button-enabled generatePdf"
        handleButtonClick={resumeListLength >= 5 ? resumeExceeds : handleModal}
        //isDisabled={resumeListLength >= 5 ? true : false}
      />
      <ModalComponent
        showModal={showModal}
        handleModal={handleModal}
        hideHeader={true}
        className="pdfModel"
        backdrop="static"
        keyboard={false}
      >
        <div key="body" className="generatePdfpoup mt-3">
          <div className="form-block complex">
            <input
              type="text"
              className="filled"
              value={pdfValue}
              onChange={(e) => setpdfValue(e.target.value)}
              disabled={inputDisable}
            />
            <label>
              <div className="label-text">{t('resume-title')}</div>
            </label>
            {checkDuplicates && (
              <div className="error-msg" role="alert">
                {t('resume-title-error')}
              </div>
            )}
            {checkTitleEmpty && (
              <div className="error-msg" role="alert">
                {t('resume-empty-title-error')}
              </div>
            )}
          </div>

          <p>{t('resume-text')}</p>            
      <>
      <Button
        text={t('resume-yes')}
        cssClass={`${
          !checkValidResumeName ? 'button-enabled orange-sd' : 'button-disabled'
        }`}
        handleButtonClick={onSubmit}
        isLoading={saveClickStatus}
        isDisabled={saveClickStatus || checkDuplicates || checkTitleEmpty}
      />

      <Button
        text={t('resume-no')}
        cssClass={`${!checkValidResumeName ? 'button-enabled' : 'button-disabled'}`}
        handleButtonClick={() => handleModal()}
        isDisabled={saveClickStatus}
      />
    </>
        </div>
        
      </ModalComponent>
    </>
  );
};

export default withTranslation()(GeneratePdfModal);
