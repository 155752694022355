import React, { useState, useEffect, useRef } from 'react';
import { clearInterval, setInterval } from 'worker-timers';

import { withTranslation } from 'react-i18next';
const CountdownTimer = ({
  t,
  handleModal,
  logoutUser,
  countdownTimerValue,
  counterValueInSeconds,
  sitecoreContext,
}) => {
  const refInstance = useRef(null);
  const [counter, setCountdown] = useState('');
  const getCounter = (e) => {
    const all = Date.parse(e) - Date.parse(new Date());
    const s = Math.floor((all / 1000) % 60);
    const m = Math.floor((all / 1000 / 60) % 60);
    return {
      all,
      s,
      m,
    };
  };
  const initCounter = (e) => {
    const { all, m, s } = getCounter(e);
    if (all >= 0) {
      setCountdown((m > 9 ? m : '0' + m) + ':' + (s > 9 ? s : '0' + s));
    }
  };
  const counterTime = (e) => {
    setCountdown(countdownTimerValue);
    if (refInstance.current) clearInterval(refInstance.current);
    const id = setInterval(() => {
      initCounter(e);
    }, 1000);
    refInstance.current = id;
  };
  const getTotalSecondsToExpire = () => {
    const addTotalSecondsToCurrentTime = new Date();
    addTotalSecondsToCurrentTime.setSeconds(
      addTotalSecondsToCurrentTime.getSeconds() + counterValueInSeconds
    );
    return addTotalSecondsToCurrentTime;
  };
  const currentLanguage = sitecoreContext?.route?.itemLanguage;

  useEffect(() => {
    counterTime(getTotalSecondsToExpire());
  }, []);
  const counterText =
    counter >= '01:00'
      ? currentLanguage === 'es'
        ? ''
        : t('minutes')
      : currentLanguage === 'es'
      ? ''
      : t('seconds');
  if (counter === '00:00') {
    clearInterval(refInstance.current);
    logoutUser();
  }
  return (
    <>
      <div className="modal-heading">
        <h4>{t('your-session-is-about-to-expire')}</h4>
      </div>
      <div className="modal-body">
        {t('you-will-be-logged-out-in')
          .replace('#timer', counter)
          .replace('#timetext', counterText)}
        <br />
        {t('do-you-want-to-stay-logged-in')}
      </div>
      <button onClick={handleModal} className="primary-button orange-sd ">
        {t('stay-signed-in')}
      </button>
      <div className="signOut">
        <button className="button-text underline" onClick={logoutUser}>
          {t('sign-out-now')}
        </button>
      </div>
    </>
  );
};
export default withTranslation()(CountdownTimer);
