import { SAVE_UPDATED_ZIP } from '../actions/actionTypes';

const initialState = {
  data: {},
};

/**
 * @description - Saves and clears the updated zip code.
 * @param {object} state - Input props
 * @param {object} action - Input props
 * @returns {object} - Object Structure.
 */
const updatedZipReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_UPDATED_ZIP.SAVE:
      return {
        ...state,
        data: action.payload,
      };
    case SAVE_UPDATED_ZIP.CLEAR:
      return initialState;
    default:
      return state;
  }
};
export default updatedZipReducer;
