/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { mediaApi, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import Error from '../../../../core/ErrorBoundary/Error';
import Loader from '../../../../core/ErrorBoundary/Loader';
import {
  clearProfileInfo,
  clearFormData,
} from '../../../../../redux/actions/actions';
import { getCandidateDetailsApi } from '../../../../../services/apiServices/candidateService';
import { withTranslation } from 'react-i18next';
import { checkMobileSource } from '../../../../../utils/helperUtils';

/**
 * @description - DashboardNav component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const DashboardNav = ({ fields, sitecoreContext, t }) => {
  const location = useLocation();
  const path = location.pathname;
  const { items = [] } = fields;
  const [profileData, setProfileData] = useState();
  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    accessibility: true,
    unslick: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.4,
          unslick: false,
        },
      },
    ],
  };
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';
  useEffect(() => {
    const navelement = document.getElementsByClassName('slick-slide slick-active');
    const navelementlist = document.getElementsByClassName('navContent-mobile');
    for (let i = 0; i < navelement.length; i++) {
      navelement[i].removeAttribute('tabIndex');
      navelementlist[i].removeAttribute('tabIndex');
    }
  }, []);

  useEffect(() => {
    if (profileInfo?.Id) {
      setprofileDataLoading(false);
      setProfileData(profileInfo);
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);
  const handleApiRefresh = () => {
    dispatch(clearProfileInfo());
    dispatch(clearFormData());
    setTimeout(() => getCandidateDetailsApi({}, dispatch), 500);
  };
  return (
    <>
      <div className={'dashboard-nav ' + brandName}>
        {/* Desktop */}
        <div className="only-desktop">
          {profileDataLoading ? (
            <Loader
              loaderMsg={t('fetching-your-data')}
              loaderWithMsg
              customLoaderStyle={{
                width: '4rem',
                height: '4rem',
                color: 'white',
              }}
              customTextStyle={{ color: 'white' }}
            />
          ) : error ? (
            <div className="apiError">
              <Error
                errorMsg={t('error-message')}
                t={t}
                refreshApi={true}
                handleApiRefresh={handleApiRefresh}
              />
            </div>
          ) : (
            <ul className="leftBar-content">
              {items.map((child, index) => {
                if (!profileData?.isAssociate && child?.fields?.isAssociate?.value) {
                  return null;
                }
                return (
                  <li key={index}>
                    <Link
                      key={child?.id}
                      to={child?.fields?.url?.value?.href}
                      aria-label={`Go to ${child?.fields?.title?.value}`}
                      className={
                        path == child?.fields?.url?.value?.href ? 'active' : ''
                      }
                      aria-current={
                        path == child?.fields?.url?.value?.href ? 'page' : false
                      }
                      key={index}
                    >
                      <span className="nav-icon">
                        <img
                          className={
                            child?.fields?.icon?.value?.src
                              ? 'dashboard-nav-image'
                              : ''
                          }
                          src={
                            child?.fields?.icon?.value?.src &&
                            mediaApi.updateImageUrl(child?.fields?.icon?.value?.src)
                          }
                          alt=""
                        />
                      </span>
                      <span className="nav-text">{child?.fields?.title?.value}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        {/* Mobile - Slick Slider */}
        <div className={`${checkMobileSource() ? 'd-none' : 'only-mobile'}`}>
          <ul className="leftBar-content">
            <Slider {...settings}>
              {items.map((child, index) => {
                if (!profileData?.isAssociate && child?.fields?.isAssociate?.value) {
                  return null;
                }
                return (
                  <li key={index} className="navContent-mobile">
                    <Link
                      key={child?.id}
                      to={child?.fields?.url?.value?.href}
                      className={
                        path == child?.fields?.url?.value?.href ? 'active' : ''
                      }
                      aria-current={
                        path == child?.fields?.url?.value?.href ? 'page' : false
                      }
                      key={index}
                    >
                      <span className="nav-icon">
                        <img
                          className={
                            child?.fields?.icon?.value?.src
                              ? 'dashboard-nav-image'
                              : ''
                          }
                          src={
                            child?.fields?.icon?.value?.src &&
                            mediaApi.updateImageUrl(child?.fields?.icon?.value?.src)
                          }
                          alt=""
                        />
                      </span>
                      <span className="nav-text">{child?.fields?.title?.value}</span>
                    </Link>
                  </li>
                );
              })}
            </Slider>
          </ul>
        </div>
      </div>
    </>
  );
};

DashboardNav.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

DashboardNav.defaultProps = {
  fields: {},
  sitecoreContext: {},
};

export default withTranslation()(withSitecoreContext()(DashboardNav));
