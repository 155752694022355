/* eslint-disable require-jsdoc */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import UserAssignments from '../UserAssignments';
import DashboardAccordion from '../../DashboardAccordion';
import { useHistory } from 'react-router-dom';

const UserCurrentAssignments = ({ fields, sitecoreContext, t }) => {
  const pageTitle = sitecoreContext?.route?.displayName;
  const { title, noContent } = fields;
  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);
  const [profileDataLoading, setProfileDataLoading] = useState(true);
  const [currentAssignments, setCurrentAssignments] = useState([]);
  const [open, setOpen] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (profileInfo && profileInfo.Id && profileInfo.AssociateDetails) {
      const assignmentsData = profileInfo?.AssociateDetails;
      const currentAssignments = assignmentsData?.filter((assignment) => {
        const startDate = moment(parseInt(assignment?.dateBegin));
        const endDate = moment(parseInt(assignment?.dateEnd));
        const currentDate = moment(Date.now());
        return (
          assignment.dateBegin &&
          startDate <= currentDate &&
          (assignment?.dateEnd ? endDate >= currentDate : true)
        );
      });
      const sortedAssignments = currentAssignments
        ? currentAssignments.sort((a, b) => a.dateBegin - b.dateBegin)
        : [];
      setCurrentAssignments(sortedAssignments);
      setProfileDataLoading(false);
    }
  }, [profileInfo]);
  useEffect(() => {
    if (profileInfo?.Id) {
      setProfileDataLoading(false);
      if (profileInfo && !profileInfo?.isAssociate) {
        history.push(t('page-not-found'));
      }
    } else if (profileInfo?.error) {
      setProfileDataLoading(false);
    }
  }, [profileInfo]);
  if (!profileInfo?.isAssociate) {
    return null;
  }

  return (
    <>
      {pageTitle && (
        <div className="row pt-2">
          <div className="col-md-12">
            <Text
              field={{ value: pageTitle }}
              tag="h2"
              className="page-title"
              id="info"
            />
          </div>
        </div>
      )}
      <div>
        <DashboardAccordion
          id={title?.value}
          title={title}
          isOpen={open}
          handleToggle={() => setOpen(!open)}
          content={
            <UserAssignments
              title={title}
              noContent={noContent}
              assignments={currentAssignments}
              profileDataLoading={profileDataLoading}
            />
          }
        />
      </div>
    </>
  );
};
UserCurrentAssignments.defaultProps = {
  t: (val) => val,
};

export default withSitecoreContext()(UserCurrentAssignments);
