/* eslint-disable require-jsdoc */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import TooltipComponent from '../../../../core/Tooltip';
import LocationIcon from '../../../../../assets/images/icon-location-man-green-pinpoint.svg';
import InfoIcon from '../../../../../assets/images/info-icon.svg';
import { LocationUrl } from '../../../../../utils/helperUtils';

const ContentTemplate = ({ title, assignment, content, isActive, onShow, t }) => {
  const {
    modifiedDateBegin,
    modifiedDateEnd,
    jobOrder,
    reportTo,
    correlatedCustomText2,
    correlatedCustomText3,
    TimeEntryMethod,
    payRate,
  } = content;

  const { clientCorporation, location } = jobOrder;
  const onKeyUp = (event) => {
    if (event.keyCode === 13) {
      onShow();
    }
  };

  return (
    <div
      className={`assignment-accordion-block  px-2 ${isActive ? 'active' : ''}`}
      onClick={onShow}
      onKeyUp={onKeyUp}
      tabIndex={0}
      role="button"
    >
      <div className="assignment-accordion-title-section">
        <div className="assignment-accordion-title">
          <h3 className="company">{title}</h3>
          <p>
            {/* {moment(parseInt(dateBegin)).format('D MMM YYYY')} -{' '}
            {moment(parseInt(dateEnd)).format('D MMM YYYY')} */}
            {modifiedDateBegin} - {modifiedDateEnd}
          </p>
        </div>
        <h4 className="client-name">{clientCorporation?.name}</h4>

        <div
          className="icon"
          onClick={onShow}
          onKeyUp={onKeyUp}
          tabIndex={0}
          role="button"
        ></div>
      </div>

      <div className="assignment-accordion-content">
        <div className="row">
          <div className="col-md-12 row">
            {payRate && (
              <div className="col-md-4 content">
                <p className="content-heading">{t('payrate')}</p>
                <p>{payRate}</p>
              </div>
            )}
            {correlatedCustomText2 && correlatedCustomText3 && (
              <div className="col-md-4 content">
                <p className="content-heading">{t('shedule')}</p>
                <p>
                  {correlatedCustomText2} - {correlatedCustomText3}
                </p>
              </div>
            )}

            {TimeEntryMethod && (
              <div className="col-md-4 content">
                <p className="content-heading">{t('time-entry-method')}</p>
                <p>
                  {TimeEntryMethod}

                  <TooltipComponent text={TimeEntryMethod}>
                    <img src={InfoIcon} alt="info" className="info-icon" />
                  </TooltipComponent>
                </p>
              </div>
            )}
            {assignment?.firstName && (
              <div className="col-md-4 content">
                <p className="content-heading">{t('manpower-poc')}</p>
                <p>
                  {' '}
                  {assignment?.firstName} {assignment?.lastName}
                </p>
              </div>
            )}

            {reportTo && (
              <div className="col-md-4 content">
                <p className="content-heading">{t('report-to')}</p>
                <p>{reportTo}</p>
              </div>
            )}
            {location?.address?.address1 && (
              <div className="col-md-4 content">
                <p className="content-heading">{t('work-location')}</p>
                <a
                  href={LocationUrl(location?.address)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="location"
                >
                  <img src={LocationIcon} alt="Location" className="location-icon" />
                  <div className="location-address-link">
                    {location?.address?.address1 && location?.address?.address1}{' '}
                    {location?.address?.address2 && location?.address?.address2}
                    <br /> {location?.address?.city && location?.address?.city},{' '}
                    {location?.address?.state && location?.address?.state}{' '}
                    {location?.address?.zip && location?.address?.zip}
                  </div>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ContentTemplate.defaultProps = {
  title: '',
  text: '',
  cssClass: '',
};

ContentTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  cssClass: PropTypes.oneOf(['', 'active']),
};

export default ContentTemplate;
