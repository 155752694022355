import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { showString } from '../../../../utils/helperUtils';
/**
 *
 * @param {*} param0 - site params
 * @returns {*} - data
 */
const Checkbox = ({
  id,
  name,
  text,
  required,
  register,
  errors,
  handleChange,
  isHideField,
  showBasedOnFieldName,
  watch,
  t
}) => {
  const showField = watch ? watch(showBasedOnFieldName) : true;
  return (
    <div
      className={`form-block checkbox ${isHideField && !showField ? 'hide' : ''}`}
    >
      <input
        type="checkbox"
        name={name}
        id={id}
        onChange={handleChange}
        ref={
          register
            ? register({
                required: (!isHideField || showField) && required ? required : false,
              })
            : () => {}
        }
      />
      <label className={errors && errors[name] && 'error'} htmlFor={id}>
        {text?.value ? <RichText field={text} editable={false} /> : showString(text)}
        {!text && <span className="sr-only">Checkbox Label</span>}
      </label>
      {errors && errors[name] && <div className="error-msg">{t('required-field')}</div>}
    </div>
  );
};

Checkbox.defaultProps = {
  name: '',
  id: 'id',
  text: 'Label',
  isHideField: false,
  errors: {},
  watch: () => {},
  register: () => {},
  handleChange: () => {},
  required: false,
};

Checkbox.propTypes = {
  /** Field identifier  */
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
  isHideField: PropTypes.bool,
  watch: PropTypes.func,
  required: PropTypes.bool,

  /** Label text */
  text: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
};

export default Checkbox;
