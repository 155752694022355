/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  SitecoreContext,
  SitecoreContextFactory,
} from '@sitecore-jss/sitecore-jss-react';
import { Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { useDispatch, useStore, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import componentFactory from './temp/componentFactory';
import RouteHandler from './RouteHandler';
import FocusWrapper from './utils/FocusWrapper';
import { loadState, saveState } from './redux/store';
import { authstates } from './utils/enums';
import {
  initializeState,
  setLocation,
  adb2cRedirect,
} from './redux/actions/loginActions';
import { setAuthHeader } from './utils/auth';
import authConfig from './services/auth/authConfig';
import { getCandidateDetailsApi } from './services/apiServices/candidateService';
import { initMap } from './redux/actions';
import Toaster from './components/core/Toaster';
import './components/core/CookieSettings/cookiesettings.scss';
import { getCandidatePopupDetails, isNullorEmpty } from './utils/helperUtils';
import LogoutListener from './components/core/PrivateRoute/LogoutListener';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
/**
 * @description - WrapAppRoot component.
 * @param {Node} AppRootComponent - Input params.
 * @returns {Node} - Router elements.
 */
const WrapAppRoot = (AppRootComponent) => {
  return (props) => {
    const { path, graphQLClient, Router } = props;
    const [channel, setChannel] = useState('');
    const [oneTrustKey, setOneTrustKey] = useState('');
    const [haveDetails, setHaveDetails] = useState(false);
    const [adb2cConfig, setConfigADB2C] = useState(null);
    const [language, setLanguage] = useState(null);
    const [googleApiKey, setGoogleAPIKey] = useState('');

    const dispatch = useDispatch();
    const store = useStore();
    const loginReducer = useSelector((state) => state.loginReducer);
    const scContextSubscribe = (context) => {
      if (channel === '') setChannel(context?.Country?.geoChannel);
      if (!googleApiKey && context?.Country?.googleAPIKey)
        setGoogleAPIKey(context?.Country?.googleAPIKey);
      if (adb2cConfig === null)
        setConfigADB2C({
          adb2c: context?.ADB2C,
          language: context?.language,
          path: props.path,
        });
      if (oneTrustKey === '') setOneTrustKey(context?.MetaData?.oneTrust);
      if (
        language === null ||
        (typeof language !== 'undefined' &&
          language &&
          language.toLowerCase() !== context?.language.toLowerCase())
      )
        setLanguage(
          context?.language && context?.language.toLowerCase() === 'nb'
            ? 'no'
            : context?.language
        );
    };

    useEffect(() => {
      // Accessing query parameters directly from the window's location
      const urlParams = new URLSearchParams(window.location.search);
      const source = urlParams.get('source');
      if (source === 'mobile') {
        localStorage.setItem('sourceDevice', source);
      }
    }, []);

    useEffect(() => {
      window.initGMap = () => dispatch(initMap());
      if (!window.google && channel) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAFdQLMUycnyxD6agbY8vCDU7rXe8PCXj0&channel=${channel}&libraries=places&callback=initGMap&language=en`;
        script.async = true;
        document.body.appendChild(script);
      }
    }, [channel]);

    useEffect(() => {
      // Set the axios interceptors.
      setAuthHeader(store);
      // Subscribe changes to store.
      store.subscribe(() => {
        if (sessionStorage) {
          const { loginReducer } = store.getState();
          saveState(loginReducer);
        }
      });
      const persistedState = sessionStorage ? loadState() : null;
      if (persistedState && persistedState.state !== authstates.PROGRESS) {
        dispatch(initializeState(persistedState));
      }

      // Browser back action from adb2c start.
      // let parsedUserSession = null;
      // if (typeof window !== 'undefined' && !path.hash.includes('state')) {
      //   const userSessionCopy = localStorage.getItem('userSessionCopy');
      //   parsedUserSession = userSessionCopy ? JSON.parse(userSessionCopy) : null;
      //   if (parsedUserSession) {
      //     dispatch(initializeState(parsedUserSession));
      //   }
      //   localStorage.removeItem('userSessionCopy');
      // }
      // Browser back action from adb2c end.
      if (path) dispatch(setLocation(path));
    }, []);

    useEffect(() => {
      if (adb2cConfig && adb2cConfig.adb2c) {
        authConfig(
          adb2cConfig.adb2c,
          adb2cConfig.path,
          dispatch,
          adb2cConfig.language
        );
      }
    }, [adb2cConfig]);

    useEffect(() => {
      if (
        !haveDetails &&
        loginReducer &&
        loginReducer?.state === authstates.AUTHENTICATED
      ) {
        const countryNameFromADB2C =
          loginReducer?.idToken?.idToken?.claims?.extension_ApplicationName;

        if (
          !countryNameFromADB2C === 'US_Manpower' ||
          !countryNameFromADB2C === 'CA_Manpower'
        ) {
          getCandidateDetails(loginReducer?.user);
        } else {
          if (typeof window !== 'undefined' && window.sessionStorage) {
            const userDetails =
              JSON.parse(sessionStorage.getItem('userDetails')) || {};
            if (
              userDetails &&
              userDetails?.isUpdated &&
              userDetails?.email === loginReducer?.user.email
            ) {
              getCandidateDetails(loginReducer?.user);
            } else {
              getCandidatePopupDetails(
                loginReducer,
                getCandidateDetails,
                userDetails
              );
            }
          }
        }
      }
    }, [loginReducer]);

    const getCandidateDetails = (user = {}) => {
      setHaveDetails(true);
      getCandidateDetailsApi(user, dispatch, loginReducer?.isRedirectFromAdb2c, 1);
      dispatch(adb2cRedirect(false));
    };
    return (
      <AppRootComponent
        channel={channel}
        graphQLClient={graphQLClient}
        Router={Router}
        oneTrustKey={oneTrustKey}
        language={language ? language : 'no'}
        contextSubscription={scContextSubscribe}
        googleApiKey={googleApiKey}
        {...props}
      />
    );
  };
};

class AppRoot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ssrRenderComplete: false,
      redirectToLogin: false,
      contextFactory: new SitecoreContextFactory(),
    };
    this.state.contextFactory.subscribeToContext(props.contextSubscription);

    if (props.ssrState && props.ssrState.sitecore && props.ssrState.sitecore.route) {
      // set the initial sitecore context data if we got SSR initial state
      this.state.contextFactory.setSitecoreContext({
        route: props.ssrState.sitecore.route,
        itemId: props.ssrState.sitecore.route.itemId,
        ...props.ssrState.sitecore.context,
      });
    } else if (props.ssrState) {
      this.state.contextFactory.setSitecoreContext(props.ssrState.sitecore.context);
    } else {
      this.state.contextFactory.setSitecoreContext(null);
    }
  }

  componentWillUnmount() {
    this.state.contextFactory.unsubscribeFromContext(props.contextSubscription);
  }

  setSsrRenderComplete = (ssrRenderComplete) =>
    this.setState({
      ssrRenderComplete,
    });

  render() {
    const { path, Router, graphQLClient, language, oneTrustKey } = this.props;

    const routeRenderFunction = (props) => {
      return (
        <RouteHandler
          route={props}
          ssrRenderComplete={this.state.ssrRenderComplete}
          setSsrRenderComplete={this.setSsrRenderComplete}
          contextFactory={this.state.contextFactory}
        />
      );
    };

    return (
      <>
        {typeof oneTrustKey !== 'undefined' && oneTrustKey != '' && (
          <Helmet htmlAttributes={{ lang: language }}>
            <script
              type={`text/javascript`}
              src={`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`}
              charset={`UTF-8`}
              data-domain-script={`${oneTrustKey}`}
              data-language={language}
            ></script>
            <script type={`text/javascript`}>
              {`function OptanonWrapper() {                
                const hbspEvent = document.createEvent('Event');
                hbspEvent.initEvent('hbsp-form-reload', true, true);
                const chatbotEvent = document.createEvent('Event');
                chatbotEvent.initEvent('chatbot-reload', true, true);
                
                if (OptanonActiveGroups) {
                  window.dispatchEvent(hbspEvent);                 
                }

                var regCheckPerformance = /C0002/g;
                const regCheckFunctional = /,C0003,/;
                const regCheckTargeting = /,C0004,/;
                const regCheckSocial = /,C0008,/;
                if (!OptanonActiveGroups.match(regCheckPerformance)) {                 
                  expireCookie('SC_ANALYTICS_GLOBAL_COOKIE');
                }
                function expireCookie(cname) {
                  var d = new Date();
                  d.setTime(d.getTime() + 0);
                  var expires = 'expires=' + d.toUTCString();
                  document.cookie = cname + '=; ' + expires + '; path=/';                 
                }
                if (OptanonActiveGroups.match(regCheckTargeting)) {
                   window.dispatchEvent(chatbotEvent);
                  const elements = document.getElementsByClassName(
                    'optanon-category-C0004'
                  );
                  loadIframe(elements);
                }
                if (OptanonActiveGroups.match(regCheckFunctional)) {
                  const elements = document.getElementsByClassName(
                    'optanon-category-C0003'
                  );
                  loadIframe(elements);
                }
                if (OptanonActiveGroups.match(regCheckPerformance)) {
                  const elements = document.getElementsByClassName(
                    'optanon-category-C0002'
                  );
                  loadIframe(elements);
                }
                if (OptanonActiveGroups.match(regCheckSocial)) {
                  const elements = document.getElementsByClassName(
                    'optanon-category-C0008'
                  );
                  loadIframe(elements);
                }
                function loadIframe(elements) {
                  for (let i = 0; i < elements.length; i++) {
                    elements[i].tagName === 'IFRAME' && elements[i].dataset.src
                      ? elements[i].setAttribute('src', elements[i].dataset.src)
                      : -1;
                  }
                }
                document.addEventListener('click', function (e) {
                  if (e.target && e.target.id == 'ot-sdk-btn') {
                    window.Optanon.ToggleInfoDisplay();
                  }
                });
            }`}
            </script>
          </Helmet>
        )}
        <ApolloProvider client={graphQLClient}>
          <SitecoreContext
            componentFactory={componentFactory}
            contextFactory={this.state.contextFactory}
          >
            <Toaster />
            <Router location={path} context={{}}>
              <FocusWrapper>
                <Switch>
                  {routePatterns.map((routePattern) => (
                    <Route
                      key={routePattern}
                      path={routePattern}
                      render={routeRenderFunction}
                    />
                  ))}
                </Switch>
              </FocusWrapper>
            </Router>
          </SitecoreContext>
        </ApolloProvider>
        <LogoutListener />
        <button
          id="ot-sdk-btn"
          className="ot-sdk-show-settings cookie-btn-style"
          style={{ display: 'none' }}
        ></button>
      </>
    );
  }
}

export default WrapAppRoot(AppRoot);
