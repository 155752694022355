/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';

/**
 * @description - AsscosiateAccordionView component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const AsscosiateAccordionView = ({ fields, t }) => {
  const profileInfoReducer = useSelector((state) => state.profileInfoReducer);
  const userData = profileInfoReducer?.data?.PersonalDetails.data[0];

  const { items } = fields;
  const modifyHref = (htmlContent) => {
    // Parse the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const anchorTag = doc.querySelector('a');

    if (anchorTag) {
      const currentHref = anchorTag.getAttribute('href');
      // Check if href starts with 'tax-forms?id'
      if (currentHref && currentHref.startsWith('tax-forms?id')) {
        anchorTag.setAttribute('href', `tax-forms?id=${userData?.id}`);
      }
    }
    return doc.body.innerHTML;
  };
  return (
    <div className="accordion-container-block">
      {items && items.length > 0 ? (
        items.map((item, index) => (
          <div className="container" key={index}>
            <div className="row">
              <div className="col">
                <h4>{item?.fields?.title?.value}</h4>
                <RichText
                  tag="p"
                  className="sub-title-text"
                  field={{ value: modifyHref(item?.fields?.content.value) }}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="no-content-data">{t(`no-data`)}</div>
      )}
    </div>
  );
};

AsscosiateAccordionView.defaultProps = {
  fields: {},
  t: (val) => val,
};

AsscosiateAccordionView.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AsscosiateAccordionView);
