import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './medals-display.scss';
import { dataFetcher } from '../../../dataFetcher';
import { PDF_API } from '../../../constants';
import Button from '../globals/buttons/Button';
import Loader from '../ErrorBoundary/Loader';

const MedalsDisplay = ({ t, fields }) => {
  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);
  const [pdfURL, setPdfURL] = useState();
  const [profileInfoAvailable, setProfileInfoAvailable] = useState(true);

  const binaryToBlob = (data) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const url = URL.createObjectURL(blob, { oneTimeOnly: true });
    return setPdfURL(url);
  };

  const profileData = profileInfo?.PersonalDetails?.data[0];

  const downloadPdfFromApi = () => {
    const fullName = profileData?.firstName + ' ' + profileData?.lastName;
    const pdfParams = {
      fullName,
      achiever: profileData?.customInt1 === null ? 0 : profileData?.customInt1,
      communicator: profileData?.customInt2 === null ? 0 : profileData?.customInt2,
      learner: profileData?.customInt3 === null ? 0 : profileData?.customInt3,
      probSolver: profileData?.customInt4 === null ? 0 : profileData?.customInt4,
      producer: profileData?.customInt5 === null ? 0 : profileData?.customInt5,
      teamPlayer: profileData?.customInt6 === null ? 0 : profileData?.customInt6,
    };

    dataFetcher(PDF_API.MEDALS_PDF, 'GET', {}, pdfParams).then(
      (response) => {
        binaryToBlob(response.data);
        setProfileInfoAvailable(false);
      },
      (error) => {
        console.log(error);
        setProfileInfoAvailable(false);
      }
    );
  };

  const openInNewTab = () => {
    window.open(pdfURL, '_blank', 'noreferrer');
  };

  useEffect(() => {
    if (profileInfo?.isRedirect) {
      downloadPdfFromApi();
    }
  }, [profileInfo]);

  return (
    <div className="dashboard-block medals-block">
      {profileInfoAvailable ? (
        <Loader
          loaderMsg={t('fetching-your-data')}
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#C25700',
          }}
          customTextStyle={{ color: '#C25700' }}
        />
      ) : (
        <>
          <div className="medal-logo-section no-medals-section">
            <img
              className="medal-logo"
              alt={fields?.backgroundimage?.value?.alt}
              src={fields?.backgroundimage?.value?.src}
            />
            <h3 className="medals-text-content">{fields?.title?.value}</h3>
          </div>
          {profileData?.customInt1 === null &&
          profileData?.customInt2 === null &&
          profileData?.customInt3 === null &&
          profileData?.customInt4 === null &&
          profileData?.customInt5 === null &&
          profileData?.customInt6 === null ? (
            <div className="medal-context no-medals-context">
              <div className="medals-text-content">
                {fields?.textIfNoMedals?.value}
              </div>
              <div className="medals-icon-section">
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[0]?.fields?.icon?.value?.src}
                      alt={fields?.icons[0]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsAchieverTitleText')}
                  </div>
                </div>
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[8]?.fields?.icon?.value?.src}
                      alt={fields?.icons[8]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsTeamPlayerTitleText')}
                  </div>
                </div>
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[3]?.fields?.icon?.value?.src}
                      alt={fields?.icons[3]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsLearnerTitleText')}
                  </div>
                </div>
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[7]?.fields?.icon?.value?.src}
                      alt={fields?.icons[7]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsProducerTitleText')}
                  </div>
                </div>
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[4]?.fields?.icon?.value?.src}
                      alt={fields?.icons[4]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsProblemSolverTitleText')}
                  </div>
                </div>
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[12]?.fields?.icon?.value?.src}
                      alt={fields?.icons[12]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsCommunicatorTitleText')}
                  </div>
                </div>
              </div>
              <div className="medals-text-content">{fields?.bottomText?.value}</div>
            </div>
          ) : (
            <div className="medal-context">
              <div>{fields?.text?.value}</div>
              <div className="medals-icon-section">
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[1]?.fields?.icon?.value?.src}
                      alt={fields?.icons[1]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsAchieverTitleText')}
                  </div>
                  <div>
                    {profileInfo?.PersonalDetails?.data[0]?.customInt1 &&
                      `{${profileInfo?.PersonalDetails?.data[0]?.customInt1}}`}
                  </div>
                </div>
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[9]?.fields?.icon?.value?.src}
                      alt={fields?.icons[9]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsTeamPlayerTitleText')}
                  </div>
                  <div>
                    {profileInfo?.PersonalDetails?.data[0]?.customInt6 &&
                      `{${profileInfo?.PersonalDetails?.data[0]?.customInt6}}`}
                  </div>
                </div>
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[2]?.fields?.icon?.value?.src}
                      alt={fields?.icons[2]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsLearnerTitleText')}
                  </div>
                  <div>
                    {profileInfo?.PersonalDetails?.data[0]?.customInt3 &&
                      `{${profileInfo?.PersonalDetails?.data[0]?.customInt3}}`}
                  </div>
                </div>
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[6]?.fields?.icon?.value?.src}
                      alt={fields?.icons[6]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsProducerTitleText')}
                  </div>
                  <div>
                    {profileInfo?.PersonalDetails?.data[0]?.customInt5 &&
                      `{${profileInfo?.PersonalDetails?.data[0]?.customInt5}}`}
                  </div>
                </div>
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[5]?.fields?.icon?.value?.src}
                      alt={fields?.icons[5]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsProblemSolverTitleText')}
                  </div>
                  <div>
                    {profileInfo?.PersonalDetails?.data[0]?.customInt4 &&
                      `{${profileInfo?.PersonalDetails?.data[0]?.customInt4}}`}
                  </div>
                </div>
                <div className="icons-section">
                  <div className="icon-div">
                    <img
                      className="medal-icon"
                      src={fields?.icons[11]?.fields?.icon?.value?.src}
                      alt={fields?.icons[11]?.fields?.icon?.value?.alt}
                    />
                  </div>
                  <div className="medal-icon-title">
                    {t('medalsCommunicatorTitleText')}
                  </div>
                  <div>
                    {profileInfo?.PersonalDetails?.data[0]?.customInt2 &&
                      `{${profileInfo?.PersonalDetails?.data[0]?.customInt2}}`}
                  </div>
                </div>
              </div>
              <div className="medals-button-container">
                <Button
                  text={fields?.ctaText?.value}
                  cssClass={`${
                    !profileInfoAvailable
                      ? 'medals-button button-enabled orange-sd text-transform-medals'
                      : 'button-disabled text-transform-medals'
                  }`}
                  handleButtonClick={() => openInNewTab()}
                  isLoading={profileInfoAvailable}
                  isDisabled={profileInfoAvailable}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

MedalsDisplay.defaultProps = {
  t: (val) => val,
  fields: {
    icons: {},
  },
};

MedalsDisplay.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    icons: PropTypes.shape({}),
  }),
};

export default withSitecoreContext()(withTranslation()(MedalsDisplay));
