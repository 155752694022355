/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { Controller } from 'react-hook-form';
import get from 'lodash/get';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { defineKeyValueObjFromObjectArray } from '../../../business/Utils/helper';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';
import { isNorthAmericanCountry } from '../../../../utils/helperUtils';
import { useSelector } from 'react-redux';
import { authstates } from '../../../../utils/enums';

/**
 * @description - Dynamic Checkbox component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const Checkbox = ({
  id,
  name,
  text,
  label,
  isHideField,
  showBasedOnFieldName,
  hideBasedOnFieldName,
  customError,
  dataValidations,
  watch,
  customStyle,
  value,
  t,
  readOnly,
  register,
  setValue,
  list,
  control,
  validateOnLoad,
  requiredValidation = [],
  fieldName,
  formName,
  sitecoreContext,
}) => {
  const hiddenFieldName = formName
    ? `${formName}[${fieldName}_hidden]`
    : `${fieldName}_hidden`;
  const fieldError = get(customError, hiddenFieldName);
  const [checked, setChecked] = useState(false);
  const [optionList, setOptionList] = useState({});
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;
  const addressValue = watch('address.zip') || null;
  const profileInfoReducer = useSelector((state) => state.profileInfoReducer);
  const updatedZip = useSelector((state) => state.updatedZipReducer);
  const formDataReducer = useSelector((state) => state.formDataReducer);
  const loginReducer = useSelector((state) => state.loginReducer);
  const userData = formDataReducer?.data?.PersonalDetails
    ? formDataReducer?.data?.PersonalDetails.data[0]
    : null;
  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    if (list?.length > 0) {
      const newList = defineKeyValueObjFromObjectArray(list);
      setOptionList(newList);
    }
  }, [list]);

  const handleSelect = (value) => {
    let newValue = value ? optionList?.true || true : optionList?.false || false;
    if (newValue && name == 'customText16' && isNorthAmericanCountry(countryName)) {
      setValue('MailingAddress.zip', null, {});
      setValue('MailingAddress.countryCode', null, {});
      setValue('MailingAddress.country', null, {});
      setValue('MailingAddress.state', null, {});
      setValue('MailingAddress.city', null, {});
      setValue('MailingAddress.address1', '', {});
      setValue('MailingAddress.address2', '', {});
      setChecked(value);
      setValue(hiddenFieldName, value, param);
      setValue(name, newValue);
    } else {
      setValue('MailingAddress.zip', null, {});
      setValue('MailingAddress.countryCode', null, {});
      setValue('MailingAddress.country', null, {});
      setValue('MailingAddress.state', null, {});
      setValue('MailingAddress.city', null, {});
      setValue('MailingAddress.address1', '', {});
      setValue('MailingAddress.address2', '', {});
      setChecked(value);
      setValue(hiddenFieldName, value, param);
      setValue(name, newValue);
    }
  };
  useEffect(() => {
    if (isNorthAmericanCountry(countryName) && name === 'customText16') {
      let newValue = value === 'true' ? true : false;
      if (
        (userData && userData?.address?.zip !== userData?.MailingAddress?.zip) ||
        (updatedZip &&
          updatedZip?.data?.address?.zip !== updatedZip?.data?.MailingAddress?.zip)
      ) {
        setChecked(false);
        setValue(hiddenFieldName, false);
        setValue(name, false);
      } else {
        setChecked(newValue);
        setValue(hiddenFieldName, newValue);
        setValue(name, newValue);
      }
    } else {
      value =
        value === null || value === undefined || value === ''
          ? optionList?.false || false
          : value;
      param = value ? { shouldValidate: true, shouldDirty: true } : param;
      let result = Object.keys(optionList)?.find(
        (key) => optionList[key] === value?.toString()
      );
      let newValue = result === 'true' ? true : false;
      setChecked(newValue);
      setValue(hiddenFieldName, newValue, param);
      setValue(name, value);
    }
  }, [value, optionList]);
  useEffect(() => {
    if (loginReducer?.state === authstates.AUTHENTICATED) {
      if (isNorthAmericanCountry(countryName) && name === 'customText16') {
        if (userData?.customText16 === 'true') {
          setChecked(true);
          setValue(hiddenFieldName, true);
          setValue(name, true);
        } else {
          setChecked(false);
          setValue(hiddenFieldName, false);
          setValue(name, false);
        }
      }
    }
  }, [name]);

  return (
    <div
      className={`form-block chkBox ${fieldError ? 'error withoutBg' : ''} ${
        !showField || hideField ? 'hide' : ''
      }`}
      style={customStyle}
    >
      <div className="chkBox">
        <input
          type="hidden"
          name={name}
          id={`${id}_hiddenField`}
          ref={register ? register : () => {}}
        />
        <Controller
          control={control}
          name={hiddenFieldName}
          render={({ onChange, onBlur }) => (
            <input
              id={id}
              type="checkbox"
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e.target.checked);
                handleSelect(e.target.checked);
              }}
              checked={checked}
              aria-checked={checked}
              disabled={readOnly}
            />
          )}
          rules={{
            required:
              showField && requiredData?.fields?.value?.value ? !hideField : false,
          }}
        />
        <label htmlFor={`${id}`} className="checkmark"></label>
        <label htmlFor={`${id}`} className="labelText">
          {text?.value ? (
            <RichText field={text} editable={false} className="checkBoxLabel" />
          ) : (
            label
          )}
          {!text && <span className="sr-only">Checkbox Label</span>}
        </label>
      </div>

      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
    </div>
  );
};

Checkbox.defaultProps = {
  id: 'id',
  setValue: () => {},
  t: () => {},
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  t: PropTypes.func,
};

export default Checkbox;
