/* eslint-disable*/
import React, {
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useForm } from 'react-hook-form';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import DynamicForm from '../../../../core/Forms/DynamicForm';
import {
  booleanRes,
  formObjIteration,
  recursiveWithConstantCallback,
} from '../../../Utils/helper';
import {
  saveJobApplyFormData,
  saveJobApplyFormState,
  saveJobApplyFormTriggerData,
  saveJobApplyValidFormList,
} from '../../../../../redux/actions/actions';
import { jobApply } from '../../../../../utils/enums';
import {
  traverseAndDataExist,
  isObjNotEmpty,
} from '../../../../../utils/helperUtils';

const SingleForm = ({ inputRef, ...props }) => {
  const {
    section = {},
    componentName,
    t,
    hideForm = false,
    sitecoreContext,
  } = props;
  const { fields = {} } = section;
  const { title, abstract, items, apiKeyMapper } = fields;
  const apiMapper = apiKeyMapper?.value
    ? apiKeyMapper?.value
    : jobApply[componentName];
  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);
  const formSubmitRef = useRef();
  const {
    register,
    errors,
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
    watch,
    trigger,
    handleSubmit,
    formState,
  } = useForm({ mode: 'all', revalidate: 'onBlur' });

  const { isValid, isDirty, touched, isValidating } = formState;

  const dispatch = useDispatch();

  const jobAppplyFormState = useSelector(
    (state) => state?.jobAppplyFormStateReducer
  );
  const jobAppplyFormData = useSelector((state) => state?.jobApplyFormDataReducer);

  const [candidateId, setCandidateId] = useState('');
  const [data, setData] = useState({});
  const [fileUpload, setFileUpload] = useState({});
  const [deletedFileList, setDeletedFileList] = useState([]);
  const [formError, showFormError] = useState(false);
  const [isDelete, setUploadDelete] = useState(false);
  const pageName = sitecoreContext?.route?.name;

  useEffect(() => {
    if (profileInfo?.Id) {
      setCandidateId(profileInfo?.Id);
      setData({
        data: profileInfo?.[apiMapper]?.data?.[0],
        attachment:
          pageName !== 'UnAuth-KO-SAENG' ? profileInfo?.AttachmentDetails : [],
      });
    }
  }, [profileInfo]);

  const handleUpload = (fileData, name) => {
    const fileList = fileUpload;
    const obj = { [name]: fileData };
    if (fileUpload.hasOwnProperty(name)) {
      delete fileList[name];
    }
    setFileUpload({ ...fileList, ...obj });
  };

  const handleDelete = (id, name, index) => {
    if (fileUpload.hasOwnProperty(name)) {
      const fileList = fileUpload;
      const temp = fileUpload[name];
      delete temp[index];
      delete fileList[name];
      const newtemp = temp?.filter((item) => item);
      const obj = { ...fileUpload, [name]: newtemp };
      setFileUpload({ ...fileList, ...obj });
    }
    if (id) {
      setDeletedFileList([...deletedFileList, { fileId: id, type: name }]);
    }
    setUploadDelete(true);
  };

  useEffect(() => {
    let isEmpty = !traverseAndDataExist(getValues());
    dispatch(
      saveJobApplyFormState({
        ...jobAppplyFormState,
        [componentName]: { isDirty, isValid, isEmpty, touched, isDelete },
      })
    );
  }, [isDirty, isValid, touched, isDelete]);

  const onSubmit = (data) => {
    const fileData = {
      files: { ...jobAppplyFormData?.data?.fileData?.files, ...fileUpload },
      DeletedFiles: jobAppplyFormData?.data?.fileData?.DeletedFiles
        ? [
            ...new Set([
              ...jobAppplyFormData?.data?.fileData?.DeletedFiles,
              ...deletedFileList,
            ]),
          ]
        : deletedFileList,
    };
    dispatch(saveJobApplyFormData({ [componentName]: data, fileData }));
    dispatch(saveJobApplyValidFormList(componentName));
  };

  const externalClick = () => {
    let event;
    if (typeof Event === 'function') {
      event = new Event('submit', { cancelable: true });
    } else {
      event = document.createEvent('Event');
      event.initEvent('submit', false, { cancelable: true });
    }
    formSubmitRef?.current?.dispatchEvent(event);
  };

  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      showFormError(true);
      setTimeout(() => dispatch(saveJobApplyFormTriggerData(componentName)), 50);
      externalClick();
    },
    invalidTrigger: () => {
      showFormError(true);
    },
  }));

  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    formattedData = recursiveWithConstantCallback(formattedData, booleanRes);
    onSubmitFunc(formattedData);
  };

  const handleChange = () => {
    setUploadDelete(true);
    showFormError(true);
  };

  return (
    <div className={`single-form ${hideForm ? 'hide-form' : ''}`}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(transform(onSubmit))}
        ref={formSubmitRef}
      >
        {title?.value && (
          <RichText tag="div" className="title-section" field={title} />
        )}
        {abstract?.value && (
          <RichText tag="div" className="text-section" field={abstract} />
        )}
        {
          <DynamicForm
            formData={items}
            register={register}
            errors={formError || isObjNotEmpty(touched) ? errors : ''}
            getValues={getValues}
            setValue={setValue}
            setError={setError}
            trigger={trigger}
            watch={watch}
            clearErrors={clearErrors}
            handleUpload={handleUpload}
            handleDelete={handleDelete}
            handleChange={handleChange}
            control={control}
            validateOnLoad={true}
            values={data}
            APIQuerryParams={{ cid: candidateId }}
            readOnlyFields={profileInfo?.FieldConfig?.readOnlyFields}
            fieldsToHide={profileInfo?.FieldConfig?.fieldsToHide}
            formState={formState}
            sitecoreContext={sitecoreContext}
          />
        }
      </form>
    </div>
  );
};

SingleForm.propTypes = {};

const ConnectedComponent = withSitecoreContext()(withTranslation()(SingleForm));

export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
