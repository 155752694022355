import React, { useEffect, useRef } from 'react';
import AuthWrapper from '../LoginMSAL/AuthWrapper';
import { isNullorEmpty } from '../../../utils/helperUtils';

const LogoutListener = () => {
  const buttonRef = useRef(null);

  const handleStorageChange = (event) => {
    const userSession = JSON.parse(sessionStorage.getItem('usersession'));

    if (
      event.key === 'authState' &&
      event.newValue === 'UNAUTHENTICATED' &&
      !isNullorEmpty(userSession?.user)
    ) {
      buttonRef.current.click();
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleLogout = (logout) => {
    logout();
  };

  return (
    <AuthWrapper>
      {({ logout }) => {
        return (
            <button
              className="d-none"
              ref={buttonRef}
              onClick={() => {
                handleLogout(logout);
              }}
            >
              logout
            </button> 
        );
      }}
    </AuthWrapper>
  );
};

export default LogoutListener;
