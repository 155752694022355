/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import crypto from 'crypto-js';
import {
  UPDATE_SESSION,
  STORAGE,
  SOCIAL_LOGIN_VALUES,
  CREATE_PROFILE,
} from '../../../constants';

import { useFetchData } from '../../../hooks/http-client';
import { authstates } from '../../../utils/enums';
import { handleRedirect } from '../../../redux/actions/actions';
import Loader from '../../core/ErrorBoundary/Loader';
import {
  logoutSuccess,
  isPopupDetailsTriggered,
} from '../../../redux/actions/loginActions';
import {
  filterPersonalDetails,
  isNorthAmericanCountry,
} from '../../../utils/helperUtils';
import { dataFetcher } from '../../../dataFetcher';

/**
 * @description - This is a placeholder component for todo pages.
 * @param {object} props - Input props.
 * @returns {Node} - HTML to render.
 */
const Redirect = (props) => {
  const { location, jobDetails, sitecoreContext, authFunc, t } = props;
  const dispatch = useDispatch();
  const { load } = useFetchData();
  const loginReducer = useSelector((state) => state.loginReducer);
  const history = useHistory();
  const isMultiCountryAllowed =
    sitecoreContext?.ADB2C?.isMultiCountryAllowed || false;
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';

  /**
   * @description - Push authentication info to gtm.
   * @returns {undefined}
   */
  const pushToDataLayer = () => {
    if (window?.dataLayer && loginReducer?.idToken?.idTokenClaims) {
      const idpVal = loginReducer?.idToken?.idTokenClaims?.idp;
      window.dataLayer.push({
        event: 'Authentication',
        eventCategory:
          loginReducer.idToken?.idTokenClaims?.email &&
          loginReducer.idToken?.idTokenClaims?.hasOwnProperty(
            'extension_TermsOfUseConsented'
          )
            ? 'Create an account'
            : 'Sign in to an account',
        eventAction: SOCIAL_LOGIN_VALUES.get(idpVal?.toLowerCase()) || idpVal,
        eventLabel: localStorage.getItem('authenticationType'),
      });
    }
  };

  /**
   * @description Handle page redirect.
   * @param {String} path - path.
   * @returns {undefined}
   */
  const handlePageRedirect = (path) => {
    if (path.indexOf('candidate/jobapply?id') !== -1) {
      /**
       * @description Function to update session on login.
       * @returns {undefined}
       */
      async function updateSesion() {
        await load(`${UPDATE_SESSION}`, { isLogout: false });
      }
      updateSesion().then(() => {
        history.push(path);
        pushToDataLayer();
        dispatch(handleRedirect(true));
        if (sessionStorage) {
          if (loginReducer.state === authstates.UNAUTHENTICATED) {
            localStorage.setItem('mobileLogoutUserStatus', true);
            localStorage.setItem('logoutUser', true);
          } else if (loginReducer.state === authstates.AUTHENTICATED) {
            localStorage.setItem('mobileLogoutUserStatus', false);
          } else {
            localStorage.removeItem('mobileLogoutUserStatus');
          }
          sessionStorage.removeItem('redirectPath');
          sessionStorage.removeItem('isLogout');
        }
      });
    } else {
      const isLogout = sessionStorage.getItem('isLogout');
      if (
        isNorthAmericanCountry(countryName) &&
        path.includes('candidate') &&
        isLogout
      ) {
        history.push('/');
      } else {
        history.push(
          path.indexOf('candidate/jobapplysuccess') !== -1 ||
            path.indexOf('candidate/jobapply-failure') !== -1 ||
            path.indexOf('candidate/spontaneous-jobapplysuccess') !== -1 ||
            path.indexOf('candidate/spontaneous-jobapply-failure') !== -1
            ? sitecoreContext?.Country?.jobSearchURL
            : path
        );
      }
      pushToDataLayer();
      dispatch(handleRedirect(true));
      if (sessionStorage) {
        if (loginReducer.state === authstates.UNAUTHENTICATED) {
          localStorage.setItem('mobileLogoutUserStatus', true);
          localStorage.setItem('logoutUser', true);
        } else if (loginReducer.state === authstates.AUTHENTICATED) {
          localStorage.setItem('mobileLogoutUserStatus', false);
        } else {
          localStorage.removeItem('mobileLogoutUserStatus');
        }
        sessionStorage.removeItem('redirectPath');
        sessionStorage.removeItem('isLogout');
      }
    }
  };

  useEffect(() => {
    if (jobDetails.isRedirect) return;
    const isLogout = sessionStorage.getItem('isLogout');
    const redirectPath = sessionStorage.getItem('redirectPath');
    if (isLogout) {
      if (location.pathname !== redirectPath) {
        handlePageRedirect(redirectPath);
      } else {
        history.push('/');
        pushToDataLayer();
        dispatch(handleRedirect(true));
        if (sessionStorage) {
          sessionStorage.removeItem('redirectPath');
          sessionStorage.removeItem('isLogout');
        }
      }
    }
  }, []);

  useEffect(() => {
    if (jobDetails.isRedirect) return;
    if (
      loginReducer.initialized &&
      loginReducer.state === authstates.AUTHENTICATED
    ) {
      if (
        !isMultiCountryAllowed &&
        !loginReducer?.idToken?.idTokenClaims?.country
          ?.toLowerCase()
          .includes(`-${sitecoreContext?.Country?.code?.toLowerCase()}-`)
      ) {
        authFunc.logout(sitecoreContext?.ADB2C?.authFailedRedirect);
        dispatch(logoutSuccess());
        dispatch(handleRedirect(true));
        history.push(sitecoreContext?.ADB2C?.authFailedRedirect);
      } else {
        localStorage.setItem(
          STORAGE.UUID,
          crypto.SHA3(loginReducer?.idToken?.uniqueId).toString()
        );
        const redirectPath = sessionStorage.getItem('redirectPath');

        if (isNorthAmericanCountry(countryName)) {
          const inputParams = {
            entity: 'Candidate',
            forceUpdate: false,
          };
          dataFetcher(
            `${CREATE_PROFILE.GET_CREATE_PROFILE.CANDIDATE_POPUP_DETAILS}`,
            'GET',
            {},
            inputParams
          ).then((response) => {
            if (response?.data?.status === 1000) {
              sessionStorage.setItem(
                'userDetails',
                JSON.stringify({
                  isUpdate: true,
                  email: loginReducer?.user?.email,
                  userDetails: filterPersonalDetails(
                    response?.data?.data?.PersonalDetails
                  ),
                })
              );
              dispatch(isPopupDetailsTriggered(true));
              handleSignInRedirection(redirectPath);
            }
          });
        } else {
          handleSignInRedirection(redirectPath);
        }
      }
    }
  }, [loginReducer.state]);
  const handleSignInRedirection = (redirectPath) => {
    if (
      !redirectPath ||
      redirectPath === sitecoreContext?.ADB2C?.authFailedRedirect
    ) {
      pushToDataLayer();
      history.push('/');
    } else {
      if (location.pathname !== redirectPath) {
        sessionStorage.setItem(STORAGE.PAGE_EVENT, true);
        handlePageRedirect(redirectPath);
      }
    }
  };
  return (
    <div className="container-fluid">
      <Loader
        loaderMsg={t('redirecting')}
        loaderWithMsg
        customLoaderStyle={{
          width: '4rem',
          height: '4rem',
          color: '#C25700',
        }}
        customTextStyle={{ color: '#C25700' }}
      />
    </div>
  );
};
/**
 * @description Mapping the state to props.
 * @param {object} state - state.
 * @returns {object} - Slice of state.
 */
const mapStateToProps = (state) => {
  return {
    jobDetails: state.jobDetailsReducer,
    authFunc: state.authFunctionsReducer,
  };
};

Redirect.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  fields: PropTypes.shape({}),
  profileInfo: PropTypes.shape({}),
  jobDetails: PropTypes.shape({
    isRedirect: PropTypes.bool,
  }),
  sitecoreContext: PropTypes.shape({}),
  authFunc: PropTypes.shape({
    logout: PropTypes.func,
  }),
};

Redirect.defaultProps = {
  location: {
    pathname: '',
  },
  fields: {},
  profileInfo: {},
  jobDetails: {
    isRedirect: false,
  },
  sitecoreContext: {},
  authFunc: {
    logout: () => {},
  },
  t: () => {},
};

export default withSitecoreContext()(connect(mapStateToProps)(withRouter(Redirect)));
