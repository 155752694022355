/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Loader from '../../../../../core/ErrorBoundary/Loader';
import Error from '../../../../../core/ErrorBoundary/Error';
import { getCandidateDetailsApi } from '../../../../../../services/apiServices/candidateService';
import { clearProfileInfo } from '../../../../../../redux/actions/actions';

import CreateNewTaxForm from './CreateNewTaxForm';
import { useHistory } from 'react-router-dom';
import MyTaxFormsTable from './MyTaxFormsTable';
import { API_STATUS_CODES, DASHBOARD } from '../../../../../../constants';
import { dataFetcher } from '../../../../../../dataFetcher';

/**
 * @description - Payment Option Component
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const TaxForms = ({ fields, t, profileInfo, dispatch }) => {
  const { title, description } = fields;
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [taxFormId, setTaxFormId] = useState();
  const [loading, setLoading] = useState(null);
  const [taxFormsData, setTaxFormsData] = useState([]);
  useEffect(() => {
    if (profileInfo?.Id) {
      setTaxFormId(profileInfo?.Id)
      setprofileDataLoading(false);
      if (profileInfo && !profileInfo?.isAssociate) {
        history.push('/en/page-not-found');
      }
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);
  useEffect(() => {
    if (taxFormId) {
      setLoading(true);
      dataFetcher(`${DASHBOARD.TAX_FORMS.GET_MY_TAX_FORMS}?id=${taxFormId}`, 'GET')
        .then((response) => {
          if (response.status === API_STATUS_CODES.OK) {
            if (response?.data[0]?.changedEntityId) {
              setTaxFormsData(response?.data);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        });
    }
  }, [taxFormId]);
  const handleApiRefresh = () => {
    dispatch(clearProfileInfo());
    setTimeout(() => getCandidateDetailsApi({}, dispatch), 500);
  };
  return (
    <section
      className="profile-accordion-section"
      aria-labelledby="title"
      aria-describedby="info"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 pt-5">
            {title?.value && (
              <div className="row pt-2">
                <div className="col-md-12">
                  <Text field={title} tag="h2" className="title-small" id="info" />
                </div>
              </div>
            )}
            {description?.value && (
              <div className="row py-2">
                <div className="col-md-12">
                  <Text field={description} className="description" id="info" />
                </div>
              </div>
            )}
            {profileDataLoading ? (
              <Loader
                loaderMsg={t('fetching-your-data')}
                loaderWithMsg
                customLoaderStyle={{
                  width: '4rem',
                  height: '4rem',
                  color: '#C25700',
                }}
                customTextStyle={{ color: '#C25700' }}
              />
            ) : error ? (
              <div className="apiError">
                <Error
                  errorMsg={t('error-message')}
                  t={t}
                  refreshApi={true}
                  handleApiRefresh={handleApiRefresh}
                />
              </div>
            ) : (
              <div>
                <MyTaxFormsTable
                  loading={loading}
                  data={taxFormsData}
                  fields={fields}
                  t={t}
                  profileInfo={profileInfo}
                />
                <CreateNewTaxForm fields={fields} t={t} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

TaxForms.defaultProps = {
  t: (val) => val,
  fields: {},
  profileInfo: {},
  dispatch: () => {},
};

TaxForms.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(TaxForms));
