import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { authstates } from '../../../utils/enums';
import { UPDATE_SESSION } from '../../../constants';
import { setUpdateSession } from '../../../redux/actions/loginActions';
import { useFetchData } from '../../../hooks/http-client';
// import { isNorthAmericanCountry } from '../../../utils/helperUtils';

/**
 * @description - Wrapper that shows the children only if authenticated.
 * @param {object} props - Props passed.
 * @returns {Node} - HTML node.
 */
const AuthWrapper = (props) => {
  const expireTimer = useRef(null);
  const {
    dispatch,
    children,
    authFunc,
    loginStatus: {
      state,
      user,
      idToken: { account },
      updateSession,
      isLogout,
      // sitecoreContext,
    },
  } = props;

  const authenticationState = state;
  const { load } = useFetchData();

  /**
   * @description - Clears timer set.
   * @returns {undefined}
   */
  const clearLogoutTimer = () => {
    if (expireTimer.current) {
      clearTimeout(expireTimer.current);
    }
  };

  useEffect(() => {
    if ((updateSession && isLogout) || (updateSession && account)) {
      // calls update session api.
      load(`${UPDATE_SESSION}`, { isLogout });
      dispatch(setUpdateSession(false));
    }
  }, [account, updateSession, isLogout, load, dispatch]);

  useEffect(() => {
    if (account) {
      const {
        idToken: { exp },
      } = account;

      // Remove any existing timer
      clearLogoutTimer();

      // Calculate the token expiry time remaining.
      const expiresIn = parseInt(exp) * 1000 - new Date().getTime();

      // Adds timer to refresh the token(15 mins before expiry).
      expireTimer.current = setTimeout(() => {
        authFunc.refreshToken();
      }, expiresIn - 300000);
    }
  }, [authFunc, account, dispatch]);

  return <>{children({ authenticationState, account, user, ...authFunc })}</>;
};

AuthWrapper.propTypes = {
  loginStatus: PropTypes.shape({
    state: PropTypes.string,
    user: PropTypes.shape({}),
    updateSession: PropTypes.bool,
    isLogout: PropTypes.bool,
    idToken: PropTypes.shape({
      account: PropTypes.shape({
        idToken: PropTypes.shape({
          exp: PropTypes.number,
        }),
      }),
    }),
  }),
  authFunc: PropTypes.shape({
    login: PropTypes.func,
    logout: PropTypes.func,
    editProfile: PropTypes.func,
    editPhoneNumber: PropTypes.func,
    editPassword: PropTypes.func,
    refreshToken: PropTypes.func,
  }),
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
};

AuthWrapper.defaultProps = {
  loginStatus: {
    state: authstates.UNAUTHENTICATED,
    user: null,
    updateSession: false,
    isLogout: false,
    idToken: {
      account: null,
    },
  },
  authFunc: {
    login: () => null,
    logout: () => null,
    editProfile: () => null,
    editPhoneNumber: () => null,
    editPassword: () => null,
    refreshToken: () => null,
  },
};

/**
 * @description Mapping the state to props.
 * @param {object} state - state.
 * @returns {object} - Slice of state.
 */
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginReducer,
    authFunc: state.authFunctionsReducer,
  };
};

export default withSitecoreContext()(connect(mapStateToProps)(AuthWrapper));
