import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import set from 'lodash/set';
import './jobpriorities.scss';
import dragIcon from '../../../../../assets/images/dragIcon.svg';
import { Controller } from 'react-hook-form';

const JobPriorities = (props) => {
  const { formProps, options } = props;
  const { setValue, name, validateOnLoad, formState, t, value, control } = formProps;
  const [items, setItems] = React.useState([]);
  const [isDragging, setDragging] = React.useState(false);

  useEffect(() => {
    if (value && value?.length > 0 && value[0]?.order) {
      // onload the array - need to be sorted initially
      const res = value.sort((a,b) => a.order - b.order)
      const priorityValues = res.map((item) => ({
        ...item,
      }));
     // value.sort((a,b) => b.order - a.order)
      setItems(priorityValues);
    } else { 
      const modifiedData = options.map((item, index) => ({
        ...item,
        order: String(index + 1),
        jobid: String(index + 1),
      }));
      setItems(modifiedData);
      const param = validateOnLoad ? { shouldValidate: true } : {};
      setValue(name, modifiedData, param);
      set(formState.touched, name, true);
    }
  }, [value]);

  const onDragStart = () => {
    setDragging(true);
  };

  const onDragEnd = (result) => {
    setDragging(false);
    if (!result.destination) return;
    const updatedItems = [...items];
    const movedItem = updatedItems.find((item) => item.jobid === result.draggableId);

    // Remove the moved item from the list
    updatedItems.splice(result.source.index, 1);
    // Insert the moved item at its new position
    updatedItems.splice(result.destination.index, 0, movedItem);
    // Reassign order values to all items based on their new positions
    updatedItems.forEach((item, index) => {
      item.order = String(index + 1);
    });
    setItems(updatedItems);
    const param = validateOnLoad ? { shouldValidate: true } : {};
    setValue(name, updatedItems, param);
    set(formState.touched, name, true);
  };

  return (
    <>
      <table className="job-priority">
        <thead>
          <tr>
            <th></th>
            <th>{t('rank')} </th>
            <th>{t('statement')}</th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {isDragging && <div className="overlay-job-priorities"> </div>}
          <Droppable droppableId="list">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable key={item.jobid} draggableId={item.jobid} index={index}>
                    {(provided) => (
                      <tr
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className="job-item-row"
                      >
                        <td className="job-item-image">
                          <img src={dragIcon} alt="drag-icon" />
                        </td>
                        <td>{item.order}</td>
                        <td>{item.content}</td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        render={({ field }) => <input type="hidden" {...field} />}
      />
    </>
  );
};

export default JobPriorities;
