/*eslint-disable*/
const validatorObject = {
  pattern: 'regexPatternvalidator',
  required: 'requiredValidator',
  maximum: 'maximumLengthvalidator',
  minimum: 'minimumLengthvalidator',
  date: 'dateValidator',
  minimumAge: 'minimumAgeValidator',
  maximumAge: 'maximumAgeValidator',
  range: 'ageRangeValidator',
  year: 'yearValidator',
  dni: 'dniValidator',
  currentDate: 'currentDateValidator',
  location: 'locationValidator',
  yearOrMonthSelectValidator: 'yearOrMonthSelectValidator',
  isEmailSame: 'isEmailSameValidator',
  isAccountNumberSame: 'isAccountNumberSameValidator',
};

export const getValidationDatabyType = (data, type) => {
  return (
    Array.isArray(data) &&
    data.find((item) => {
      let inputType = item?.fields?.inputType?.value
        ? item.fields.inputType.value
        : '';
      return inputType === validatorObject[type];
    })
  );
};
