import { combineReducers } from 'redux';
import profileInfoReducer from './profileInfoReducer';
import profileInfoFormReducer from './profileInfoFormReducer';
import formDataReducer from './formDataReducer';
import updatedZipReducer from './updatedZipReducer';
import profileEmptyFieldsReducer from './profileEmptyFieldsReducer';
import publicPositionReducer from './publicPositionReducer';
import screenerQuestionsReducer from './screenerQuestionsReducer';
import candidateIdReducer from './candidateIdReducer';
import jobDetailsReducer from './jobDetailsReducer';
import buildProfileFormReducer from './buildProfileFormReducer';

import jobSearchInfoReducer from './jobSearchInfoReducer';
import jobLocationReducer from './jobLocationReducer';
import loginReducer from './loginReducer';
import adb2cConfigReducer from './adb2cConfigReducer';
import authFunctionsReducer from './authFunctionsReducer';
import savedJobsReducer from './savedJobsReducer';
import profilePicReducer from './profilePicReducer';
import uploadConfigReducer from './uploadConfigReducer';
import coverReducer from './coverReducer';
import resumeReducer from './resumeReducer';
import hubspotFormReducer from './hubspotFormReducer';
import chatbotReducer from './chatbotReducer';
import languageReducer from './languageReducer';
import consentReducer from './consentReducer';
import mapReducer from './mapReducer';
import uploadFieldsReducer from './uploadFieldsReducer';
import jobApplyFormDataReducer from './jobApplyFormDataReducer';
import jobApplyFormStateReducer from './jobApplyFormStateReducer';
import seoSearchResultsReducer from './seoSearchResultsReducer';
import checkboxFocusReducer from './checkboxFocusReducer';
import sortJobsReducer from './sortJobsReducer';
import bannerLoadedReducer from './bannerLoadedReducer';

export const rootReducer = combineReducers({
  profileInfoReducer,
  chatbotReducer,
  publicPositionReducer,
  candidateIdReducer,
  screenerQuestionsReducer,
  jobDetailsReducer,
  buildProfileFormReducer,
  jobSearchInfoReducer,
  jobLocationReducer,
  loginReducer,
  adb2cConfigReducer,
  authFunctionsReducer,
  savedJobsReducer,
  profilePicReducer,
  uploadConfigReducer,
  profileInfoFormReducer,
  formDataReducer,
  updatedZipReducer,
  profileEmptyFieldsReducer,
  coverReducer,
  resumeReducer,
  hubspotFormReducer,
  languageReducer,
  consentReducer,
  mapReducer,
  uploadFieldsReducer,
  jobApplyFormDataReducer,
  jobApplyFormStateReducer,
  seoSearchResultsReducer,
  checkboxFocusReducer,
  sortJobsReducer,
  bannerLoadedReducer,
});
