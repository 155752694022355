/* eslint-disable require-jsdoc */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Button from '../../core/globals/buttons/Button';
import Select from 'react-select';
import './skillsmodal.scss';
import '../../core/Forms/forms.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fillInAddress,
  // getPlacePredictionsOthers,
  googleGeocoder,
} from '../Utils/helper';
import {
  checkUserHaveSkillsZipcode,
  isNorthAmericanCountry,
  isObjNotEmpty,
} from '../../../utils/helperUtils';
import { CREATE_PROFILE, GET_BRANCH_LOCATION_DETAILS } from '../../../constants';
import { dataFetcher } from '../../../dataFetcher';
import { authstates, countryCodesList } from '../../../utils/enums';
import ModalComponent from '../../core/Modal';
import { toast } from 'react-toastify';
import {
  getCandidateDetailsApi,
  getCandidateDetailsApiPromiseMethod,
} from '../../../services/apiServices/candidateService';
import { NearbyFinder } from '../../core/Map/NearbyFinder';
import {
  logoutSuccess,
  isPopupDetailsTriggered,
} from '../../../redux/actions/loginActions';
import {
  checkUserHaveBranchAssigned,
  formattSkillsData,
  postPersonalInfoData,
} from './utls';

const SkillsAndZipcodeModal = ({ t, sitecoreContext }) => {
  const [showModal, setShowModal] = useState(true);
  const [latLng, setLatLng] = useState({});
  const [latLong, setLatLong] = useState({});
  const [branchLocationList, setBranchLocationList] = useState({});
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [profileInfoFromPopup, setProfileInfoFromPopup] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [postApiLoading, setPostApiLoading] = useState(false);
  const [locationAdress, setLocationAddress] = useState({});
  const [locationAdressFromApi, setLocationAdressFromApi] = useState({});
  const autoCompleteZipRef = useRef(null);
  const dispatch = useDispatch();
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const loginReducer = useSelector((state) => state.loginReducer);
  const loginState = loginReducer?.state;
  const isPopupTriggerState = loginReducer?.isPopupDetailsTriggered;
  const authenticatedUser = loginState === authstates.AUTHENTICATED;

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  // To check if user has skills and zipcode but not branch

  useEffect(() => {
    if (
      authenticatedUser &&
      typeof window !== 'undefined' &&
      window.sessionStorage
    ) {
      const userPopupDetails =
        JSON.parse(sessionStorage.getItem('userDetails')) || {};
      if (userPopupDetails?.userDetails) {
        setProfileInfoFromPopup(userPopupDetails?.userDetails);
        setIsLoading(false);
      }
    }
  }, [isPopupTriggerState]);

  useEffect(() => {
    const baseUrl =
      countryName === 'us'
        ? GET_BRANCH_LOCATION_DETAILS.US
        : GET_BRANCH_LOCATION_DETAILS.CA;
    dataFetcher(baseUrl, 'GET').then((response) => {
      const data = response?.data?.sitecore?.route?.placeholders['jss-main'][0];
      // filter the data with allowCandidateAssignment flag
      const filteredItems = data?.fields?.items?.filter((item) => {
        return item?.fields?.allowCandidateAssignment?.value === true;
      });
      // set branch location list
      setBranchLocationList((prevState) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          items: filteredItems,
        },
      }));
    });
  }, []);

  useEffect(() => {
    if (isObjNotEmpty(locationAdress)) {
      // const value = `${locationAdress.zip} ${
      //   locationAdress.city
      //     ? locationAdress.city
      //     : locationAdress.country || locationAdress.countryName
      // }, ${
      //   locationAdress.countryName
      //     ? locationAdress.countryName
      //     : locationAdress.country
      // }`;
      setValue('zipCode', locationAdress.zip);
    }
  }, [locationAdress]);

  useEffect(() => {
    if (profileInfoFromPopup) {
      dataFetcher(CREATE_PROFILE.GET_CREATE_PROFILE.GET_SKILLS_MASTER_DATA).then(
        (response) => {
          const skillsMasterData = formattSkillsData(response?.data);
          setSkillsOptions(skillsMasterData);
          const address =
            profileInfoFromPopup?.PersonalDetails?.data?.[0]?.address || {};
          const skillsAdded =
            profileInfoFromPopup?.PersonalDetails?.data?.[0]?.skills || [];
          const selectedObjects = skillsAdded?.map((value) => {
            return skillsMasterData.find((item) => item.label === value);
          });
          setValue('skills', selectedObjects);
          setSelectedSkills(selectedObjects);
          setValue('zipCode', address?.zip);

          setLocationAdressFromApi(address);
          if (address?.zip) {
            setLocationAddress(address);
            googleGeocoder(address?.zip, setLatLng);
          }
        }
      );
    }
  }, [profileInfoFromPopup]);

  const isUserInUS =
    !isLoading &&
    authenticatedUser &&
    isNorthAmericanCountry(countryName) &&
    checkUserHaveSkillsZipcode(profileInfoFromPopup);

  const isUserInUSWithNoBranchAssigned =
    authenticatedUser &&
    profileInfoFromPopup &&
    profileInfoFromPopup?.Id &&
    isNorthAmericanCountry(countryName) &&
    checkUserHaveBranchAssigned(profileInfoFromPopup);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (isUserInUS) {
      sessionStorage.setItem('captureAdditionalModalPopup', true);
    }
  }, [isUserInUS]);

  useEffect(() => {
    if (isUserInUSWithNoBranchAssigned) {
      const zip = profileInfoFromPopup?.PersonalDetails?.data?.[0].address.zip;
      googleGeocoder(zip, setLatLong);
      NearbyFinder(branchLocationList, latLong.lat, latLong.lng, true).then(
        (sortedNearbyLocations) => {
          const branchId = sortedNearbyLocations[0]?.fields?.branchId?.value;
          const postPersonalInfoData = {
            EditPersonalInfo: {
              Branch: branchId,
            },
          };
          const formData = new FormData();
          formData.append('profileData', JSON.stringify(postPersonalInfoData));
          dataFetcher(
            CREATE_PROFILE.POST_CREATE_PROFILE.CANDIDATE_PERSONAL_INFO,
            'POST',
            formData
          ).then(
            () => {
              getCandidateDetailsApi(loginReducer?.user, dispatch, true);
              setPostApiLoading(false);
            },
            () => {
              handleError();
            }
          );
        }
      );
    }
  }, [isUserInUSWithNoBranchAssigned]);
  const handleError = () => {
    toast.error(t('check-back-later'));
    setPostApiLoading(false);
    setShowModal(false);
  };

  const onSubmit = () => {
    const locationData = branchLocationList?.fields?.items?.filter((item) => {
      return item?.fields?.allowCandidateAssignment?.value === true;
    });

    NearbyFinder(branchLocationList, latLng.lat, latLng.lng, true).then(
      (sortedNearbyLocations) => {
        const branchId =
          sortedNearbyLocations && sortedNearbyLocations[0]?.fields?.branchId?.value;
        if (selectedSkills && selectedSkills.length > 0) {
          if (locationAdress && !locationAdress?.zip) {
            setError('zipCode', {
              type: 'required',
              message: t('please-enter-valid-zipcode'),
            });
            return;
          }
          setPostApiLoading(true);
          const allSkills = selectedSkills.map((item) => item.value);
          const formData = new FormData();
          formData.append(
            'profileData',
            JSON.stringify(
              postPersonalInfoData(
                locationAdressFromApi,
                sitecoreContext,
                branchId,
                allSkills,
                locationAdress,
                profileInfoFromPopup,
                locationData
              )
            )
          );
          if (locationAdress && locationAdress?.zip) {
            dataFetcher(
              CREATE_PROFILE.POST_CREATE_PROFILE.CANDIDATE_PERSONAL_INFO_FROM_MODAL,
              'POST',
              formData
            ).then(
              (response) => {
                // temporary fix - for specific zipcodes
                if (response?.data?.message !== 'show thankyou message') {
                  getCandidateDetailsApiPromiseMethod(
                    loginReducer?.user,
                    dispatch,
                    true
                  )
                    .then(() => {
                      toast.success(t('saved-skills-and-zipcode'), {
                        position: 'top-center',
                      });
                      setPostApiLoading(false);
                      setShowModal(false);
                      dispatch(isPopupDetailsTriggered(false));
                      sessionStorage.setItem('captureAdditionalModalPopup', false);
                    })
                    .catch(() => {
                      toast.success(t('error-message'), {
                        position: 'top-center',
                      });
                      setPostApiLoading(false);
                      dispatch(logoutSuccess());
                    });
                } else {
                  toast.error(t('branch-assignment-response-information'), {
                    position: 'top-center',
                  });
                  setPostApiLoading(false);
                  dispatch(logoutSuccess());
                }
              },
              () => {
                handleError();
              }
            );
          }
        }
      }
    );
  };

  const colourStyles = {
    control: (styles, provided, state, isFocused) => ({
      ...styles,
      cursor: 'pointer',
      border: isFocused ? '2px solid black' : '1px solid gray',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'black', // Border color on hover
      },
    }),
  };

  const initPlaceSearch = () => {
    if (scriptLoaded === false) {
      if (window?.google) {
        handleScriptLoad();
        setScriptLoaded(true);
      }
    }
  };

  const handleScriptLoad = () => {
    const countryNames = countryName === 'us' ? [countryName, 'pr'] : [countryName];
    if (window.google.maps.places?.Autocomplete) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteZipRef.current,
        {
          types: ['postal_code'],
          componentRestrictions: {
            country: countryNames,
          },
          fields: ['address_components', 'formatted_address', 'geometry', 'name'],
        }
      );
      if (countryCodesList) {
        autoComplete.setComponentRestrictions({
          country: countryCodesList.split(','),
        });
      }
      autoComplete.addListener('place_changed', () => {
        handlePlaceSelect(autoComplete);
      });
    }
  };
  const checkSkills = () => {
    if (selectedSkills && !selectedSkills.length > 0) {
      return t('skills-required');
    }
  };
  async function handlePlaceSelect(autoComplete) {
    const addressObject = await autoComplete.getPlace();
    // const name = addressObject?.name;
    const address = addressObject?.address_components;

    // if (name) {
    //   // getPlacePredictionsOthers(name, countryName, (value) => {
    //   setValue('zipCode', name);
    //   // });
    // }
    setLatLng({
      lat: addressObject?.geometry?.location.lat(),
      lng: addressObject?.geometry?.location.lng(),
    });
    if (address) {
      const formatAddress = fillInAddress(address);
      setLocationAddress(formatAddress);
    } else {
      setLocationAddress({});
    }
  }
  const removeSkill = (valueToRemove) => {
    const updatedSkills = selectedSkills.filter(
      (skill) => skill.value !== valueToRemove
    );

    setSelectedSkills(updatedSkills);
  };
  const updateSelectedSkills = (selectedOptions) => {
    const alreadySelected = selectedSkills.some(
      (skill) => skill.value === selectedOptions.value
    );

    if (!alreadySelected) {
      const skillsSelected = [...selectedSkills, selectedOptions];
      setSelectedSkills(skillsSelected);
    }
  };
  return (
    <>
      {isUserInUS ? (
        <ModalComponent
          showModal={showModal}
          handleModal={handleModal}
          hideHeader={true}
          hideFooter={true}
          size="md"
          backdrop="static"
          keyboard={false}
        >
          <div className="inner-modal-container skillsModal" key="body">
            <div className=" text-center">
              <h2 className="title">{t('additional-information')}</h2>
              <p> {t('additional-details')}</p>
            </div>

            <div className="row mx-4">
              <div className="col-md-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row forms">
                    <div className="col-lg-12 form-block text-left">
                      <h4> {t('zipCode')}</h4>
                      <p> {t('zipcode-details')}</p>
                      <div className="zip-code form-block">
                        <Controller
                          name="zipCode"
                          control={control}
                          defaultValue=""
                          render={({ onChange, value, field }) => (
                            <input
                              {...field}
                              ref={autoCompleteZipRef}
                              type="text"
                              value={value || ''}
                              onChange={(selected) => {
                                const selectedVal = selected?.target?.value
                                  ? selected?.target?.value
                                  : selected;
                                onChange(selectedVal);

                                initPlaceSearch();
                              }}
                              placeholder={t('zipCode')}
                              disabled={profileInfoFromPopup?.PersonalDetails?.data?.[0].address.zip} // added for cross login - country code #51780 
                            />
                          )}
                          rules={{ required: t('zipCode-required') }} // Add required validation rule
                        />
                        {errors.zipCode && (
                          <span className="error-msg">{errors.zipCode.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 form-block text-left">
                      <h4> {t('expertise-skills')}</h4>
                      <p> {t('expertise-skills-details')}</p>
                      <div className="skills-select">
                        <div className={`form-block skills`}>
                          <Controller
                            name="skills"
                            control={control}
                            placeholder={t('skills')}
                            defaultValue={null}
                            render={({ field, value }) => (
                              <Select
                                {...field}
                                closeMenuOnSelect={true}
                                placeholder={t('skills')}
                                options={skillsOptions}
                                menuPlacement="auto"
                                value={value || null}
                                styles={colourStyles}
                                onChange={(selectedOptions) => {
                                  updateSelectedSkills(selectedOptions);
                                }}
                              />
                            )}
                            rules={{
                              required: t('skills-required'),
                              validate: checkSkills,
                            }}
                          />
                        </div>
                      </div>
                      {errors.skills && !selectedSkills?.length > 0 && (
                        <div className="error-msg">{errors.skills.message}</div>
                      )}
                    </div>
                    <div className="col-lg-12 selected-skilss-container">
                      {selectedSkills &&
                        selectedSkills.map((skill) => (
                          <div
                            role="button"
                            tabIndex={0}
                            key={skill.value}
                            className="rounded-pill skill-pill"
                            onClick={() => removeSkill(skill.value)}
                            onKeyUp={() => removeSkill(skill.value)}
                          >
                            <span>{skill.label}</span>
                            <span className="close-icon">&#x2715;</span>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="save-btn text-center">
                    <Button
                      isLoading={postApiLoading}
                      text={t('save-changes')}
                      type="submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ModalComponent>
      ) : null}
    </>
  );
};

export default withSitecoreContext()(withTranslation()(SkillsAndZipcodeModal));
