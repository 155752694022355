/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  // withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Loader from '../../../../../core/ErrorBoundary/Loader';
import Error from '../../../../../core/ErrorBoundary/Error';
import { getCandidateDetailsApi } from '../../../../../../services/apiServices/candidateService';
import { clearProfileInfo } from '../../../../../../redux/actions/actions';
import PaymentStubs from './PaymentStubs';
import PaymentStubDetails from './PaymentStubDetails';
import { useHistory } from 'react-router-dom';
import { API_STATUS_CODES, DASHBOARD } from '../../../../../../constants';
import { dataFetcher } from '../../../../../../dataFetcher';
import FilingInformation from './FilingInformation';
import CurrentAndYTD from './CurrentAndYTD';
import CurrentEarningDetails from './CurrentEarningDetails';
import { scrollToTop } from '../../../../../../utils/helperUtils';
import Deduction from './Deduction';
import NonTaxable from './NonTaxable';
import Taxes from './Taxes';

/**
 * @description - Payment Option Component
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const PaymentStubAccordion = ({ t, profileInfo, dispatch, sitecoreContext }) => {
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [earningDetails, setEarningDetails] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [paymentStubsData, setPaymentStubsData] = useState(null);
  const [detailedPageView, setDetailedPageView] = useState(false);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(false);
  // const location = useLocation();
  // Needed for future use - UAT
  //const params = new URLSearchParams(location?.search);
  //const [officeId] = useState(params.get('officeId'));
  const { route } = sitecoreContext;
  const placeholders = route?.placeholders;
  const history = useHistory();

  const paymentStubAccordionDetails =
    placeholders &&
    placeholders['jss-main'][0].placeholders['jss-content'][0].placeholders[
      'jss-content'
    ];
  const title = paymentStubAccordionDetails[0]?.fields?.title;
  const titleEntity = paymentStubAccordionDetails[0]?.fields?.titleEntity;
  const description = paymentStubAccordionDetails[0]?.fields?.description;
  const descriptionEntity =
    paymentStubAccordionDetails[0]?.fields?.descriptionEntity;
  const entity = profileInfo?.PersonalDetails?.data[0]?.bhBranchEntity;
  const officeId = profileInfo?.PersonalDetails?.data[0]?.desiredSalary;
  const [descriptionData, setDescriptionData] = useState(null);
  const [mainTitle, setMainTitle] = useState(null);

  useEffect(() => {
    if (entity !== undefined) {
      if (entity === 'MPUSA' || entity === 'MPCAN') {
        setDescriptionData(description);
        setMainTitle(title);
      } else {
        setDescriptionData(descriptionEntity);
        setMainTitle(titleEntity);
      }
    }
  }, [entity]);

  useEffect(() => {
    if (profileInfo?.Id) {
      setprofileDataLoading(false);
      if (profileInfo && !profileInfo?.isAssociate) {
        history.push('/en/page-not-found');
      }
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);

  useEffect(() => {
    setLoading(true);
    dataFetcher(
      `${DASHBOARD.PAYMENT_STUBS.GET_CHECKLIST}?officeId=${officeId}`,
      'GET'
    )
      .then((response) => {
        if (response.status === API_STATUS_CODES.OK) {
          if (response?.data[0]?.CHECKDDNUMBER) {
            setPaymentStubsData(response?.data);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, [officeId]);

  const handleApiRefresh = () => {
    dispatch(clearProfileInfo());
    setTimeout(() => getCandidateDetailsApi({}, dispatch), 500);
  };

  const goToDetailedView = (checkNumber, employeeId, GK_PAYSTUBSJDE1) => {
    if (!detailedPageView) {
      setprofileDataLoading(true);

      const earningDetailsPromise = dataFetcher(
        `${DASHBOARD.PAYMENT_STUBS.GET_EARNING_DETAILS}?checkNumber=${GK_PAYSTUBSJDE1}`,
        'GET'
      );

      const paymentDetailsPromise = dataFetcher(
        `${DASHBOARD.PAYMENT_STUBS.GET_PAYMENT_DETAILS}?checkNumber=${checkNumber}&employeeId=${employeeId}`,
        'GET'
      );

      const categoryDetailsPromise = dataFetcher(
        `${DASHBOARD.PAYMENT_STUBS.GET_CATEGORY_DETAILS}?checkNumber=${GK_PAYSTUBSJDE1}`,
        'GET'
      );
      Promise.all([
        earningDetailsPromise,
        paymentDetailsPromise,
        categoryDetailsPromise,
      ])
        .then(
          ([
            earningDetailsResponse,
            paymentDetailsResponse,
            categoryDetailsPromise,
          ]) => {
            if (
              earningDetailsResponse.status === API_STATUS_CODES.OK &&
              paymentDetailsResponse.status === API_STATUS_CODES.OK &&
              categoryDetailsPromise.status === API_STATUS_CODES.OK
            ) {
              setEarningDetails(earningDetailsResponse?.data);
              setPaymentDetails(paymentDetailsResponse?.data);
              setCategoryDetails(categoryDetailsPromise?.data);
              console.log(categoryDetailsPromise?.data);
              setprofileDataLoading(false);
              setDetailedPageView(true);
            }
          }
        )
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      setDetailedPageView(false);
    }
  };

  const goBack = () => {
    setDetailedPageView(false);
    scrollToTop();
  };
  return (
    <section
      className="profile-accordion-section"
      aria-labelledby="title"
      aria-describedby="info"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 pt-5">
            {title?.value && !detailedPageView && (
              <div className="row pt-2">
                <div className="col-md-12">
                  <Text
                    field={mainTitle}
                    tag="h2"
                    className="title-small"
                    id="info"
                  />
                </div>
              </div>
            )}
            {descriptionData?.value && !detailedPageView && (
              <div className="row py-2">
                <div className="col-md-12">
                  <Text field={descriptionData} className="description" id="info" />
                </div>
              </div>
            )}
            {profileDataLoading ? (
              <Loader
                loaderMsg={t('fetching-your-data')}
                loaderWithMsg
                customLoaderStyle={{
                  width: '4rem',
                  height: '4rem',
                  color: '#C25700',
                }}
                customTextStyle={{ color: '#C25700' }}
              />
            ) : error ? (
              <div className="apiError">
                <Error
                  errorMsg={t('error-message')}
                  t={t}
                  refreshApi={true}
                  handleApiRefresh={handleApiRefresh}
                />
              </div>
            ) : (
              <div className="pt-2">
                {detailedPageView ? (
                  <>
                    <div className="row">
                      <button
                        onClick={() => goBack()}
                        className="primary-button backButton"
                      >
                        {t('Back')}
                      </button>
                    </div>
                    <PaymentStubDetails
                      fields={paymentStubAccordionDetails[1]?.fields}
                      t={t}
                      profileInfo={profileInfo}
                      paymentDetails={paymentDetails?.paymentDetails}
                    />
                    <FilingInformation
                      fields={paymentStubAccordionDetails[2]?.fields}
                      t={t}
                      profileInfo={profileInfo}
                      tableData={paymentDetails?.filingInformation}
                    />
                    <CurrentAndYTD
                      fields={paymentStubAccordionDetails[3]?.fields}
                      t={t}
                      profileInfo={profileInfo}
                      tableData={paymentDetails?.currentYTD}
                    />
                    <CurrentEarningDetails
                      fields={paymentStubAccordionDetails[4]?.fields}
                      t={t}
                      profileInfo={profileInfo}
                      tableData={earningDetails}
                    />
                    <Taxes
                      fields={paymentStubAccordionDetails[5]?.fields}
                      t={t}
                      profileInfo={profileInfo}
                      tableData={categoryDetails?.taxes}
                    />

                    <Deduction
                      fields={paymentStubAccordionDetails[6]?.fields}
                      t={t}
                      profileInfo={profileInfo}
                      tableData={categoryDetails?.deductions}
                    />
                    <NonTaxable
                      fields={paymentStubAccordionDetails[7]?.fields}
                      t={t}
                      profileInfo={profileInfo}
                      tableData={categoryDetails?.nonTaxable}
                    />

                    <div className="row">
                      <button
                        onClick={() => goBack()}
                        className="primary-button backButton"
                      >
                        {t('Back')}
                      </button>
                    </div>
                  </>
                ) : (
                  <PaymentStubs
                    fields={paymentStubAccordionDetails[0]?.fields}
                    t={t}
                    profileInfo={profileInfo}
                    goToDetailedView={goToDetailedView}
                    loading={loading}
                    paymentStubsData={paymentStubsData}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

PaymentStubAccordion.defaultProps = {
  t: (val) => val,
  fields: {},
  profileInfo: {},
  dispatch: () => {},
};

PaymentStubAccordion.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(
  connect(mapStateToProps)(withSitecoreContext()(PaymentStubAccordion))
);
