/* eslint-disable require-jsdoc */
/* eslint-disable complexity */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { maskNumber } from '../../../../../../utils/helperUtils';
import '../PaymentOptions/paymentOptions.scss';

const PaymentSuccessTemplate = ({ type, fields, t, userData, handleModal }) => {
  const {
    ContinueCTAText,
    messageTitle,
    messageDescription,
    messageInfo,
    payCardDeliveryInfo,
    paperCheckDeliveryInfo,
  } = fields;

  const countryName = userData?.sourceCountry.toLowerCase() || '';
  return (
    <div className="payment-success-information">
      <div className="row">
        <div className="col  d-flex justify-content-center">
          <Text field={messageTitle} tag="h5" className="title" />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col">
          <RichText field={messageDescription} className="subtitle" />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col  d-flex justify-content-center">
          <Text field={messageInfo} tag="h5" className="payment-method-info-title" />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <h5 className="user-details-title">{t('payment-method')}</h5>
          <p className="user-details-data">{userData?.paymentMethod}</p>
        </div>
      </div>
      {type === 'DirectDeposit' ? (
        <>
          <div className="row mt-4">
            <div className="col-6">
              <h5 className="user-details-title">{t('bank-name')}</h5>
              <p className="user-details-data">{userData?.customText35}</p>
            </div>
            <div className="col-6">
              {countryName !== 'ca' ? (
                <div>
                  <h5 className="user-details-title">{t('account-type')}</h5>
                  <p className="user-details-data">{userData?.customText34}</p>
                </div>
              ) : (
                <div>
                  <h5 className="user-details-title">{t('bank-code')}</h5>
                  <p className="user-details-data">
                    {userData?.customEncryptedText4}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="row my-4">
            <div className="col-6">
              <h5 className="user-details-title">{t('transit-routing')}</h5>
              <p className="user-details-data">
                {userData?.customEncryptedText1
                  ? maskNumber(userData?.customEncryptedText1, -4, 'X')
                  : ''}
              </p>
            </div>
            <div className="col-6">
              <h5 className="user-details-title">{t('account-number')}</h5>
              <p className="user-details-data">
                {userData?.customEncryptedText2
                  ? maskNumber(userData?.customEncryptedText2, -4, 'X')
                  : ''}
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row mt-4">
            <div className="col">
              <h5 className="user-details-title">{t('full-name')}</h5>
              <p className="user-details-data">{`${userData?.firstName} ${userData?.lastName}`}</p>
            </div>
          </div>

          <div className="row my-4">
            {userData?.MailingAddress?.zip && userData?.customText16 !== 'true' ? (
              <div className="col">
                <p className="user-details-title">{t('mailing-address')}</p>

                <p className="user-details-data">
                  {userData?.MailingAddress?.address1 &&
                    `${userData?.MailingAddress?.address1}`}
                  {userData?.MailingAddress?.address1 && <br />}
                  {userData?.MailingAddress?.address2 &&
                    `${userData?.MailingAddress?.address2}`}
                  {userData?.MailingAddress?.address2 && <br />}
                  {userData?.MailingAddress?.city &&
                    `${userData?.MailingAddress?.city},`}
                  {userData?.MailingAddress?.state &&
                    `${userData?.MailingAddress?.state} `}
                  {userData?.MailingAddress?.zip &&
                    `${userData?.MailingAddress?.zip}`}
                </p>
              </div>
            ) : null}
            {userData?.address?.zip && userData?.customText16 === 'true' ? (
              <div className="col">
                <p className="user-details-title">{t('mailing-address')}</p>
                <p className="user-details-data">
                  {userData?.address?.address1 && `${userData?.address?.address1}`}
                  {userData?.address?.address1 && <br />}
                  {userData?.address?.address2 && `${userData?.address?.address2}`}
                  {userData?.address?.address2 && <br />}
                  {userData?.address?.city && `${userData?.address?.city},`}
                  {userData?.address?.state && `${userData?.address?.state} `}
                  {userData?.address?.zip && `${userData?.address?.zip}`}
                </p>
              </div>
            ) : null}
          </div>
        </>
      )}

      <div className="row">
        <div className="col d-flex justify-content-center">
          <button
            onClick={handleModal}
            className="primary-button reversed-button close-btn ml-0"
          >
            {ContinueCTAText?.value}
          </button>
        </div>
      </div>
      {type !== 'DirectDeposit' ? (
        <div className="row my-4">
          <div className="col">
            <p className="user-details-data">
              {type === 'PayCard'
                ? `${payCardDeliveryInfo?.value}`
                : `${paperCheckDeliveryInfo?.value}`}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

PaymentSuccessTemplate.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
  handleModal: PropTypes.func.isRequired,
};

export default withTranslation()(PaymentSuccessTemplate);
