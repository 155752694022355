import React, { useState, useEffect } from 'react';
import CheckboxGroup from './CheckboxGroup';

const ContactPreference = (props) => {
  const { formProps, form, options, register } = props;
  const { value } = formProps;
  const [checkedValues, setCheckedValues] = useState();
  useEffect(() => {
    if (value?.length > 0) {
      let values;
      if (typeof value === 'string') {
        values = value.split(',');
      } else {
        values = value;
      }
      setCheckedValues(values);
    } else {
      setCheckedValues([]);
    }
  }, [value]);

  return (
    <>
      <div className="formAnswer contactpreference">
        <CheckboxGroup
          setCheckedValues={setCheckedValues}
          checkedValues={checkedValues}
          {...formProps}
          id={`multiSelect_${form?.id}`}
          options={options}
          type="checkbox"
          defaultOptions={checkedValues}
          requiredValidation={[]}
          toggleStyle={{
            display: 'flex',
            flexDirection:
              form?.fields?.position?.value &&
              form?.fields?.position?.value === 'horizontal'
                ? 'row'
                : 'column',
          }}
        />
        <input
          type="hidden"
          name={`${form?.fields?.name?.value}`}
          ref={register ? register : () => {}}
        />
      </div>
    </>
  );
};

export default ContactPreference;
