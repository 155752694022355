/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import DashboardAccordion from '../../DashboardAccordion';
import moment from 'moment';
import { DATE_FORMATS } from '../../../../../../utils/enums';
import PaymentStubsTable from '../../../../../core/PaymentStubsTable';

/**
 * @description - PaymentStubTable component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const PaymentStubTable = ({ t, fields, tableData, sitecoreContext }) => {
  const { icon } = fields;
  const [paymentStubData, setPaymentStubData] = useState([]);
  const [loading, setLoading] = useState(null);
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';

  const columns = [
    {
      selector: 'workdate',
      name: t('workdate'),
      grow: 1,
      cell: (row) => {
        console.log(moment(row?.workdate)._isValid);
        return (
          <div className="stubs-content-data">
            {countryName === 'ca' && moment(row?.workdate)._isValid
              ? moment(row?.workdate).format(DATE_FORMATS.CANADA_FORMAT)
              : moment(row?.workdate)._isValid
              ? moment(row?.workdate).format(DATE_FORMATS.DEFAULT_US_MM_DD_YYYY)
              : ''}
          </div>
        );
      },
    },
    {
      selector: 'jobTitle',
      name: t('jobTitle'),
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.jobTitle}</div>;
      },
    },
    {
      selector: 'orderNum',
      name: t('orderNum'),
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.orderNum}</div>;
      },
    },
    {
      selector: 'description',
      name: t('description'),
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.description}</div>;
      },
    },
    {
      selector: 'hours',
      name: t('stub-hours'),
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.hours}</div>;
      },
    },
    {
      selector: 'payRate',
      name: t('payRate'),
      grow: 1,
      right: true,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.payRate}</div>;
      },
    },
    {
      selector: 'amount',
      name: t('amount'),
      right: true,
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.amount}</div>;
      },
    },
  ];
  useEffect(() => {
    setLoading(true);
    prepareData(tableData);
    setLoading(false);
  }, []);

  const prepareData = (responseData) => {
    const data = responseData?.map((object, index) => ({
      ...object,
      id: index + 1,
    }));
    setPaymentStubData(data);
  };

  return (
    <>
      <PaymentStubsTable
        columns={columns}
        icon={icon}
        row={paymentStubData}
        pagination={paymentStubData?.length > 5 ? true : false}
        noHeader={true}
        showHeader={false}
        noTableHead={false}
        progressPending={loading}
        noDataMessage={t('empty-payment-stub-content')}
      />
    </>
  );
};
const CurrentEarningDetails = ({ fields, t, profileInfo, tableData }) => {
  const { title } = fields;
  const [open, setOpen] = useState(true);

  return (
    <div>
      <DashboardAccordion
        id={title?.value}
        title={title}
        isOpen={open}
        handleToggle={() => setOpen(!open)}
        content={
          <PaymentStubTable
            profileInfo={profileInfo}
            fields={fields}
            t={t}
            tableData={tableData}
          />
        }
      />
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(CurrentEarningDetails));
