/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import DashboardAccordion from '../../DashboardAccordion';
import PaymentStubsTable from '../../../../../core/PaymentStubsTable';

/**
 * @description - PaymentStubTable component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const PaymentStubTable = ({ t, fields, tableData }) => {
  const { icon } = fields;
  const [paymentStubData, setPaymentStubData] = useState([]);
  const [loading, setLoading] = useState(null);

  const columns = [
    {
      selector: 'description',
      name: t('description'),
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.description}</div>;
      },
    },
    {
      selector: 'currentAmount',
      name: t('currentAmount'),
      right: true,
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.currentAmount}</div>;
      },
    },

    {
      selector: 'ytdAmount',
      name: t('ytdAmount'),
      right: true,
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.ytdAmount}</div>;
      },
    },
  ];
  useEffect(() => {
    setLoading(true);
    prepareData(tableData);
    setLoading(false);
  }, []);

  const prepareData = (responseData) => {
    const data = responseData?.map((object, index) => ({
      ...object,
      id: index + 1,
    }));
    setPaymentStubData(data);
  };

  return (
    <>
      <PaymentStubsTable
        columns={columns}
        icon={icon}
        row={paymentStubData}
        pagination={paymentStubData?.length > 5 ? true : false}
        noHeader={true}
        showHeader={false}
        noTableHead={false}
        progressPending={loading}
        noDataMessage={t('empty-payment-stub-content')}
      />
    </>
  );
};
const NonTaxable = ({ fields, t, profileInfo, tableData }) => {
  const { title } = fields;
  const [open, setOpen] = useState(true);
  useEffect(() => {
    if (tableData && tableData?.length <= 0) {
      setOpen(false);
    }
  }, [tableData]);

  return (
    <div>
      <DashboardAccordion
        id={title?.value}
        title={title}
        noContent={t('No Non-Taxable data is available')}
        isOpen={open}
        handleToggle={() => setOpen(!open)}
        content={
          <PaymentStubTable
            profileInfo={profileInfo}
            fields={fields}
            t={t}
            tableData={tableData}
          />
        }
      />
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(NonTaxable));
