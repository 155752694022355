/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import './tax-forms.scss';
import { DASHBOARD } from '../../../../../../constants';
import Loader from '../../../../../core/ErrorBoundary/Loader';
import Error from '../../../../../core/ErrorBoundary/Error';
import DynamicForm from '../../../../../core/Forms/DynamicForm';
import { dataFetcher } from '../../../../../../dataFetcher';
import { toast } from 'react-toastify';

const ChangePinTemplate = ({
  fields,
  t,
  userData,
  selectedWorkAddress,
  handleModal,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    control,
    trigger,
    watch,
    getValues,
  } = useForm({
    mode: 'onClick',
    reValidateMode: 'onBlur',
  });
  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading] = useState(false);
  const [error, setError] = useState(false);
  const [xmlData, setXmlData] = useState('');
  const handleSavePaymentInfo = (inputData) => {
    const postData = {
      CreateTaxForm: {
        employee: {
          id: userData?.id,
          firstName: userData?.firstName,
          middleName: userData?.middleName,
          lastName: userData?.lastName,
          ssn: userData?.ssn,
          address: { ...userData?.address },
          PIN: { value: inputData?.reEnterPin },
        },
        workAddress: { ...selectedWorkAddress },
      },
    };
    const formData = new FormData();
    formData.append('profileData', JSON.stringify(postData));

    dataFetcher(`${DASHBOARD.TAX_FORMS.SAVE_TAX_FORMS_INFO}`, 'POST', formData)
      .then((response) => {
        if (response) {
          setXmlTaxFormData(response?.data);
        }
      })
      .catch(() => {
        setError(true);
      });
  };
  const setXmlTaxFormData = (response) => {
    if (response.status === 'OK') {
      const form = document.getElementById('myForm');
      setXmlData(response.xml);
      form.xml.value = response.xml;
      form.submit();
    } else {
      toast.error(t('state-code-not-found'), {
        position: 'top-center',
      });
    }
  };
  return (
    <>
      <form
        id="myForm"
        method="post"
        action="https://spf.symmetry.com/spf/get-started?profile=MTZ6dW5GNGVuaGphZFZLMXFFcVRDTHlGazA1YkswcHZvYjZTVkdzS3BkND0="
        encType="multipart/form-data"
        className="noDisplay"
      >
        <textarea id="xml" name="xml" className="divxml">
          {xmlData}
        </textarea>
      </form>
      <div className="payment-method">
        {loading ? (
          <Loader
            loaderMsg={t('saving-tax-forms')}
            loaderWithMsg
            customLoaderStyle={{
              width: '4rem',
              height: '4rem',
              color: '#C25700',
            }}
            customTextStyle={{ color: '#C25700' }}
          />
        ) : error ? (
          <>
            <div className="apiError">
              <Error errorMsg={t('error-message')} t={t} />
            </div>
            <div className="row">
              <div className="col d-flex justify-content-center">
                <button
                  onClick={handleModal}
                  className="primary-button reversed-button close-btn ml-0"
                >
                  {fields?.cancelCTA?.value}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col  d-flex justify-content-center">
                <Text
                  field={fields?.changePinModalTitle}
                  tag="h5"
                  className="title"
                />
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col">
                <RichText
                  tag="h5"
                  className="subtitle"
                  field={fields?.changePinModalDescription}
                />
              </div>
            </div>

            <DynamicForm
              formData={fields?.changePinFields}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              control={control}
              trigger={trigger}
              watch={watch}
            />

            <div className="row">
              <div className="col d-flex justify-content-center">
                <button
                  className="primary-button orange-sd"
                  onClick={handleSubmit(handleSavePaymentInfo)}
                >
                  {fields?.changePinCTA?.value}
                </button>
                <button
                  onClick={handleModal}
                  className="primary-button reversed-button close-btn"
                >
                  {fields?.cancelCTA?.value}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withTranslation()(ChangePinTemplate);
