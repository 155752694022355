/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React from 'react';
// import moment from 'moment';
import './assignmentBlock.scss';
// import { DATE_FORMATS } from '../../../../../utils/enums';

const AssignmentContent = ({ item }) => {
  const { modifiedDateBegin, modifiedDateEnd, jobOrder, payRate } = item;
  const { clientCorporation, location, title } = jobOrder;
  const {
    address: { address1, address2, city, state, zip },
  } = location;
  return (
    <>
      <div className="container assignment-info">
        <div className="row">
          <div className="column assignment-details">
            <div className="sub-column">
              {title && <p className="job-title">{title}</p>}
              {payRate && <p className="pay-rate">{payRate}</p>}
            </div>
            <div className="sub-column">
              {clientCorporation?.name && (
                <p className="client-name">{clientCorporation?.name}</p>
              )}
              <p className="location">
                {address1 && address1} {address2 && address2},<br />
                {city && city}, {state && state} {zip && zip}
              </p>
            </div>
            <div className="sub-column date">
              <p className="start-date">
                {modifiedDateBegin &&
                  // moment(parseInt(modifiedDateBegin)).format(
                  //   countryName === 'ca'
                  //     ? DATE_FORMATS.ASSOCIATE_CA_FORMAT
                  //     : countryName === 'us'
                  //     ? DATE_FORMATS.ASSOCIATE_US_FORMAT
                  //     : DATE_FORMATS.ROBOTS_FORMAT_REV
                  // )
                  modifiedDateBegin}{' '}
                -{' '}
                {modifiedDateEnd &&
                  // moment(parseInt(modifiedDateEnd)).format(
                  //   countryName === 'ca'
                  //     ? DATE_FORMATS.ASSOCIATE_CA_FORMAT
                  //     : countryName === 'us'
                  //     ? DATE_FORMATS.ASSOCIATE_US_FORMAT
                  //     : DATE_FORMATS.ROBOTS_FORMAT_REV
                  // )
                  modifiedDateEnd}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentContent;
