/* eslint-disable */
import React, { useEffect, useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '../../../core/globals/buttons/Button';
import { jobApplyApi } from '../../../../services/apiServices/candidateService';
import {
  RichText,
  Text,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { useDispatch, useSelector } from 'react-redux';
import CustomOverlayLoader from '../../../core/ErrorBoundary/CustomOverlayLoader';
import {
  isNorthAmericanCountry,
  isNullorEmpty,
  isObjNotEmpty,
  replaceTextwithJSX,
} from '../../../../utils/helperUtils';
import { preJobApplyEvent } from '../../../../services/apiServices/gtmService';
import { toast } from 'react-toastify';
import ToggleForm from './ToggleForm';
import { useLocation } from 'react-router-dom';
import {
  clearJobApplyFormTriggerData,
  clearPublicPosition,
  clearScreenerQuestions,
  clearJobApplyFormState,
  clearJobApplyFormData,
} from '../../../../redux/actions/actions';
import NormalForm from './NormalForm';
import AuthWrapper from '../../../core/LoginMSAL/AuthWrapper';
import TextButton from '../../../core/globals/buttons/TextButton';
import './jobApplyForm.scss';
import ModalComponent from '../../../core/Modal';

/**
 * @description - JobApply Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const JobApply = ({ inputRef, sitecoreContext, ...props }) => {
  const { candidateJobDetails, fields, t, context } = props;
  const location = useLocation();
  const {
    title,
    subTitle,
    items = [],
    ctaUrl,
    errorPageUrl,
    text,
    userNameIdentifier,
    signInText,
    isConsentPopupRequired,
    abstract,
  } = fields;

  const userNameIdentifierVal = userNameIdentifier?.value || 'firstName';
  const profileInfoReducer = useSelector((state) => state.profileInfoReducer);
  const loginReducer = useSelector((state) => state.loginReducer);
  const publicPositionData = useSelector(
    (state) => state?.publicPositionReducer?.data
  );
  const screenerQuestionData = useSelector(
    (state) => state?.screenerQuestionsReducer?.data
  );
  const sectionNames = fields?.sectionNames?.value?.split(',') || [];
  const history = useHistory();
  const [data, setData] = useState({});
  const [candidateId, setCandidateId] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [utmParams, setUtmParams] = useState({});
  const [newTitle, setTitle] = useState(title?.value);
  const dispatch = useDispatch();
  const [currentActiveTab, setCurrentActiveTab] = useState({});
  const [applyJobButtonClicked, setApplyJobButtonClicked] = useState(false);
  const [triggerArray, setTriggerArray] = useState([]);
  const [invalidArray, setInvalidArray] = useState([]);
  const [mandatorySections, setMandatorySections] = useState([]);
  const [alwaysTriggeredSections, setAlwaysTriggeredSections] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const getJobdisplayName = context?.route?.displayName?.split('-') || '';
  const isStandardJob =
    getJobdisplayName[getJobdisplayName?.length - 1]?.toLowerCase() === 'sa';

  const jobAppplyFormState = useSelector((state) => state?.jobApplyFormStateReducer);
  const jobAppplyFormData = useSelector((state) => state?.jobApplyFormDataReducer);
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';

  useEffect(() => {
    return () => {
      dispatch(clearScreenerQuestions());
      dispatch(clearPublicPosition());
      dispatch(clearJobApplyFormData());
      dispatch(clearJobApplyFormState());
    };
  }, []);

  useEffect(() => {
    preJobApplyEvent(
      candidateJobDetails,
      context,
      loginReducer?.state,
      isStandardJob
    );
    if (sessionStorage) {
      let utmValues = sessionStorage.getItem('utmParams')
        ? sessionStorage.getItem('utmParams')
        : sessionStorage.getItem('utmReferrer');
      utmValues = !isNullorEmpty(utmValues) ? JSON.parse(utmValues) : {};
      setUtmParams(utmValues);
    }
  }, []);

  useEffect(() => {
    const standardForms = items?.filter(
      (item) => item?.fields?.layoutType?.value !== 'ToggleForm'
    );
    const mandatory = [];
    const alwaysTrigger = [];
    standardForms?.map((section) => {
      if (section?.fields?.isMandatory?.value)
        mandatory.push(section?.fields?.sectionName?.value);
      if (section?.fields?.triggerAlwaysIfValid?.value) {
        alwaysTrigger.push(section?.fields?.sectionName?.value);
      }
    });
    setMandatorySections(mandatory);
    setAlwaysTriggeredSections(alwaysTrigger);
  }, []);

  useEffect(() => {
    if (loginReducer?.user?.firstName) {
      const formattedTitle = title?.value?.replace(
        '{0}',
        loginReducer?.user?.firstName || t('user')
      );
      setTitle(formattedTitle);
    }
  }, [loginReducer]);

  useEffect(() => {
    if (profileInfoReducer?.data?.Id) {
      setCandidateId(profileInfoReducer?.data?.Id);
      setData(profileInfoReducer?.data);
    }
  }, [profileInfoReducer]);

  const checkValid = () => {
    const validationArray = [];
    Object.keys(jobAppplyFormState).forEach((k, i) => {
      if (triggerArray.includes(k)) {
        validationArray.push(jobAppplyFormState[k].isValid);
      }
    });
    return !validationArray.includes(false);
  };

  const applyJob = () => {
    setApplyJobButtonClicked(true);
    let currentActiveSections = currentActiveTab?.sections?.split(',') || [];
    const mandatorySectionsOnSubmit = [
      ...mandatorySections,
      ...currentActiveSections,
    ];

    let form = {
      triggerList: [...alwaysTriggeredSections],
      invalidList: [],
    };
    Object.keys(jobAppplyFormState).forEach((item) => {
      if (isObjNotEmpty(jobAppplyFormState[item])) {
        if (
          (isObjNotEmpty(jobAppplyFormState[item]?.touched) &&
            !jobAppplyFormState[item]?.isEmpty) ||
          jobAppplyFormState[item]?.isDelete ||
          jobAppplyFormState[item]?.isClear ||
          jobAppplyFormState[item]?.isUpload
        ) {
          if (
            jobAppplyFormState[item].isValid ||
            jobAppplyFormState[item]?.isClear
          ) {
            if (!form?.triggerList?.includes(item)) form.triggerList.push(item);
          } else {
            form.invalidList.push(item);
          }
        }
      }
    });

    let mandatorySectionList = [
      ...new Set([...mandatorySectionsOnSubmit, ...form.triggerList]),
    ];
    for (let item in mandatorySectionList) {
      if (
        !jobAppplyFormState?.[mandatorySectionList[item]]?.isValid &&
        !form?.invalidList?.includes(item)
      ) {
        form.invalidList.push(mandatorySectionList[item]);
      }
    }

    if (form.invalidList.length > 0) {
      console.log(form);
      setInvalidArray(form.invalidList);
      showError('missing-information-text');
    } else if (form.triggerList.length > 0 && form.invalidList.length === 0) {
      console.log(form);
      setTriggerArray(form.triggerList);
    } else {
      triggerApplyApi();
    }
  };

  const triggerApplyApi = () => {
    setLoading(true);
    let dataFromInputs = { ...jobAppplyFormData };
    if (currentActiveTab?.key === 0) {
      dataFromInputs.triggered = dataFromInputs.triggered.filter(
        (component) => component !== 'EditExpertiseAndSkills'
      );
      dataFromInputs.validComponents = dataFromInputs.validComponents.filter(
        (component) => component !== 'EditExpertiseAndSkills'
      );
      delete dataFromInputs.data.EditExpertiseAndSkills;
    } else {
      dataFromInputs.triggered = dataFromInputs.triggered.filter(
        (component) => component !== 'UploadResume'
      );
      dataFromInputs.validComponents = dataFromInputs.validComponents.filter(
        (component) => component !== 'UploadResume'
      );
      delete dataFromInputs.data.UploadResume;
    }
    const data = {
      screenerQuestionData,
      publicPositionData,
      jobAppplyFormData: dataFromInputs,
    };

    jobApplyApi(
      data,
      showSuccess,
      applyJobLoader,
      showError,
      dispatch,
      candidateJobDetails,
      context,
      loginReducer,
      userNameIdentifierVal,
      candidateId,
      utmParams,
      t,
      isStandardJob,
      countryName
    );

    dispatch(clearJobApplyFormTriggerData([]));
  };

  useEffect(() => {
    if (
      applyJobButtonClicked &&
      jobAppplyFormData?.triggered?.length !== 0 &&
      jobAppplyFormData?.triggered?.length === triggerArray.length
    ) {
      if (
        jobAppplyFormData?.validComponents?.length === triggerArray.length &&
        checkValid()
      ) {
        triggerApplyApi();
      } else {
        if (
          Object.keys(jobAppplyFormData?.triggered)?.length === triggerArray?.length
        ) {
          toast.error(t('missing-information-text'), {
            position: 'top-center',
          });
          dispatch(clearJobApplyFormTriggerData([]));
          setApplyJobButtonClicked(false);
        }
      }
    }
  }, [jobAppplyFormData]);

  const showSuccess = (statusMsg = '') => {
    setLoading(false);
    history.push({
      pathname: `${ctaUrl.value.href}?id=${statusMsg || null}`,
    });
  };

  const applyJobLoader = () => {
    setLoading(false);
  };

  const showError = (
    message = 'error-message',
    failureRedirect = false,
    statusMsg = ''
  ) => {
    if (failureRedirect === true) {
      history.push({
        pathname: `${errorPageUrl?.value?.href}?id=${statusMsg || null}`,
      });
    } else {
      let errorMessage = message?.replace('{0}', candidateJobDetails?.jobTitle);
      toast.error(t(errorMessage), {
        position: 'top-center',
      });
    }
    setLoading(false);
  };

  /**
   * @description - switches the nav tabs.
   * @param {number} selectedIndex - Nav index which need to be activated
   * @returns {void} - void.
   */
  const tabItemClicked = (selectedTab) => {
    setCurrentActiveTab(selectedTab);
  };

  const jobApplyRef = {};
  sectionNames.map((sectionName) => {
    jobApplyRef[sectionName] = useRef(null);
  });

  useEffect(() => {
    if (triggerArray) {
      triggerArray.map((refItem) => {
        setTimeout(() => {
          jobApplyRef?.[refItem]?.current?.trigger();
        }, 10);
      });
    }
  }, [triggerArray]);

  useEffect(() => {
    if (invalidArray) {
      invalidArray.map((refItem) => {
        setTimeout(() => {
          jobApplyRef?.[refItem]?.current?.invalidTrigger();
        }, 10);
      });
    }
  }, [invalidArray]);

  const handleModal = () => {
    let currentActiveSections = currentActiveTab?.sections?.split(',') || [];
    const mandatorySectionsOnSubmit = [
      ...mandatorySections,
      ...currentActiveSections,
    ];

    let form = {
      triggerList: [...alwaysTriggeredSections],
      invalidList: [],
    };
    Object.keys(jobAppplyFormState).forEach((item) => {
      if (isObjNotEmpty(jobAppplyFormState[item])) {
        if (
          (isObjNotEmpty(jobAppplyFormState[item]?.touched) &&
            !jobAppplyFormState[item]?.isEmpty) ||
          jobAppplyFormState[item]?.isDelete ||
          jobAppplyFormState[item]?.isClear ||
          jobAppplyFormState[item]?.isUpload
        ) {
          if (
            jobAppplyFormState[item].isValid ||
            jobAppplyFormState[item]?.isClear
          ) {
            if (!form?.triggerList?.includes(item)) form.triggerList.push(item);
          } else {
            form.invalidList.push(item);
          }
        }
      }
    });

    let mandatorySectionList = [
      ...new Set([...mandatorySectionsOnSubmit, ...form.triggerList]),
    ];
    for (let item in mandatorySectionList) {
      if (
        !jobAppplyFormState?.[mandatorySectionList[item]]?.isValid &&
        !form?.invalidList?.includes(item)
      ) {
        form.invalidList.push(mandatorySectionList[item]);
      }
    }
    if (form.invalidList.length > 0) {
      setInvalidArray(form.invalidList);
      showError('missing-information-text');
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  const handleYes = () => {
    applyJob();
    setShowModal(false);
  };

  const handleNo = () => {
    setShowModal(false);
  };

  return (
    <section className="resume-upload">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            {newTitle && <h1 className="title">{newTitle}</h1>}
            <div className="subtitle-text">
              {signInText?.value
                ? replaceTextwithJSX(
                    subTitle?.value,
                    '{0}',
                    <AuthWrapper>
                      {({ login }) => {
                        return (
                          <TextButton
                            cssClass="blue"
                            text={signInText?.value}
                            handleTextClick={() => {
                              localStorage.setItem(
                                'authenticationType',
                                'Apply > Create Account / Apply > Sign in'
                              );
                              login(
                                null,
                                'createAccountAfterJobapply',
                                location?.pathname + location?.search
                              );
                            }}
                          />
                        );
                      }}
                    </AuthWrapper>
                  )
                : subTitle?.value}
            </div>
            <>
              <div className="form-validation-text">
                <Text field={text} />
              </div>
              <div className="col-lg-8 offset-lg-2 upload-resume-form">
                {items?.map((section, index) => {
                  return (
                    <div className={`form-section form_${index}`}>
                      {section?.fields?.layoutType?.value === 'ToggleForm' ? (
                        <ToggleForm
                          section={section}
                          ref={jobApplyRef}
                          updateSelectedTab={(selectedTab) =>
                            tabItemClicked(selectedTab)
                          }
                        />
                      ) : (
                        <NormalForm
                          section={section}
                          ref={jobApplyRef[section?.fields?.sectionName?.value]}
                          componentName={section?.fields?.sectionName?.value}
                        />
                      )}
                      {section?.fields?.showLineBar?.value === 'true' ||
                      section?.fields?.showLineBar?.value ? (
                        <div className="seperatorLine" />
                      ) : (
                        ''
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="upload-button-container">
                <Button
                  text={t('apply')}
                  handleButtonClick={
                    isConsentPopupRequired?.value
                      ? () => handleModal()
                      : () => applyJob()
                  }
                  isDisabled={isLoading}
                ></Button>
              </div>
              <ModalComponent showModal={showModal} handleModal={handleNo} size="md">
                <div className="inner-modal-container" key="body">
                  {abstract?.value && (
                    <RichText tag="div" className="subTitleText" field={abstract} />
                  )}
                  <div
                    className="confirm-button-container"
                    role="button"
                    tabIndex={0}
                  >
                    <Button
                      cssClass="yes-button"
                      handleButtonClick={handleYes}
                      text={t('confirm-apply')}
                      isDisabled={isLoading}
                    />
                    <Button
                      cssClass="hyperLink"
                      handleButtonClick={handleNo}
                      text={t('cancel')}
                      isDisabled={isLoading}
                    />
                  </div>{' '}
                </div>
              </ModalComponent>
            </>
          </div>
        </div>
      </div>
      {isLoading ? (
        <CustomOverlayLoader
          showSpinner
          customLoaderMsg={t('submitting-details')}
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#fff',
          }}
          customTextStyle={{ color: '#fff' }}
          customStyle={{
            backgroundColor: 'rgb(0, 0, 0, 0.5)',
            background: 'rgba(0, 0, 0, 0.5)',
            opacity: '1',
          }}
        />
      ) : (
        ''
      )}
    </section>
  );
};

JobApply.propTypes = {
  fields: PropTypes.shape({}).isRequired,
};

JobApply.defaultProps = {
  fields: {},
};

const ConnectedComponent = withSitecoreContext()(JobApply);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
