export const PROFILE_INFO = {
  SAVE: 'SAVE_PROFILE_INFO',
  SAVE_NIE: 'SAVE_NIE_INFO',
  CLEAR: 'CLEAR_PROFILE_INFO',
  ERROR: 'ERROR_PROFILE_INFO',
};

export const FORM_DATA = {
  SAVE: 'SAVE_FORM_DATA',
  CLEAR: 'CLEAR_FORM_DATA',
};

export const SAVE_UPDATED_ZIP = {
  SAVE: 'SAVE UPDATED ZIP CODE',
  CLEAR: 'CLEAR UPDATED ZIP CODE',
};

export const PROFILE_INFO_FORM = {
  SAVE: 'SAVE_PROFILE_INFO_FORM',
  CLEAR: 'CLEAR_PROFILE_INFO_FORM',
  SAVE_COUNT_IF_VALID: 'SAVE_PROFILE_INFO_FORM_TRIGGER_COUNT_IF_FORM_VALID',
  SAVE_COUNT: 'SAVE_PROFILE_INFO_FORM_TRIGGER_COUNT',
  CLEAR_COUNT: 'CLEAR_PROFILE_INFO_FORM_TRIGGER_COUNT',
};

export const BUILD_PROFILE_FORM = {
  SAVE: 'SAVE_BUILD_PROFILE_FORM',
  CLEAR: 'CLEAR_BUILD_PROFILE_FORM',
  PERSONAL_SAVE: 'PERSONAL_SAVE',
  EXP_SAVE: 'EXP_SAVE',
  EDU_SAVE: 'EDU_SAVE',
  SKILL_SAVE: 'SKILL_SAVE',
  CERT_SAVE: 'CERT_SAVE',
  RESUME_SAVE: 'RESUME_SAVE',
  COVER_LETTER_SAVE: 'COVER_LETTER_SAVE',
  RESUME_LENGTH: 'RESUME_LENGTH',
};

export const PUBLIC_POSITION = {
  SAVE: 'SAVE_PUBLIC_POSITION',
  CLEAR: 'CLEAR_PUBLIC_POSITION',
};
export const CANDIDATE_ID = {
  SAVE: 'SAVE_CANDIDATE_ID',
  CLEAR: 'CLEAR_CANDIDATE_ID',
};
export const SCREENER_QUESTIONS = {
  SAVE: 'SAVE_SCREENER_QUESTIONS',
  CLEAR: 'CLEAR_SCREENER_QUESTIONS',
};

export const JOB_DETAILS = {
  SAVE: 'SAVE_JOB_DETAILS',
  CLEAR: 'CLEAR_JOB_DETAILS',
  PAGEREDIRECT: 'IS_PAGE_REDIRECTED',
};

export const PROFILE_EMPTY_FIELDS = {
  SAVE: 'SAVE_PROFILE_EMPTY_FIELDS',
  CLEAR: 'CLEAR_PROFILE_EMPTY_FIELDS',
};

export const JOBSEARCHINFO = {
  SAVE: 'SAVE_JOB_SEARCH',
  CLEAR: 'CLEAR_JOB_SEARCH',
  SAVE_LOCATION: 'SAVE_LOCATION_INFO',
};

export const LOGIN = {
  INITIALIZING: 'INITIALIZING',
  INITIALIZED: 'INITIALIZED',
  ISREDIRECT: 'ISREDIRECT',
  GETIDTOKENSUCCESS: 'GETIDTOKENSUCCESS',
  LOGINSUCCESS: 'LOGINSUCCESS',
  LOGINERROR: 'LOGINERROR',
  GETIDTOKENERROR: 'GETIDTOKENERROR',
  LOGOUTSUCCESS: 'LOGOUTSUCCESS',
  AUTHSTATECHANGED: 'AUTHSTATECHANGED',
  PASSWORDRESET: 'PASSWORDRESET',
  EDITPROFILE: 'EDITPROFILE',
  LOCATION: 'LOCATION',
  UPDATESESSION: 'UPDATESESSION',
  PERSISTEDSTATE: 'PERSISTEDSTATE',
  UPDATEPHONE: 'UPDATEPHONE',
  CANDIDATEID: 'CANDIDATEID',
  ISPOPUPDETAILSTRIGGERED: 'ISPOPUPDETAILSTRIGGERED',
};

export const ADB2C = {
  SHOWLOGIN: 'SHOWLOGIN',
  SETCONFIG: 'SETCONFIG',
  SETAUTHFUNC: 'SETAUTHFUNC',
};

export const SAVED_JOBS = {
  ADD: 'ADD_JOB',
  REMOVE: 'REMOVE_JOB',
  CLEAR: 'CLEAR_JOBS',
};

export const PROFILE_PIC = {
  GET: 'GET_PRO_PIC',
};

export const UPLOAD_CONFIG = {
  GET: 'UPLOAD_CONFIG',
};

export const RESUME = {
  GET: 'GET_RESUME',
  CLEAR: 'CLEAR_RESUME',
  CLEARALL: 'CLEARALL_RESUME',
};

export const COVER_LETTER = {
  GET: 'GET_COVER_LETTER',
  CLEAR: 'CLEAR_COVER_LETTER',
  CLEARALL: 'CLEARALL_COVER_LETTER',
};

export const IS_FORM_SUBMITTING = {
  SET: 'IS_FORM_SUBMITTING',
};

export const PARSE = {
  SET: 'PARSE_SET',
};

export const HUBSPOT = {
  SHOW: 'SHOW_FORM',
};

export const CHATBOT = {
  SHOW: 'SHOW_CHATBOT',
};

export const LANGUAGE = {
  UPDATE: 'UPDATE_LANG',
};

export const JOB_ALERTS = {
  CLEAR: 'CLEAR_ALERTS',
};

export const MAP = {
  INIT: 'INIT',
};
export const UPLOAD_FIELDS = {
  SAVE: 'SAVE',
};

export const JOBAPPLY_FORM_DATA = {
  SAVE: 'SAVE_JOBAPPLY_FORM_DATA',
  SAVE_VALID_FORM_NAME: 'SAVE_JOBAPPLY_VALID_FORM_NAME',
  CLEAR: 'CLEAR_JOBAPPLY_FORM_DATA',
  ERROR: 'ERROR_JOBAPPLY_FORM_DATA',
  SAVE_TRIGGERED: 'SAVE_JOBAPPLY_FORM_TRIGGER_DATA',
  CLEAR_TRIGGERED: 'CLEAR_JOBAPPLY_FORM_TRIGGER_DATA',
};

export const JOBAPPLY_FORM_STATE = {
  SAVE: 'SAVE_JOBAPPLY_FORM_STATE',
  CLEAR: 'CLEAR_JOBAPPLY_FORM_STATE',
  ERROR: 'ERROR_JOBAPPLY_FORM_STATE',
};

export const SEO_TYPES = {
  SAVE: 'SEO_SAVE_JOB_RESULTS',
  CLEAR: 'SEO_CLEAR_JOB_RESULTS',
};
export const REMOVE_FOCUS = {
  FOCUS: 'REMOVE_FOCUS',
};

export const SORT_JOBS = {
  SORT: 'SORT_JOBS_BY',
};

export const BANNER_LOADED = {
  LOADED: 'BANNER_LOADED',
};
