/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import set from 'lodash/set';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Controller } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { RichText, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import Modal from '../../../Modal';
import DropBox from './Drive/DropBox';
import GoogleDrive from './Drive/GoogleDrive';
import NormalUpload from './Drive/NormalUpload';
import OneDriveComponent from './Drive/OneDriveComponent';
import FileList from './FileList';
import {
  dataURLtoFile,
  base64ArrayBuffer,
  getImg,
  isObjNotEmpty,
} from '../../../../../utils/helperUtils';
import { API } from '../../../../../constants';
import { documentTypeMock } from '../mockData';
import TooltipComponent from '../../../Tooltip';
import Button from '../../../globals/buttons/Button';
import { dataFetcher } from '../../../../../dataFetcher';
import { fileTypesAllowed } from '../../../../../utils/enums';
import { dropDownFormat } from '../../DynamicForm/helper/dataFactory';
import { getValidationDatabyType } from '../../DynamicForm/helper/filter';
import { attachementDownloadApi } from '../../../../../services/apiServices/candidateService';
import './upload-block.scss';

const { ValueContainer, Placeholder } = components;
/**
 * @description - Value Container
 * @param {*} param0
 */
const NewValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder
        {...props}
        isFocused={props.selectProps.menuIsOpen || props.hasValue}
      >
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? (
          <div className="dummy-input-wrapper">{child}</div>
        ) : null
      )}
    </ValueContainer>
  );
};

const StyleObject = (error) => {
  return {
    menu: (styles) => ({ ...styles, zIndex: 999 }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      zIndex: 999999,
    }),
    menuList: (provided, state) => ({
      ...provided,
      overflowX: 'hidden',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      color: 'rgba(103, 105, 111, 0.5)',
      top:
        state.isFocused || state.isSelected || state.selectProps.inputValue
          ? 15
          : '50%',
      transition: 'top 0.1s, font-size 0.1s',
      whiteSpace: 'nowrap',
      fontSize:
        state.isFocused || state.isSelected || state.selectProps.inputValue
          ? 12
          : 16,
    }),
    control: (base) => ({
      ...base,
      height: 60,
      minHeight: 60,
      ...(error
        ? {
            border: '1px solid  #9d323d',
            '&:hover': { borderColor: '#9d323d' },
            boxShadow: 'none',
          }
        : { boxShadow: 'none' }),
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 49,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      top: 35,
    }),
  };
};

/**
 * @description - File upload Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const UploadBlockDocument = (props) => {
  const {
    label,
    secondaryLabel,
    customStyle,
    optionsData,
    handleUpload,
    handleChange,
    name,
    value,
    t,
    handleDelete,
    config,
    dataValidations,
    requiredValidation,
    setValue,
    customError,
    className,
    control,
    limit,
    isApi,
    apiEndPoint,
    hasApiCollection,
    downloadApiEndPoint,
    cssClass,
    toolTipText,
    clearErrors,
    validateOnLoad,
    fileTypes,
    readOnly,
    id,
    hintText,
    apiData,
    getValues,
    reset,
    formState,
    contentLoading,
    trigger,
    setError,
    register,
  } = props;
  const uploadFieldName = `${name}_upload`;
  const docTypeFieldName = 'doc_type_upload';
  const hiddenUploadFieldName = 'my_documents_hidden';
  const fieldError = get(customError, hiddenUploadFieldName);
  const [file, setFile] = useState([]);
  const [src, setSrc] = useState('#');
  const [options, setOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [removeFileIndex, setFileIndex] = useState('');
  const [confirmDownload, setConfirmDownload] = useState(false);
  const [downloadIndex, setDownloadIndex] = useState(0);
  const [dropDownData, setDropDownData] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [uploadClick, setUploadClick] = useState(false);
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');

  const validFileTypes = fileTypes
    ? fileTypes.toUpperCase().split(',')
    : fileTypesAllowed;
  const normal = useRef();
  const dbox = useRef();
  const gDrive = useRef();
  const oneD = useRef();
  let param = validateOnLoad ? { shouldValidate: true } : {};

  useEffect(() => {
    contentLoading(loading);
  }, [loading]);

  /**
   * @description - Option component
   * @param {*} icon
   * @param {*} text
   */
  const optionComponent = (icon, text) => (
    <div className="upload-container">
      <div
        style={{
          backgroundImage: `url(${mediaApi.updateImageUrl(getImg(icon))})`,
        }}
        className="upload-icon"
      />
      {text}
    </div>
  );

  useEffect(() => {
    const temp = [];
    optionsData.map((item) => {
      temp.push({
        value: item?.fields?.key?.value || 'upload',
        label: optionComponent(item.fields.img, item.fields.label.value),
        data: item.fields,
      });
    });
    setOptions(temp);
  }, [optionsData]);

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setFile(value);
    }
  }, [value]);

  useEffect(() => {
    setValue(hiddenUploadFieldName, file.length > 0 ? 'upload' : null, param);
  }, [file]);

  useEffect(() => {
    if (isApi) {
      setLoading(true);
      if (!hasApiCollection) {
        dataFetcher(`${API.HOST}${apiEndPoint}`).then(
          (response) => {
            setLoading(false);
            setDropDownData(
              dropDownFormat(response.data, 'DropdownAPI', 0, 0, '', '')
            );
          },
          (error) => {
            setLoading(false);
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    if (apiData) {
      setLoading(false);
      setDropDownData(dropDownFormat(apiData, getType(), 0, 0, '', ''));
    }
  }, [apiData]);

  /**
   * @description On file select
   */
  const onSuccess = (val) => {
    if (val !== null) {
      const extn = val?.name.split('.').pop();
      const fileSize = val?.size || val?.bytes || val?.sizeBytes;
      if (checkValidFormat(extn)) {
        if (checkValidSize(fileSize)) {
          setValue(
            uploadFieldName,
            { value: 'upload', label: 'Upload File' },
            param
          );
          clearErrors(uploadFieldName);
          setSelectedFile(val);
          setSrc(URL.createObjectURL(val));
          setUploadLoading(false);
        } else {
          setValue(uploadFieldName, null, param);
          setSelectedFile({});
          toast.error(t('the-document-size-should-not-exceed-3mb'));
          setUploadLoading(false);
        }
      } else {
        setValue(uploadFieldName, null, param);
        setSelectedFile({});
        toast.error(t('please-upload-document-with-the-following-file-type'));
        setUploadLoading(false);
      }
    }
  };

  const getFileTypeLengthByDocType = (file, type) => {
    let result = file.filter((item) => item?.type === type).length;
    return result;
  };

  const onSubmit = () => {
    let uploadFieldVal = getValues(uploadFieldName);
    let docTypeFieldVal = getValues(docTypeFieldName);
    if (uploadFieldVal?.value && docTypeFieldVal?.value) {
      set(formState.touched, uploadFieldName, true);
      setUploadClick(false);
      let typeCount = getFileTypeLengthByDocType(file, docTypeFieldVal?.value);
      if (typeCount > 4) {
        toast.error(t('each-upload-type-not-greater-than-5'));
      } else {
        const fileArray = [
          {
            name: selectedFile.name,
            type: docTypeFieldVal.value,
            label: docTypeFieldVal.label,
            file: selectedFile,
          },
          ...file,
        ];
        setFile(fileArray);
        handleUpload(fileArray, uploadFieldName);
      }
      setSelectedFile({});
      setValue(uploadFieldName, null);
      setValue(docTypeFieldName, null);
      setUploadLoading(false);
    } else {
      setUploadClick(true);
      trigger(hiddenUploadFieldName);
    }
  };

  /**
   * @description Loader
   */
  const setLoader = () => {
    setUploadLoading(true);
  };

  /**
   * @description To check valid file types
   * @param {*} extn file extension
   * @returns {boolean} Boolean
   */
  const checkValidFormat = (extn) => validFileTypes.includes(extn?.toUpperCase());

  /**
   * @description To check valid file size
   * @param {*} extn file size
   * @returns {boolean} Boolean
   */

  const checkValidSize = (fileSize) => fileSize < 3000000;

  /**
   * @description - On dropdown select
   * @param {*} value
   */
  const onSelect = (value) => {
    if (value) {
      switch (value.value) {
        case 'upload':
          normal.current.trigger();
          break;
        case 'dbox':
          dbox.current.trigger();
          break;
        case 'onedrive':
          oneD.current.trigger();
          break;
        case 'googledrive':
          gDrive.current.trigger();
          break;
        default:
          return null;
      }
    }
  };

  /**
   * @description handle success file upload from dropbox
   * @param {*} url path of file
   * @param {*} name name of file
   * @returns {undefined} nothing
   */
  const handleSuccess = (url, name) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function () {
      const type =
        file?.name?.split('.').pop() === 'pdf'
          ? 'application/pdf'
          : 'application/msword';
      var base64 = `data:${type};base64,` + base64ArrayBuffer(xhr.response);
      const temp = dataURLtoFile(base64, name);
      onSuccess(temp);
    };
    xhr.send();
  };

  /**
   * @description - dropbox
   */
  const openFile = () => {
    var options = {
      clientId: config?.ONEDRIVE?.apiKey,
      action: 'download',
      multiSelect: true,
      success: function (files) {
        setLoader();
        const url = files.value[0]['@microsoft.graph.downloadUrl'];
        handleSuccess(url, files?.value[0].name);
      },
      error: function (e) {
        console.log('Error occurred while picking a file: ' + e, e);
      },
      advanced: {
        redirectUri: history?.location?.pathname,
      },
    };
    OneDrive.open(options);
  };

  const downloadSuccess = (fileName) => {
    toast.success(`${t('download-success-message')} ${fileName}`, {
      delay: 2000,
    });
  };

  const errorCallBack = (fileName) => {
    toast.error(`${t('download-error-message')} ${fileName}`, {
      delay: 2000,
    });
  };

  const handleDownload = () => {
    toast.success(`${t('downloading')} ${file[downloadIndex]?.name}`, {
      autoClose: 3000,
    });
    var ua = navigator?.userAgent;
    var msie = ua?.indexOf('MSIE');
    if (file[downloadIndex]?.id) {
      attachementDownloadApi(
        file[downloadIndex]?.name,
        `${downloadApiEndPoint}&fileID=${file[downloadIndex]?.id}`,
        downloadSuccess,
        errorCallBack
      );
    } else if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      var blob = new Blob([file[downloadIndex]], {
        type: 'text/plain;charset=utf-8;',
      });
      if (navigator.msSaveBlob) {
        handleDownloadConfirm();
        downloadSuccess(file[downloadIndex]?.name);
        return navigator.msSaveBlob(blob, file[downloadIndex].name);
      }
    } else {
      var link = document?.createElement('a');
      link.download = file[downloadIndex]?.name;
      link.href = src;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link = null;
      downloadSuccess(file[downloadIndex]?.name);
    }
    handleDownloadConfirm();
  };

  const handleNoDownload = () => {
    handleDownloadConfirm();
  };

  /**
   * @description to show file remove alert dialog
   * @returns {undefined} - nothing
   */
  const handleModal = () => {
    setShowModal(!showModal);
  };

  /**
   * @description to show download alert dialog
   * @returns {undefined} - nothing
   */
  const handleDownloadConfirm = () => {
    setConfirmDownload(!confirmDownload);
  };

  /**
   * @description - Remove file
   * @returns {undefined} - nothing
   */
  const handleYes = () => {
    if (file && file.length > 0 && file[removeFileIndex]?.id) {
      handleDelete(file[removeFileIndex].id, uploadFieldName, removeFileIndex);
    } else {
      handleDelete(null, uploadFieldName, removeFileIndex);
    }
    setFileIndex('');
    const temp = file;
    delete temp[removeFileIndex];
    const newtemp = temp.filter((item) => item);
    setFile(newtemp);
    setSrc('#');
    handleModal();
  };

  /**
   * @description to show job alert dialog
   * @returns {undefined} - nothing
   */
  const handleNo = () => {
    handleModal();
    setFileIndex('');
  };

  const removeFile = (index) => {
    handleModal();
    setFileIndex(index);
  };

  const downloadFile = (index) => {
    handleDownloadConfirm();
    setDownloadIndex(index);
  };

  const clearFile = () => {
    setValue(uploadFieldName, null, param);
    setSelectedFile({});
  };

  const isError = (formName) => {
    return (
      (isObjNotEmpty(fieldError) > 0 && !getValues(formName)) ||
      (uploadClick && !getValues(formName))
    );
  };

  return (
    <>
      <input
        type="hidden"
        name={hiddenUploadFieldName}
        ref={
          register
            ? register({
                required: requiredData?.fields?.value?.value || false,
              })
            : () => {}
        }
      />
      <div
        className={`select-wrapper form-block complex ${cssClass}`}
        style={customStyle}
        role="combobox"
        aria-label={label}
      >
        <div className="uploadSelectContainer">
          <div
            className={classNames(
              className,
              !selectedFile?.name ? '' : 'hide-field'
            )}
          >
            <Controller
              className={className}
              name={uploadFieldName}
              control={control}
              rules={{
                required: false,
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <Select
                  onChange={(selected) => {
                    onChange(selected);
                    onSelect(selected);
                  }}
                  onBlur={(selected) => {
                    onBlur(selected);
                    if (formState?.touched?.[uploadFieldName])
                      delete formState.touched[uploadFieldName];
                  }}
                  options={options}
                  value={value || null}
                  placeholder={
                    <div className="upload-placeholder">
                      {label}
                      {toolTipText && (
                        <TooltipComponent text={toolTipText}>
                          <div className="info-icon">i</div>
                        </TooltipComponent>
                      )}
                    </div>
                  }
                  isMulti={false}
                  isClearable={false}
                  autocomplete={false}
                  isSearchable={false}
                  isDisabled={
                    limit && file?.length === parseInt(limit) ? true : false
                  }
                  styles={StyleObject(isError(uploadFieldName))}
                  components={{ ValueContainer: NewValueContainer }}
                />
              )}
            />
            {uploadLoading && (
              <span
                className="spinner-wrapper-upload spinner-border spinner-border-sm"
                role="status"
              ></span>
            )}
            {isError(uploadFieldName) && (
              <div className="error-msg" aria-label="this field is required">
                {fieldError?.type === 'required'
                  ? requiredData?.fields?.message?.value
                    ? requiredData.fields.message.value.replace(
                        '{0}',
                        label.replace('*', '')
                      )
                    : t('mandatory-field-message')
                  : t('mandatory-field-message')}
              </div>
            )}
            {hintText && (
              <RichText field={{ value: hintText }} className="hintText" />
            )}
          </div>
          {selectedFile?.name && (
            <div className="file-select-container">
              <div className="label-container">
                {' '}
                <div className="upload-placeholder">
                  {label}
                  {toolTipText && (
                    <TooltipComponent text={toolTipText}>
                      <div className="info-icon">i</div>
                    </TooltipComponent>
                  )}
                </div>
              </div>
              <div className="file-container">
                <div className="input-wrapper">{selectedFile?.name}</div>
                <div className="close-icon" onClick={() => clearFile()} />
              </div>
            </div>
          )}
          <div className="doc-type-select-container">
            <Controller
              name={docTypeFieldName}
              control={control}
              rules={{
                required: false,
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <Select
                  onChange={(selected) => {
                    onChange(selected);
                  }}
                  value={value || null}
                  onBlur={(selected) => {
                    onBlur(selected);
                    if (formState?.touched?.[docTypeFieldName])
                      delete formState.touched[docTypeFieldName];
                  }}
                  options={dropDownData}
                  defaultValue={value}
                  placeholder={secondaryLabel}
                  isMulti={false}
                  autocomplete={true}
                  isSearchable={false}
                  isClearable={true}
                  isLoading={loading}
                  isDisabled={
                    limit && file?.length === parseInt(limit) ? true : false
                  }
                  styles={StyleObject(isError(docTypeFieldName))}
                  components={{ ValueContainer: NewValueContainer }}
                />
              )}
            />
            {isError(docTypeFieldName) && (
              <div className="error-msg" aria-label="this field is required">
                {fieldError?.type === 'required'
                  ? requiredData?.fields?.message?.value
                    ? requiredData.fields.message.value.replace(
                        '{0}',
                        secondaryLabel.replace('*', '')
                      )
                    : t('mandatory-field-message')
                  : t('mandatory-field-message')}
              </div>
            )}
          </div>
        </div>
        <div className="upload-btn">
          <Button cssClass="blue" text={t('upload')} handleButtonClick={onSubmit} />
        </div>
      </div>
      <div style={{ display: 'none' }} className="upload-config-cls">
        {' '}
        <NormalUpload
          onSuccess={onSuccess}
          ref={normal}
          config={config}
          id={id}
          name={name}
        />
        <DropBox
          onSuccess={onSuccess}
          ref={dbox}
          config={config}
          setLoader={setLoader}
        />
        <GoogleDrive
          onSuccess={onSuccess}
          ref={gDrive}
          config={config}
          setLoader={setLoader}
        />
        <OneDriveComponent openFile={openFile} ref={oneD} />
      </div>
      <Modal showModal={showModal} handleModal={handleModal} size="md">
        <div className="inner-modal-container" key="body">
          {t('would-you-like-to-delete-the-attached-document')}
          <div className="confirm-button-container" role="button" tabIndex={0}>
            <Button
              cssClass="yes-button"
              handleButtonClick={handleYes}
              text={t('yes')}
            />
            <Button handleButtonClick={handleNo} text={t('no')} />
          </div>{' '}
        </div>
      </Modal>
      <Modal
        showModal={confirmDownload}
        handleModal={handleDownloadConfirm}
        size="md"
      >
        <div className="inner-modal-container" key="body">
          {t('download-confirmation')}
          <div className="confirm-button-container" role="button" tabIndex={0}>
            <Button
              cssClass="yes-button"
              handleButtonClick={handleDownload}
              text={t('yes')}
            />
            <Button handleButtonClick={handleNoDownload} text={t('no')} />
          </div>{' '}
        </div>
      </Modal>
      {file?.length > 0 && (
        <FileList
          data={file}
          removeFile={removeFile}
          t={t}
          downloadFile={downloadFile}
          hasDocType={true}
          viewFile={viewFile}
        />
      )}
    </>
  );
};

UploadBlockDocument.propTypes = {
  label: PropTypes.string.isRequired,
  customStyle: PropTypes.shape({}).isRequired,
  t: PropTypes.func,
  readOnly: PropTypes.bool,
  hintText: PropTypes.string,
};

UploadBlockDocument.defaultProps = {
  label: 'Upload Resume',
  customStyle: {},
  readOnly: false,
  hintText: '',
  trigger: () => {},
};

const mapStateToProps = (state) => {
  return {
    config: state.uploadConfigReducer.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(UploadBlockDocument));
