/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { authstates } from '../../../utils/enums';
import Loader from '../ErrorBoundary/Loader';
import { isNorthAmericanCountry } from '../../../utils/helperUtils';
import RedirectToLoginUS from './RedirectToLoginUS';
/**
 * @description - PrivateRoute component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for PrivateRoute.
 */
const PrivateRoute = ({ children, redirectUrl, countryName }) => {
  const loginReducer = useSelector((state) => state.loginReducer);

  useEffect(() => {
    /**
     * @description - gets local state
     * @returns {Object} - state or undefined.
     */
    const getStorageItem = () => {
      const serializedState = sessionStorage
        ? sessionStorage.getItem('usersession')
        : null;
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    };

    (() => {
      const localState = getStorageItem();

      if (
        (loginReducer.initialized &&
          !isNorthAmericanCountry(countryName) &&
          loginReducer.state !== authstates.AUTHENTICATED) ||
        (localState !== undefined &&
          !isNorthAmericanCountry(countryName) &&
          localState.state !== authstates.AUTHENTICATED)
      )
        window.location.href = redirectUrl;
    })();
  }, [loginReducer.state, loginReducer.initialized]);

  return (
    <>
      {loginReducer && loginReducer.state === authstates.AUTHENTICATED ? (
        children
      ) : loginReducer &&
        loginReducer.state !== authstates.AUTHENTICATED &&
        isNorthAmericanCountry(countryName) ? (
        <RedirectToLoginUS />
      ) : (
        <Loader
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#C25700',
          }}
        />
      )}
    </>
  );
};

PrivateRoute.defaultProps = {
  redirectUrl: '/',
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  redirectUrl: PropTypes.string,
};

export default PrivateRoute;
