/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './sidebarcards.scss';
import {
  hasEngineeringSpecializationAndIsInUS,
  isNorthAmericanCountry,
} from '../../../utils/helperUtils';

/**
 * @description to show recruter card in job details
 * @returns {node} html
 */
const SidebarCardRecruiter = ({ t, fields, sitecoreContext }) => {
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase();
  const country_code = sitecoreContext?.Country?.code;
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  const specializations = fields?.specialization;

  const isSpecialisation = hasEngineeringSpecializationAndIsInUS(
    specializations,
    countryName
  );

  return (
    <div className="recruiter-sidebar">
      {isSpecialisation && (
        <h2 className={`sidebar-title ${brandName}`}>{t('recruiter')}</h2>
      )}

      {Array.isArray(fields?.items) &&
        fields.items.map((recruiter, index) => {
          return (
            <div
              className={`sidebar-body ${country_code === 'NL' ? '' : 'final'}`}
              key={index}
              style={index === 0 ? { paddingTop: 0 } : {}}
            >
              {isSpecialisation && (
                <>
                  {recruiter?.recImage && (
                    <div className="recruiter-image">
                      <img src={recruiter?.recImage} alt="recruiter" />
                    </div>
                  )}
                  {recruiter?.recruiterName && (
                    <div className="recruiter-name">{recruiter.recruiterName}</div>
                  )}
                  <div>
                    {recruiter?.recruiterPhoneNumber?.trim(' ""') && (
                      <a
                        href={`tel: ${recruiter?.recruiterPhoneNumber}`}
                        className={`social-item phone`}
                        aria-label="Phone number opens in a new window/tab"
                      >
                        <div className="icon">
                          <div className="icon-phone"></div>
                        </div>
                        <div className="text phone">
                          {recruiter?.recruiterPhoneNumber}
                        </div>
                      </a>
                    )}

                    {recruiter?.recEmailId && (
                      <a
                        href={`mailto: ${recruiter?.recEmailId}`}
                        className={`social-item`}
                        aria-label="Email opens in a new window/tab"
                      >
                        <div className="icon">
                          <div className="icon-mail"></div>
                        </div>
                        <div className="text">{recruiter?.recEmailId}</div>
                      </a>
                    )}

                    {recruiter?.linkedinId &&
                    country_code !== 'DE' && ( // fix for 24712 - Manpower Germany no linkedin Id needed.
                        <a
                          href={recruiter?.linkedinId}
                          className={`social-item`}
                          aria-label="LinkedIn opens in a new window/tab"
                        >
                          <div className="icon">
                            <div className="icon-ln"></div>
                          </div>
                          <div className="text">{recruiter?.linkedinId}</div>
                        </a>
                      )}
                  </div>
                </>
              )}
              {isNorthAmericanCountry(countryName) &&
              recruiter?.recBranchAddress1 &&
              recruiter?.recBranchName ? (
                !isSpecialisation ? (
                  <div>
                    <div className="sidebar-title manpower">
                      {t('branch-address')}
                    </div>
                    <div className="address-bar">
                      {recruiter?.recBranchName && (
                        <div className="text">{recruiter?.recBranchName}</div>
                      )}
                      {recruiter?.recBranchAddress1 && (
                        <div className="text">{recruiter?.recBranchAddress1}</div>
                      )}
                      {recruiter?.recBranchAddress2 && (
                        <div className="text">{recruiter?.recBranchAddress2}</div>
                      )}
                      <div className="text">
                        {recruiter?.recBranchCity && (
                          <span>{recruiter?.recBranchCity},</span>
                        )}
                        {recruiter?.recBranchState && (
                          <span> {recruiter?.recBranchState}</span>
                        )}
                        {recruiter?.recBranchZip && (
                          <span> {recruiter?.recBranchZip}</span>
                        )}
                      </div>
                    </div>
                    {recruiter?.recBranchPhone?.trim(' ""') && (
                      <a
                        href={`tel: ${recruiter?.recBranchPhone}`}
                        className={`social-item phone`}
                        aria-label="Phone number opens in a new window/tab"
                      >
                        <div className="icon">
                          <div className="icon-phone"></div>
                        </div>
                        <div className="text">{recruiter?.recBranchPhone}</div>
                      </a>
                    )}
                  </div>
                ) : null
              ) : (
                (recruiter?.recBranchAddress || recruiter?.recBranchLocation) &&
                !isSpecialisation && (
                  <div>
                    <div
                      className="sidebar-title"
                      style={{ borderTopColor: '#6f8f82' }}
                    >
                      {t('branch-address')}
                    </div>
                    {recruiter?.recBranchAddress ? (
                      <div className="text">{recruiter?.recBranchAddress}</div>
                    ) : null}
                    {recruiter?.recBranchLocation ? (
                      <div className="text">{recruiter?.recBranchLocation}</div>
                    ) : null}
                    {recruiter?.recBranchPhone?.trim(' ""') && (
                      <a
                        href={`tel: ${recruiter?.recBranchPhone}`}
                        className={`social-item phone`}
                        aria-label="Phone number opens in a new window/tab"
                      >
                        <div className="icon">
                          <div className="icon-phone"></div>
                        </div>
                        <div className="text">{recruiter?.recBranchPhone}</div>
                      </a>
                    )}
                    <div style={{ paddingBottom: '10px' }}> </div>
                  </div>
                )
              )}
            </div>
          );
        })}
    </div>
  );
};

SidebarCardRecruiter.defaultProps = {
  fields: {
    items: [],
  },
  t: () => {},
  sitecoreContext: {},
};

SidebarCardRecruiter.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  t: PropTypes.func,
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(SidebarCardRecruiter);
