import React from 'react';
import PropTypes from 'prop-types';
import CardInfo from '../Cards/CardInfo';

import { withTranslation } from 'react-i18next';
import {
  checkArray,
  isNorthAmericanCountry,
  showString,
} from '../../../utils/helperUtils';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './cardblock.scss';

/**
 * @description-  CardBlockInfo standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const CardBlockInfo = (props) => {
  const { fields, params, t, sitecoreContext } = props;
  const { colorMode = '', linkColor = '', colorCode = '' } = params;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';

  const link_color = linkColor ? linkColor : 'BLUE';
  const color_Mode = showString(colorMode).toLowerCase();
  let inputStyle = {
    background: '',
  };
  inputStyle = showString(colorCode) ? { background: colorCode } : inputStyle;
  const gridValue = checkArray(fields.items).length > 3 ? 'col-lg-3' : 'col-lg-4';

  return (
    <div
      className={`card-block ${color_Mode} ${
        isNorthAmericanCountry(countryName) && color_Mode === ''
          ? 'card-padding-usca'
          : ''
      }`}
      style={inputStyle}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <h2
              className={`title solution ${
                isNorthAmericanCountry(countryName) && color_Mode === ''
                  ? 'card-textalign-usca'
                  : ''
              }`}
            >
              {isNorthAmericanCountry(countryName) ? (
                fields?.url?.value?.href ? (
                  <a className="title-url-header" href={fields?.url?.value?.href}>
                    {fields?.title?.value}{' '}
                  </a>
                ) : (
                  fields?.title?.value
                )
              ) : (
                fields?.title?.value
              )}
            </h2>
          </div>
        </div>
        <div
          className={`row justify-content-md-center ${
            isNorthAmericanCountry(countryName) && color_Mode === ''
              ? 'card-margin-usca'
              : ''
          }`}
        >
          {checkArray(fields.items).map((item) => (
            <div
              key={item.name}
              className={`cardInfoContainer ${gridValue} col-md-6`}
            >
              {brandName === 'manpower' ? (
                <CardInfo
                  title={item.fields.title}
                  text={item.fields.text}
                  icon={item.fields.image}
                  ctaUrl={item?.fields?.url}
                  ctaText={t('services-read-more')}
                  mode={color_Mode}
                  brandName={brandName}
                  countryName={countryName}
                />
              ) : (
                <CardInfo
                  title={item.fields.title}
                  text={item.fields.text}
                  icon={item.fields.image}
                  ctaUrl={item?.fields?.url}
                  ctaText={t('services-read-more')}
                  readMoreColour={link_color}
                  mode={'dark'}
                  brandName={brandName}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CardBlockInfo.defaultProps = {
  fields: { title: {}, items: [] },
  params: {
    colorMode: { value: 'manpower_light' },
    linkColor: { value: 'blue' },
    colorCode: '',
  },
  sitecoreContext: {
    Country: {
      brandName: '',
    },
  },
};

CardBlockInfo.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        icon: PropTypes.string,
        altText: PropTypes.string,
        ctaText: PropTypes.string,
        ctaUrl: PropTypes.string,
        colorMode: PropTypes.oneOf(['dark', 'light']),
      })
    ),
  }),
  params: PropTypes.shape({
    colorMode: {
      value: PropTypes.oneOf(['dark', 'light']),
    },
    linkColor: {
      value: PropTypes.oneOf(['green', 'blue']),
    },
    colorCode: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({
    Country: PropTypes.shape({
      brandName: PropTypes.string,
    }),
  }).isRequired,
};

export default withSitecoreContext()(withTranslation()(CardBlockInfo));
