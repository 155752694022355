/* eslint-disable */
import React, { useState, useEffect, Fragment, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import CheckboxGroup from '../../globals/forms/CheckboxGroup';
import RadioToggle from '../../globals/forms/RadioToggle';
import TextButton from '../../globals/buttons/TextButton';
import Select from '../../globals/forms/Select';
import { useDispatch } from 'react-redux';
import {
  isObjNotEmpty,
  isDataExists,
  parseFilterData,
  setIdsParamAtLast,
  JSONParser,
  verifySearchJobPath,
  isNullorEmpty,
} from '../../../../utils/helperUtils';
import { SEARCH, DEVICE } from '../../../../constants';

import {
  getFormattedArray,
  getCitiesArray,
  getFilterArray,
  getSelectArray,
  getIndustriesArray,
  splitCitiesArray,
  getLanguageArray,
  getRadiusFilterArray,
} from '../../../../factory/jobSearchFilterFactory';
import { searchFilterLayoutGlobal } from '../../../../utils/enums';
import Loader from '../../ErrorBoundary/Loader';
import SearchAccordion from './SearchAccordion';
import { getSortType } from '../../../../redux/actions';
import { CollapseFilterContext } from '../../../business/Contexts';
import ToggleSwitch from './ToggleSwitch';

/**
 *
 */
const getFormattedvalues = (name, values) => {
  switch (name) {
    // case 'radius':
    // case 'salaryRange':
    // case 'salaryRangeLevels':
    case 'language':
      return values;
    case 'radius':
    case 'industries':
    // case 'jobTypes':
    case 'specialization':
    //  case 'experienceLevel':
    //  case 'education':

    case 'sortOrder':
    case 'jobOfferType':
    // case 'employmentType':
    case 'workingHours':
    case 'contractType':

    case 'managementLevel':
    case 'publicationDates':
    case 'remoteJobs':
      return values ? getFormattedArray([values]) : [];
    case 'showamsjobs':
      return values ? values : false;
    default:
      return getFormattedArray(values || []);
  }
};

/**
 *
 * @param {*} param0
 */
const LocationsGroup = (props) => {
  const { cities, selectedCities, onFilterChange, t, brandName, focusId } = props;
  const [showAll, setShowAll] = useState(false);
  const citiesArray = getCitiesArray(cities || []);
  const jobCities = splitCitiesArray(citiesArray)?.jobCities;
  const noJobCities = splitCitiesArray(citiesArray)?.noJobCities;

  useEffect(() => {
    setShowAll(false);
  }, [cities]);

  return (
    <Fragment>
      <CheckboxGroup
        options={showAll ? citiesArray : jobCities}
        name="cities"
        focusId={focusId}
        cssClass="neighborhoods-group"
        selectHandler={onFilterChange}
        selectedValues={selectedCities}
      />
      {isDataExists(noJobCities) ? (
        <TextButton
          text={showAll ? t('jobs-view-less') : t('jobs-view-all')}
          cssClass={brandName}
          handleTextClick={() => setShowAll(!showAll)}
          ariaExpanded={showAll ? true : false}
        />
      ) : null}
    </Fragment>
  );
};

const FieldInputLocations = ({
  title,
  cities,
  onFilterChange,
  selectedCities,
  focusId,
  brandName,
  t,
}) => {
  return (
    <div className="search__filter search__filter--checklist">
      <LocationsGroup
        cities={cities}
        onFilterChange={onFilterChange}
        selectedCities={selectedCities}
        focusId={focusId}
        brandName={brandName}
        t={t}
      />
    </div>
  );
};

/**
 *
 * @param {*} param0
 */
const FieldInputCheckbox = ({
  title,
  options,
  selectHandler,
  selectedValues,
  inputName,
  focusId,
}) => {
  return (
    <div className="search__filter search__filter--checklist" role="group">
      <fieldset>
        <CheckboxGroup
          focusId={focusId}
          options={options}
          name={inputName}
          selectHandler={selectHandler}
          selectedValues={selectedValues}
        />
      </fieldset>
    </div>
  );
};

const FieldInputRadioType = ({
  title,
  options,
  selectHandler,
  selectedValue,
  notRequired,
  inputName,
  focusId,
}) => {
  return (
    <div className="search__filter search__filter--checklist">
      <RadioToggle
        options={options}
        name={inputName}
        focusId={focusId}
        selectHandler={selectHandler}
        selectedValue={selectedValue}
        notRequired={notRequired}
      />
    </div>
  );
};

/**
 *
 */
const FieldInputSalaryRange = ({
  title,
  text,
  maxSalary,
  minSalary,
  salaryStepper,
  salaryRange,
  setSalaryRange,
  onFilterChange,
  currencySymbol,
  currencyPrefix,
}) => {
  const salarySlider = useRef();

  useEffect(() => {
    if (maxSalary?.value && minSalary?.value) {
      const track = salarySlider?.current?.querySelector(
        '.input-range__track.input-range__track--active'
      );
      const sliderHoldMin = salarySlider?.current?.querySelectorAll(
        '.input-range__slider-container'
      )?.[0];
      const sliderHoldMax = salarySlider?.current?.querySelectorAll(
        '.input-range__slider-container'
      )?.[1];

      const sliderRoundMin = salarySlider?.current?.querySelectorAll(
        'div[role="slider"]'
      )?.[0];
      const sliderRoundMax = salarySlider?.current?.querySelectorAll(
        'div[role="slider"]'
      )?.[1];

      track.style.width = `${
        ((Number(salaryRange?.max) - Number(salaryRange?.min)) /
          (Number(maxSalary?.value) - Number(minSalary?.value) || 1)) *
          100 || 0
      }%`;

      track.style.left = `${
        ((Number(salaryRange?.min) - Number(minSalary?.value)) /
          (Number(maxSalary?.value) - Number(minSalary?.value) || 1)) *
        100
      }%`;

      sliderHoldMin.style.left = `${
        ((Number(salaryRange?.min) - Number(minSalary?.value)) /
          (Number(maxSalary?.value) - Number(minSalary?.value) || 1)) *
        100
      }%`;

      sliderHoldMax.style.left = `${
        ((Number(salaryRange?.max) - Number(minSalary?.value)) /
          (Number(maxSalary?.value) - Number(minSalary?.value) || 1)) *
        100
      }%`;

      sliderRoundMin.ariaValueNow = `${salaryRange?.min}`;
      sliderRoundMax.ariaValueNow = `${salaryRange?.max}`;
    }
  }, []);

  return (
    <div
      className="search__filter search__filter--range-slider range-slider"
      ref={salarySlider}
    >
      <InputRange
        name="salaryRange"
        step={Number(salaryStepper?.value)}
        maxValue={Number(maxSalary?.value)}
        minValue={Number(minSalary?.value)}
        formatLabel={(value) =>
          `${currencySymbol?.value}${value}${currencyPrefix?.value}`
        }
        value={salaryRange}
        onChange={(value) => {
          setSalaryRange(value);
        }}
        onChangeComplete={(value) =>
          onFilterChange({ target: { name: 'salaryRange' } }, value)
        }
      />
    </div>
  );
};

const AmsToggleSwitch = ({
  title,
  selectHandler,
  selectedValue,
  ariaLabel,
  inputName,
}) => {
  return (
    <div className="toggle-switch-wrapper">
      <label className="ams-toggle-label">{title}</label>
      <ToggleSwitch
        inputName={inputName}
        ariaLabel={ariaLabel}
        selectHandler={selectHandler}
        selectedValue={selectedValue}
      />
    </div>
  );
};

/**
 *
 */
const SearchFiltersAccItem = (props) => {
  const {
    setFilterActive,
    t,
    fields,
    location,
    history,
    jobData,
    loading,
    removeFocus,
  } = props;
  const { filters = {} } = jobData;
  const dispatch = useDispatch();
  const { sitecoreContext } = props;
  const url = sitecoreContext?.Country?.jobSearchURL || '';
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';
  const sortby = useContext(CollapseFilterContext) || {};
  const {
    defaultValue,
    defaultSalaryMax,
    defaultSalaryMin,
    sortOption,
    industry,
    distance,
    contract,
    specialization,
    filterLayout,
    remoteJobs,
    publicationDates,
  } = fields;

  const parsedFilterLayout = JSONParser(filterLayout?.value) || {};
  const customFilterLayout = filterLayout?.value
    ? Object.keys(parsedFilterLayout)?.length > 0
      ? parsedFilterLayout
      : searchFilterLayoutGlobal
    : searchFilterLayoutGlobal;

  const params = new URLSearchParams(location?.search);
  const filter = typeof window !== 'undefined' ? parseFilterData() : undefined;
  const filterObj = filter || {};
  const targetInput = useRef(null);
  const focusId = useRef(null);

  const { radius } = filterObj;
  const [rangeValue, setRangeValue] = useState(
    radius || Number(defaultValue?.value)
  );
  const [salaryRange, setSalaryRange] = useState(
    filterObj?.salaryRange || {
      min: Number(defaultSalaryMin?.value),
      max: Number(defaultSalaryMax?.value),
    }
  );
  const [selectedFilters, setSelectedFilters] = useState({});

  /**
   *
   * @param {*} e
   */
  const closeFiler = (e) => {
    if (e && e.keyCode === 13) {
      setFilterActive(false);
      if (typeof window !== 'undefined') {
        document.getElementById('filter-button').focus();
      }
    }
  };

  const onLangChange = (e, selectedVal) => {
    const paramsToSet = new URLSearchParams(location?.search);
    if (params.get('searchKeyword')) {
      paramsToSet.set('searchKeyword', params.get('searchKeyword'));
    }
    if (filterObj.latitude && filterObj.longitude) {
      paramsToSet.set(
        'filters',
        encodeURIComponent(
          JSON.stringify({
            latitude: filterObj.latitude,
            longitude: filterObj.longitude,
            place: filterObj.place,
          })
        )
      );
    }
    window.location = `${selectedVal.redirectURL}`;
  };

  /**
   *
   * @param {*} e
   * @param {*} selectedVal
   */
  const onFilterChange = (e, selectedVal) => {
    if (!isNullorEmpty(e.target.id)) {
      focusId.current = e.target.id;
    } else focusId.current = null;
    const fieldName = e?.target?.name;
    params.delete(fieldName);
    const finalVal = getFormattedvalues(fieldName, selectedVal);
    if (fieldName === 'radius') {
      filterObj['selectedDistance'] = finalVal;
    } else if (fieldName === 'salaryRange') {
      filterObj[fieldName] = finalVal;
    } else if (fieldName === 'showamsjobs') {
      filterObj[fieldName] = finalVal;
    } else if (isDataExists(finalVal)) {
      filterObj[fieldName] = finalVal;
    } else {
      delete filterObj[fieldName];
    }
    filterObj['sf'] = fieldName;
    if (isObjNotEmpty(filterObj)) {
      let ids = {};
      Object.keys(filterObj).forEach((filter) => {
        let temp = [];
        if (filter === 'salaryRange') {
          params.set('salaryMin', filterObj[filter].min);
          params.set('salaryMax', filterObj[filter].max);
        } else if (Array.isArray(filterObj[filter])) {
          ids[filter] = [];
          filterObj[filter].filter((type) => {
            temp.push(type['value']?.replace(/,/g, ''));
            ids[filter].push(type['key']);
          });
          params.set(filter, temp.join(','));
        } else params.set(filter, filterObj[filter]);
      });
      if (isObjNotEmpty(ids))
        params.set('ids', JSON.stringify(ids).split('"').join(''));
    }
    params.set('page', 1);
    setIdsParamAtLast(params);

    if (fieldName === 'employmentType') targetInput.current = e?.target;

    history.replace({
      pathname: location?.pathname,
      search: String(decodeURIComponent(params)),
    });
  };

  useEffect(() => {
    if (
      verifySearchJobPath(location?.pathname, url, sitecoreContext?.language) ||
      sortby?.isCompanyJobSearch
    ) {
      const params = new URLSearchParams(location?.search);

      const compareAndUpdate = (obj1, obj2, excludeKeys) => {
        const result = {};
        Object.keys(obj1).forEach((key) => {
          if (excludeKeys.includes(key)) {
            result[key] = obj1[key];
            return; // Skip to the next iteration
          }

          if (obj2.hasOwnProperty(key)) {
            result[key] = [];

            if (Array.isArray(obj1[key])) {
              obj1[key].forEach((item) => {
                if (Array.isArray(obj2[key])) {
                  const match = obj2[key].find(
                    (obj2Item) => obj2Item.key === item.key
                  );
                  if (match) {
                    result[key].push({
                      key: item.key,
                      value: match.value,
                    });
                  }
                }
              });
            }
          }
        });

        return result;
      };

      const filterObjData = parseFilterData() || {};
      const filterObj = compareAndUpdate(filterObjData, filters, [
        'latitude',
        'longitude',
        'place',
        'page',
        'selectedDistance',
        'sf',
      ]);

      if (
        sitecoreContext?.route?.fields?.company?.fields?.key?.value &&
        sitecoreContext?.route?.fields?.company?.fields?.key?.value !== ''
      ) {
        filterObj.company =
          sitecoreContext?.route?.fields?.company?.fields?.key?.value;
      }

      setSelectedFilters({
        radius: getRadiusFilterArray(filterObj?.selectedDistance || []),
        cities: getFilterArray(filterObj?.cities || []),
        jobTypes: getFilterArray(filterObj?.jobTypes || []),
        scheduleType: getFilterArray(filterObj?.scheduleType || []),
        showDisabilityIcon: getFilterArray(filterObj?.showDisabilityIcon || []),
        sortOrder: getFilterArray(filterObj?.sortOrder || []),
        employmentType: getFilterArray(filterObj?.employmentType || []),
        experienceLevel: getFilterArray(filterObj?.experienceLevel || []),
        education: getFilterArray(filterObj?.education || []),
        jobOfferType: getFilterArray(filterObj?.jobOfferType || []),
        contractType: getFilterArray(filterObj?.contractType || []),
        workingHours: getFilterArray(filterObj?.workingHours || []),
        industries: getFilterArray(filterObj?.industries || []),
        specialization: getFilterArray(filterObj?.specialization || []),
        managementLevel: getFilterArray(filterObj?.managementLevel || []),
        salaryRangeLevels: getFilterArray(filterObj?.salaryRangeLevels || []),
        remoteJobs: getFilterArray(filterObj?.remoteJobs || []),
        publicationDates: getFilterArray(filterObj?.publicationDates || []),
        sectors: getFilterArray(filterObj?.sectors || []),
      });
      setSalaryRange(
        filterObj?.salaryRange || {
          min: Number(defaultSalaryMin?.value),
          max: Number(defaultSalaryMax?.value),
        }
      );

      if (localStorage) {
        localStorage.setItem(
          SEARCH.JOB_SEARCH.SAVED_SEARCH_KEY,
          JSON.stringify({
            searchKeyword: params.get('searchKeyword'),
            filters: filterObj,
          })
        );
        localStorage.setItem(
          SEARCH.JOB_SEARCH.SAVED_SEARCH_PARAMS,
          location?.search
        );
      }

      if (targetInput.current !== null) {
        targetInput.current.focus();
      }
    }
    if (removeFocus) focusId.current = null;
  }, [location]);

  useEffect(() => {
    dispatch(
      getSortType(
        selectedFilters?.sortOrder?.[0] !== undefined &&
          selectedFilters?.sortOrder?.length > 0
          ? selectedFilters?.sortOrder?.[0]?.value
          : sortby?.sortby
          ? sortby?.sortby
          : sortOption?.value || ''
      )
    );
  }, [selectedFilters, sortby]);

  const [isMobView, setIsMobView] = useState(false);
  useEffect(() => {
    const isMobWidth = window.innerWidth <= DEVICE.TABLET.WIDTH;
    // initial rendering.
    if (isMobView !== isMobWidth) {
      setIsMobView(!isMobView);
    }
  }, []);

  const getLayoutClass = (item) => {
    return `${item?.sm ? `col-sm-${item?.sm}` : ''} ${
      item?.md ? `col-md-${item?.md}` : ''
    } ${item?.lg ? `col-lg-${item?.lg}` : ''} ${
      item?.xl ? `col-xl-${item?.xl}` : ''
    }`;
  };

  const getFilterLayout = (item = {}) => {
    switch (item) {
      /*SingleSlider*/
      case 'radius':
        return isDataExists(filters?.locationDistance) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputRadioType
              inputName="radius"
              focusId={focusId}
              options={getRadiusFilterArray(filters?.locationDistance || [])}
              selectHandler={onFilterChange}
              selectedValue={
                selectedFilters?.radius !== undefined &&
                selectedFilters?.radius?.length > 0
                  ? selectedFilters?.radius?.[0]?.value
                  : defaultValue?.value || ''
              }
            />
          </div>
        ) : null;

      /*LocationDropdown*/
      case 'cities':
        return isDataExists(filters?.cities) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputLocations
              // title={t('jobs-neighborhood')}
              cities={filters?.cities}
              onFilterChange={onFilterChange}
              selectedCities={selectedFilters?.cities}
              focusId={focusId}
              brandName={brandName}
              t={t}
            />
          </div>
        ) : null;

      /*Dropdown*/
      case 'industries':
        return isDataExists(filters?.industries) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputRadioType
              inputName="industries"
              focusId={focusId}
              options={getFilterArray(filters?.industries || [])}
              selectHandler={onFilterChange}
              selectedValue={
                selectedFilters?.industries !== undefined &&
                selectedFilters?.industries?.length > 0
                  ? selectedFilters?.industries?.[0]?.value
                  : industry?.value || ''
              }
            />
          </div>
        ) : null;

      /*Dropdown*/
      case 'remoteJobs':
        return isDataExists(filters?.remoteJobs) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputRadioType
              focusId={focusId}
              inputName="remoteJobs"
              options={getFilterArray(filters?.remoteJobs || [])}
              selectHandler={onFilterChange}
              selectedValue={
                selectedFilters?.remoteJobs !== undefined &&
                selectedFilters?.remoteJobs?.length > 0
                  ? selectedFilters?.remoteJobs?.[0]?.value
                  : remoteJobs?.value || ''
              }
            />
          </div>
        ) : null;

      case 'jobOfferType':
        return isDataExists(filters?.jobOfferType) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputRadioType
              inputName="jobOfferType"
              focusId={focusId}
              options={getFilterArray(filters?.jobOfferType || [])}
              selectHandler={onFilterChange}
              selectedValue={
                selectedFilters?.jobOfferType?.[0] !== undefined
                  ? selectedFilters?.jobOfferType?.[0]?.value
                  : []
              }
            />
          </div>
        ) : null;

      case 'workingHours':
        return isDataExists(filters?.workingHours) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputRadioType
              inputName="workingHours"
              focusId={focusId}
              options={getFilterArray(filters?.workingHours || [])}
              selectHandler={onFilterChange}
              selectedValue={
                selectedFilters?.workingHours?.[0] !== undefined
                  ? selectedFilters?.workingHours?.[0]?.value
                  : []
              }
            />
          </div>
        ) : null;

      case 'contractType':
        return isDataExists(filters?.contractType) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputRadioType
              inputName="contractType"
              focusId={focusId}
              options={getFilterArray(filters?.contractType || [])}
              selectHandler={onFilterChange}
              selectedValue={
                selectedFilters?.contractType?.[0] !== undefined
                  ? selectedFilters?.contractType?.[0]?.value
                  : contract?.value || ''
              }
            />
          </div>
        ) : null;

      /*Checkbox*/

      case 'employmentType':
        return isDataExists(filters?.employmentType) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputCheckbox
              focusId={focusId}
              inputName="employmentType"
              options={getFilterArray(filters?.employmentType || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.employmentType}
            />
          </div>
        ) : null;
      case 'salaryRangeLevels':
        return isDataExists(filters?.salaryRangeLevels) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputCheckbox
              focusId={focusId}
              inputName="salaryRangeLevels"
              options={getFilterArray(filters?.salaryRangeLevels || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.salaryRangeLevels}
            />
          </div>
        ) : null;

      case 'sortOrder':
        return isDataExists(filters?.sortOrder) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputRadioType
              inputName="sortOrder"
              focusId={focusId}
              options={getFilterArray(filters?.sortOrder || [])}
              selectHandler={onFilterChange}
              selectedValue={
                selectedFilters?.sortOrder?.[0] !== undefined &&
                selectedFilters?.sortOrder?.length > 0
                  ? selectedFilters?.sortOrder?.[0]?.value
                  : sortby.sortby
                  ? sortby.sortby
                  : sortOption?.value || ''
              }
            />
          </div>
        ) : null;

      case 'jobTypes':
        return isDataExists(filters?.jobTypes) ? (
          <div className={`${getLayoutClass(customFilterLayout?.[item])}`}>
            <FieldInputCheckbox
              inputName="jobTypes"
              focusId={focusId}
              options={getFilterArray(filters?.jobTypes || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.jobTypes}
            />
          </div>
        ) : null;
      case 'scheduleType':
        return isDataExists(filters?.scheduleType) ? (
          <div className={`${getLayoutClass(customFilterLayout?.[item])} `}>
            <FieldInputCheckbox
              inputName="scheduleType"
              focusId={focusId}
              options={getFilterArray(filters?.scheduleType || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.scheduleType}
            />
          </div>
        ) : null;

      case 'showDisabilityIcon':
        return isDataExists(filters?.showDisabilityIcon) ? (
          <div className={`${getLayoutClass(customFilterLayout?.[item])} `}>
            <FieldInputCheckbox
              inputName="showDisabilityIcon"
              focusId={focusId}
              options={getFilterArray(filters?.showDisabilityIcon || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.showDisabilityIcon}
            />
          </div>
        ) : null;

      case 'education':
        return isDataExists(filters?.education) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputCheckbox
              inputName="education"
              focusId={focusId}
              options={getFilterArray(filters?.education || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.education}
            />
          </div>
        ) : null;

      case 'experienceLevel':
        return isDataExists(filters?.experienceLevel) ? (
          <div className={`${getLayoutClass(customFilterLayout?.[item])} `}>
            <FieldInputCheckbox
              inputName="experienceLevel"
              focusId={focusId}
              options={getFilterArray(filters?.experienceLevel || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.experienceLevel}
            />
          </div>
        ) : null;

      case 'sectors':
        return isDataExists(filters?.sectors) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputCheckbox
              focusId={focusId}
              inputName="sectors"
              options={getFilterArray(filters?.sectors || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.sectors}
            />
          </div>
        ) : null;

      /*RadioButton*/
      case 'managementLevel':
        return isDataExists(filters?.managementLevel) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputRadioType
              inputName="managementLevel"
              focusId={focusId}
              options={getFilterArray(filters?.managementLevel || [])}
              selectHandler={onFilterChange}
              selectedValue={
                selectedFilters?.managementLevel?.[0] !== undefined
                  ? selectedFilters?.managementLevel?.[0]?.value
                  : []
              }
            />
          </div>
        ) : null;

      /* language */
      case 'language':
        return isDataExists(filters?.languages) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputRadioType
              inputName="language"
              focusId={focusId}
              options={getLanguageArray(filters?.languages || [])}
              selectHandler={onLangChange}
              selectedValue={sitecoreContext?.languageISO}
            />
          </div>
        ) : null;

      /* specialization */
      case 'specialization':
        return isDataExists(filters?.specialization) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputRadioType
              inputName="specialization"
              focusId={focusId}
              options={getFilterArray(filters?.specialization || [])}
              selectHandler={onFilterChange}
              selectedValue={
                selectedFilters?.specialization !== undefined &&
                selectedFilters?.specialization?.length > 0
                  ? selectedFilters?.specialization?.[0]?.value
                  : specialization?.value || ''
              }
            />
          </div>
        ) : null;

      case 'publicationDates':
        return isDataExists(filters?.publicationDates) ? (
          <div className={getLayoutClass(customFilterLayout?.[item])}>
            <FieldInputRadioType
              focusId={focusId}
              inputName="publicationDates"
              options={getFilterArray(filters?.publicationDates || [])}
              selectHandler={onFilterChange}
              selectedValue={
                selectedFilters?.publicationDates !== undefined &&
                selectedFilters?.publicationDates?.length > 0
                  ? selectedFilters?.publicationDates?.[0]?.value
                  : publicationDates?.value || ''
              }
            />
          </div>
        ) : null;

      default:
        return null;
    }
  };

  return (
    <div className="searchfilters__panel">
      {loading ? (
        <div className="filter-overlay-loader">
          <Loader
            loaderMsg={t('filter-loading')}
            loaderWithMsg
            customLoaderStyle={{
              width: '2rem',
              height: '2rem',
              color: '#C25700',
            }}
            customTextStyle={{ color: '#C25700' }}
          />
        </div>
      ) : null}
      <div className="" style={loading ? { visibility: 'hidden', height: 0 } : {}}>
        <div className="">
          <div className="row search-filter-layout">
            <SearchAccordion
              allowMultipleOpen={!isMobView}
              data={Object.keys(customFilterLayout)}
              focusId={focusId}
              filterContent={getFilterLayout}
              selected={selectedFilters && selectedFilters}
              sortOption={sortby?.sortby ? sortby?.sortby : sortOption?.value || ''}
              defaultValues={{ industry, specialization, contract, distance }}
            />
            {fields?.allowToggle?.value && isObjNotEmpty(filters) ? (
              <AmsToggleSwitch
                inputName="showamsjobs"
                title={t('external-job-hide-toggle')}
                ariaLabel="AMS jobs toggle button"
                selectHandler={onFilterChange}
                selectedValue={filterObj?.showamsjobs || false}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 *
 */
const mapStateToProps = (state) => ({
  jobData: state.jobSearchInfoReducer.data,
  loading: state.jobSearchInfoReducer.loading,
  removeFocus: state.checkboxFocusReducer.removeFocus,
  sortType: state.sortJobsReducer.data,
});

SearchFiltersAccItem.propTypes = {
  setFilterActive: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    minSalary: { value: 1.5 },
    maxSalary: { value: 10 },
    defaultSalaryRange: {
      value: {
        min: 4,
        max: 7,
      },
    },
    salaryStepper: { value: 0.5 },
    currencySymbol: { value: '$' },
    currencyPrefix: { value: 'k' },
    sortOption: {},
    industry: {},
    contract: {},
    specialization: {},
  }),
  jobData: PropTypes.shape({}).isRequired,
};

SearchFiltersAccItem.defaultProps = {
  t: (val) => val,
  fields: {
    minSalary: { value: 1.5 },
    maxSalary: { value: 10 },
    defaultSalaryRange: {
      value: {
        min: 4,
        max: 7,
      },
    },
    salaryStepper: { value: 0.5 },
    currencySymbol: { value: '$' },
    currencyPrefix: { value: 'k' },
    sortOption: {},
    industry: {},
    distance: {},
    specialization: {},
    contract: {},
  },
  jobData: {},
};

export default withRouter(
  connect(mapStateToProps)(withSitecoreContext()(SearchFiltersAccItem))
);
