import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AccordionBlock from '../../../Accordion';
import Loader from '../../../../core/ErrorBoundary/Loader';
import Error from '../../../../core/ErrorBoundary/Error';
import { getCandidateDetailsApi } from '../../../../../services/apiServices/candidateService';
import { clearProfileInfo } from '../../../../../redux/actions/actions';
import { useHistory } from 'react-router-dom';

/**
 * @description -DashboardBenifitsAccordion
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const DashboardBenifitsAccordion = ({ fields, data, t, profileInfo, dispatch }) => {
  const { text, title } = fields;
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [error, setError] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (profileInfo?.Id) {
      setprofileDataLoading(false);
      if (profileInfo && !profileInfo?.isAssociate) {
        history.push('/en/page-not-found');
      }
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);

  if (profileInfo && !profileInfo?.isAssociate) {
    return null;
  }
  const handleApiRefresh = () => {
    dispatch(clearProfileInfo());
    setTimeout(() => getCandidateDetailsApi({}, dispatch), 500);
  };
  return (
    <section
      className="profile-accordion-section"
      aria-labelledby="title"
      aria-describedby="info"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 pt-5">
            {profileDataLoading ? (
              <Loader
                loaderMsg={t('fetching-your-data')}
                loaderWithMsg
                customLoaderStyle={{
                  width: '4rem',
                  height: '4rem',
                  color: '#C25700',
                }}
                customTextStyle={{ color: '#C25700' }}
              />
            ) : error ? (
              <div className="apiError">
                <Error
                  errorMsg={t('error-message')}
                  t={t}
                  refreshApi={true}
                  handleApiRefresh={handleApiRefresh}
                />
              </div>
            ) : (
              <>
                {title?.value && (
                  <div className="row pt-2">
                    <div className="col-md-12">
                      <Text
                        field={title}
                        tag="h2"
                        className="title-small"
                        id="info"
                      />
                    </div>
                  </div>
                )}
                {text?.value && (
                  <div className="row">
                    <div className="col-md-12">
                      <Text field={text} tag="h1" className="text" id="info" />
                    </div>
                  </div>
                )}
                {data && data.length > 0 && (
                  <>
                    <div className="row">
                      <div className="col">
                        <AccordionBlock
                          isAssociateContentPage={true}
                          allowMultipleOpen={true}
                          data={data}
                          showExpandButton={true}
                          t={t}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

DashboardBenifitsAccordion.defaultProps = {
  t: (val) => val,
  fields: {},
  profileInfo: {},
  dispatch: () => {},
  data: [],
};

DashboardBenifitsAccordion.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.arrayOf({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(
  connect(mapStateToProps)(DashboardBenifitsAccordion)
);
