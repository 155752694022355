/*eslint-disable*/
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import set from 'lodash/set';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { patternRegexCheck } from '../../DynamicForm/helper/validationFactory';
import {
  isObjNotEmpty,
  getLastIndexOfString,
  isNorthAmericanCountry,
} from '../../../../../utils/helperUtils';
import { getValidationDatabyType } from '../../DynamicForm/helper/filter';
import {
  checkValueTypeAndGetTheCount,
  fillInAddress,
  getPlacePredictions,
} from '../../../../business/Utils/helper';
import { Controller } from 'react-hook-form';
import ErrorBlock from '../ErrorBlock';
import { countryCodesList } from '../../../../../utils/enums';

/**
 * @description - Dynamic LookUpWithTextboxMailingLocation component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const LookUpWithTextboxMailingLocation = ({
  id,
  name,
  label,
  requiredValidation = [],
  type,
  icon,
  customStyle,
  readOnly,
  customError,
  hintText,
  register,
  dataValidations,
  getValues,
  setValue,
  watch,
  showBasedOnFieldName,
  hideBasedOnFieldName,
  isHideField,
  value,
  validateOnLoad,
  formName,
  control,
  className,
  lookupField,
  clearErrors,
  trigger,
  showCurrentLocation,
  formState,
  t,
  contentLoading,
  formValues,
  countryCodes,
  sitecoreContext,
}) => {
  const fieldError = get(customError, name);
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  const patternData = getValidationDatabyType(dataValidations, 'pattern');
  const locationData = getValidationDatabyType(dataValidations, 'location');
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;
  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;
  const lname = getLastIndexOfString(name);

  //Google Location API Params---------
  const autoCompleteRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [locationAdress, setLocationAddress] = useState({});
  const [loading, setLoading] = useState(false);
  const mapInit = useSelector((state) => state.mapReducer.data);
  const checkBox16 = watch('customText16_hidden');
  const watchMailingAddressZip = watch('MailingAddress.zip');
  const addressValue = watch('address') || null;
  const mailingAddressValue = watch('MailingAddress') || null;
  const addressZip = watch('address.zip') || null;
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  const profileInfoReducer = useSelector((state) => state.profileInfoReducer);
  const [cityName, setCityName] = useState('');

  const userData = profileInfoReducer?.data?.PersonalDetails.data[0];

  const sameAsMailingValidate =
    name === 'MailingAddress.zip' &&
    checkBox16 &&
    watchMailingAddressZip === null &&
    isNorthAmericanCountry(countryName);

  /**
   * @description to load map script if not loaded
   * @returns {undefined}- nothing
   */
  const initPlaceSearch = () => {
    if (scriptLoaded === false) {
      if (window?.google) {
        handleScriptLoad();
        setScriptLoaded(true);
      }
    }
  };

  useEffect(() => {
    contentLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (mapInit && value && userData) {
      let result = { formatAddr: value, postalCode: value };
      forEach(lookupField, function (item) {
        let fieldValue = formValues ? get(formValues, item.fields.name.value) : null;
        result.formatAddr += fieldValue ? ` ${fieldValue}` : '';
      });
      googleGeocoder(result, null, null);
    } else {
      setLoading(false);
      setValue(name, null, param);
      setLocationAddress({});
    }
  }, [mapInit, value, userData]);

  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    if (hideFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    if (checkBox16 && name === 'MailingAddress.zip' && addressZip) {
      setValue('MailingAddress.address1', addressValue?.address1, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue('MailingAddress.address2', addressValue?.address2, {});
      setLocationAddress({ ...addressValue, country: userData?.country });
      setLocationAddress({ ...addressValue, country: userData?.country });
    }
  }, [checkBox16, addressZip]);

  useEffect(() => {
    if (checkBox16 && name === 'MailingAddress.zip') {
      setHideField(true);
    } else {
      setHideField(false);
    }
  }, [checkBox16]);
  /**
   * @description - to get place from lat n lng
   * @param {string} address - address
   * @param {number} lat - latitude
   * @param {number} lng - longitude
   * @returns {undefined} - no returns
   */
  const googleGeocoder = (address, lat, lng) => {
    if (window?.google?.maps) {
      setLoading(true);
      const geocoder = new window.google.maps.Geocoder();
      let options = {};
      if (address) {
        options.address = address.formatAddr;
      } else if (lat && lng) {
        const latlng = new window.google.maps.LatLng(lat, lng);
        options.latLng = latlng;
        set(formState.touched, name, true);
      }
      geocoder.geocode(options, function (results, status) {
        setLoading(false);
        if (status == google.maps.GeocoderStatus.OK) {
          const addressStructured = results?.[0]?.address_components;
          if (addressStructured) {
            let formatAddress = fillInAddress(
              addressStructured,
              addressStructured?.postalCode
                ? addressStructured?.postalCode
                : address?.postalCode
            );
            getPlacePredictions(formatAddress.zip, countryName, (value) => {
              setCityName(value);
            });
            setLocationAddress(formatAddress);
          } else {
            setLocationAddress({});
          }
        } else {
          locationAlert(t('location-not-available'));
        }
      });
    }
  };

  function enableEnterKey(input) {
    /* Store original event listener */
    const _addEventListener = input.addEventListener;

    const addEventListenerWrapper = (type, listener) => {
      if (type === 'keydown') {
        /* Store existing listener function */
        const _listener = listener;
        listener = (event) => {
          /* Simulate a 'down arrow' keypress if no address has been selected */
          const suggestionSelected = document.getElementsByClassName(
            'pac-item-selected'
          ).length;
          if (event.key == 'Shift') return false;
          if (
            (event.key === 'Tab' || event.key === 'Enter') &&
            !suggestionSelected
          ) {
            const e = new KeyboardEvent('keydown', {
              key: 'ArrowDown',
              code: 'ArrowDown',
              keyCode: 40,
            });
            _listener.apply(input, [e]);
          }
          _listener.apply(input, [event]);
        };
      }
      _addEventListener.apply(input, [type, listener]);
    };

    input.addEventListener = addEventListenerWrapper;
  }

  /**
   * @description - callback to be called followed by script load
   * @returns {undefined} - nothing
   */
  const handleScriptLoad = () => {
    const countryNames = countryName === 'us' ? [countryName, 'pr'] : [countryName];

    if (window.google.maps.places?.Autocomplete) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        {
          types: ['postal_code'],
          componentRestrictions: {
            country: countryNames,
          },
          fields: [
            'address_components',
            'formatted_address',
            'geometry',
            'place_id',
            'name',
          ],
        }
      );
      if (countryCodes) {
        autoComplete.setComponentRestrictions({
          country: countryCodes.split(','),
        });
      }
      autoComplete.addListener('place_changed', () => {
        setLoading(true);
        handlePlaceSelect(autoComplete);
      });
      enableEnterKey(autoCompleteRef.current);
    }
  };

  /**
   * @description - to handle place select event
   * @param {function} autoComplete - AutoComplete reference
   * @returns {undefined}
   */
  async function handlePlaceSelect(autoComplete) {
    const addressObject = await autoComplete.getPlace();

    const name = addressObject?.name;
    const address = addressObject?.address_components;

    if (name) {
      getPlacePredictions(name, countryName, (value) => {
        setCityName(value);
      });
    }

    if (address) {
      let formatAddress = fillInAddress(address);
      setLocationAddress(formatAddress);
    } else {
      setLocationAddress({});
    }
  }

  useEffect(() => {
    if (isObjNotEmpty(locationAdress)) {
      let addressObjKeys = Object.keys(locationAdress);
      if (!addressObjKeys.includes(lname)) {
        trigger(name);
      } else {
        if (locationAdress?.[lname]) {
          setValue(name, locationAdress?.[lname], param);
        } else {
          trigger(name);
        }
        clearErrors(name);
        forEach(lookupField, function (item) {
          let fieldName = item?.fields?.name?.value;
          let lastFieldIndex = getLastIndexOfString(fieldName);
          let fieldVal = locationAdress?.[lastFieldIndex]
            ? locationAdress?.[lastFieldIndex]
            : null;
          const verifyName = fieldName === 'MailingAddress.city';
          const verifyValue = fieldVal !== cityName;
          const updatedFieldValue = verifyName && verifyValue ? cityName : fieldVal;
          setValue(fieldName, updatedFieldValue, param);
        });
      }
      setLoading(false);
    }
  }, [locationAdress, cityName]);

  /**
   * @description to get location on button click
   * @param {boolean} showCurrent - whether to show current location
   * @returns {undefined}
   */
  const search = (showCurrent) => {
    initPlaceSearch();
    if (navigator.geolocation && showCurrent) {
      navigator.geolocation.getCurrentPosition(handlePosition, handleError);
    }
  };

  /**
   * to handle navigator error
   * @param {*} error - error object
   * @returns {undefined} - nothing
   */
  const handleError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        // User denied access to location. Perhaps redirect to alternate content?
        locationAlert(t('location-permission-denied'));
        break;
      case error.POSITION_UNAVAILABLE:
        locationAlert(t('location-not-available'));
        break;
      case error.PERMISSION_DENIED_TIMEOUT:
        locationAlert(t('location-permission-timedout'));
        break;
      case error.UNKNOWN_ERROR:
        locationAlert(t('location-unknown-error'));
        break;
    }
  };

  /**
   * @description - to handle position if cords available
   * @param {object} position - position cords
   * @returns {undefined} - no returns
   */
  const handlePosition = (position) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    googleGeocoder(null, lat, lng);
  };

  /**
   * @description to handle location access error
   * @param {string} message - error message
   * @returns {undefined} - no
   */
  const locationAlert = (message) => {
    // alert(message);
    setLoading(false);
  };

  const validateLocation = (val) => {
    return val === locationAdress?.[lname];
  };

  const isValueSetForFieldName = (name) => {
    let lastFieldIndex = getLastIndexOfString(name);
    return !!locationAdress?.[lastFieldIndex];
  };

  return (
    <>
      <div
        className={`form-block complex location-search ${
          fieldError ? 'error withoutBg' : ''
        } ${!showField || hideField ? 'hide' : ''}`}
        style={customStyle}
      >
        <Controller
          className={className}
          name={name}
          control={control}
          render={({ onChange, onBlur, value, ref }) => (
            <input
              className={getValues && getValues(name) ? 'filled' : ''}
              id={id}
              disabled={checkBox16}
              type="text"
              readOnly={readOnly}
              onBlur={onBlur}
              aria-required={
                showField && requiredData?.fields?.value?.value ? !hideField : false
              }
              {...(fieldError
                ? {
                    'aria-invalid': true,
                    'aria-describedby': `err_${name}_${id}`,
                  }
                : '')}
              ref={autoCompleteRef}
              onChange={(selected) => {
                let selectedVal = selected?.target?.value
                  ? selected?.target?.value
                  : selected;
                onChange(selectedVal);
                setLocationAddress({});
                initPlaceSearch();
              }}
              placeholder=""
              value={value || ''}
            />
          )}
          rules={
            !showField || hideField
              ? {}
              : {
                  required:
                    showField && requiredData?.fields?.value?.value
                      ? !hideField
                      : false,
                  validate: {
                    validateLocation: (value) =>
                      validateLocation(value) ||
                      locationData?.fields?.message?.value ||
                      t('validation-failed'),
                  },
                  pattern: {
                    value: patternData?.fields?.value?.value
                      ? patternRegexCheck(patternData.fields.value.value)
                      : '',
                    message:
                      patternData?.fields?.message?.value || t('validation-failed'),
                  },
                }
          }
        />
        {label && (
          <label htmlFor={id}>
            <div className="label-text">
              {icon && (
                <div className="input-icon">
                  <span className="input-icon"></span>
                </div>
              )}
              {label}
            </div>
          </label>
        )}
        {loading && !fieldError && (
          <span className="spinner-border spinner-border-sm" role="status"></span>
        )}
        {showCurrentLocation && (
          <button
            type="button"
            className="search-icon"
            onClick={() => search(true)}
            aria-label="Geo Location Search"
          ></button>
        )}
        <ErrorBlock
          id={id}
          error={fieldError}
          label={label}
          requiredData={requiredData}
        />
        {hintText && <div className="hintText">{hintText}</div>}
      </div>
      {lookupField?.map((item, idx) => {
        return (
          <div
            key={`formblock${idx}`}
            className={`form-block complex ${
              get(customError, item?.fields?.name?.value) ? 'error withoutBg' : ''
            } ${
              (item?.fields?.hidden?.value && !locationAdress?.[lname]) ||
              !item?.fields?.showFieldOnValue?.value ||
              hideField
                ? 'hide'
                : ''
            }`}
            style={{
              width: item?.fields?.width?.value
                ? item?.fields.width.value + '%'
                : '100%',
            }}
          >
            <input
              className={
                getValues && getValues(item?.fields?.name?.value) ? 'filled' : ''
              }
              name={item?.fields?.name?.value}
              id={id}
              type="text"
              autoComplete="off"
              readOnly={
                item?.fields?.readonly?.value === true &&
                isValueSetForFieldName(item?.fields?.name?.value)
                  ? true
                  : false
              }
              aria-required={requiredData?.fields?.value?.value?.value}
              {...(fieldError
                ? {
                    'aria-invalid': true,
                    'aria-describedby': `err_${item?.fields?.name?.value}_${idx}`,
                  }
                : '')}
              ref={
                register
                  ? register({
                      required: requiredData?.fields?.value?.value || false,
                    })
                  : () => {}
              }
            />
            <label htmlFor={idx}>
              <div className="label-text">
                {icon && (
                  <div className="input-icon">
                    <span className="input-icon"></span>
                  </div>
                )}
                {item?.fields?.label?.value}
              </div>
            </label>
            <ErrorBlock
              id={idx}
              error={get(customError, item?.fields?.name?.value)}
              label={item?.fields?.label?.value}
              requiredData={requiredData}
            />
          </div>
        );
      })}
    </>
  );
};

LookUpWithTextboxMailingLocation.defaultProps = {
  id: '',
  name: '',
  label: '',
  placeholder: '',
  type: 'text',
  icon: '',
  readOnly: false,
  setValue: () => {},
  t: () => {},
  trigger: () => {},
  lookupField: [],
};

LookUpWithTextboxMailingLocation.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  t: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  lookupField: PropTypes.arrayOf(PropTypes.shape({})),
};

export default LookUpWithTextboxMailingLocation;
