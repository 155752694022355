/* eslint-disable react/display-name */
import React from 'react';

import { withTranslation } from 'react-i18next';
import {
  RichText,
  withSitecoreContext,
  Text,
} from '@sitecore-jss/sitecore-jss-react';
import { DATE_FORMATS } from '../../../../../../utils/enums';
import moment from 'moment';

/**
 * @description - PaymentStubDetails component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const PaymentStubDetails = ({ fields, paymentDetails, sitecoreContext, t }) => {
  const { items, title, description, infoText } = fields;
  const {
    SOCIALSECURITYNUMBER,
    ENDDATEWORKED,
    TOADDR,
    DIRECTDEPOSIT,
    STARTDATEWORKED,
    REG_HOURS,
    RELEASE_DATE,
    PERIODENDDATE,
    PAYMENTDATE,
    PAYEENAME,
    OVT_HOURS,
    ADDRESS1,
    ADDRESS2,
    BUSINESSUNIT,
    CHECKDDNUMBER,
    EMPLOYEENUMBER,
  } = paymentDetails;

  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  const forDatesWorking = () => {
    if (countryName === 'ca') {
      return `${
        moment(STARTDATEWORKED).format(DATE_FORMATS.ROBOTS_FORMAT_REV) +
        ' ' +
        t('to') +
        ' ' +
        moment(ENDDATEWORKED).format(DATE_FORMATS.ROBOTS_FORMAT_REV)
      }`;
    }
    return `${
      moment(STARTDATEWORKED).format(DATE_FORMATS.DEFAULT_US_MM_DD_YYYY) +
      ' ' +
      t('to') +
      ' ' +
      moment(ENDDATEWORKED).format(DATE_FORMATS.DEFAULT_US_MM_DD_YYYY)
    }`;
  };

  const addLineBreaks = (address) => {
    const words = address.split(' ');
    const formattedAddress = [];
    for (let i = 0; i < words.length; i++) {
      if (i > 0 && i % 5 === 0) {
        formattedAddress.push(' ');
      }
      formattedAddress.push(words[i]);
    }
    return formattedAddress.join(' ');
  };
  const formattedAddress1 = addLineBreaks(ADDRESS1 + ',' + ' ' + ADDRESS2);

  return (
    <div className="pb-3">
      <div className="row">
        <div className="col  d-flex justify-content-center">
          <Text field={title} tag="h2" className="title-small" id="info" />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col">
          <RichText field={description} className="subtitle" /> 
        </div>
      </div>
      <div className="row  mt-3">
        <div className="col-lg-12 row">
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[0]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">{PAYEENAME}</p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[9]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">{CHECKDDNUMBER}</p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[1]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">{formattedAddress1}</p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[10]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">
                {countryName === 'ca'
                  ? moment(PAYMENTDATE).format(DATE_FORMATS.ROBOTS_FORMAT_REV)
                  : moment(PAYMENTDATE).format(DATE_FORMATS.DEFAULT_US_MM_DD_YYYY)}
              </p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[2]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">{SOCIALSECURITYNUMBER}</p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[11]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">
                {countryName === 'ca'
                  ? moment(PERIODENDDATE).format(DATE_FORMATS.ROBOTS_FORMAT_REV)
                  : moment(PERIODENDDATE).format(DATE_FORMATS.DEFAULT_US_MM_DD_YYYY)}
              </p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[3]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">{EMPLOYEENUMBER}</p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[12]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">
                {countryName === 'ca'
                  ? moment(RELEASE_DATE).format(DATE_FORMATS.ROBOTS_FORMAT_REV)
                  : moment(RELEASE_DATE).format(DATE_FORMATS.DEFAULT_US_MM_DD_YYYY)}
              </p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[4]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">{BUSINESSUNIT}</p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[13]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">{forDatesWorking()}</p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[5]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">{TOADDR}</p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6"></div>
            <div className="col-lg-6"></div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[6]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">{DIRECTDEPOSIT}</p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6"></div>
            <div className="col-lg-6"></div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[7]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">{REG_HOURS}</p>
            </div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6"></div>
            <div className="col-lg-6"></div>
          </div>
          <div className="col-lg-6 row text-left py-1 d-flex">
            <div className="col-lg-6">
              <p className="user-details-data label">
                {items[8]?.fields?.label?.value} :
              </p>
            </div>
            <div className="col-lg-6">
              <p className="user-details-data copy">{OVT_HOURS}</p>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-12 row">
        {items[0]?.fields?.label?.value} 
      </div> */}
      </div>
      <div className="row text-left">
        <div className="col">
          <RichText field={infoText} className="subtitle" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(PaymentStubDetails));
