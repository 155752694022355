/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import DashboardAccordion from '../../DashboardAccordion';
import PaymentStubsTable from '../../../../../core/PaymentStubsTable';

/**
 * @description - PaymentStubTable component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const PaymentStubTable = ({ t, fields, tableData }) => {
  const { icon } = fields;
  const [paymentStubData, setPaymentStubData] = useState([]);
  const [loading, setLoading] = useState(null);

  const columns = [
    {
      selector: 'Type',
      name: '',
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.Type}</div>;
      },
    },
    {
      selector: 'Earnings',
      name: t('earnings'),
      grow: 1,
      right: true,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.Earnings}</div>;
      },
    },
    {
      selector: 'NonTaxIncome',
      name: t('non-Tax-Income'),
      right: true,
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.NonTaxIncome}</div>;
      },
    },
    {
      selector: 'Deductions',
      name: t('deductions'),
      right: true,
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.Deductions}</div>;
      },
    },
    {
      selector: 'Expenses',
      name: t('expenses'),
      right: true,
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.Expenses}</div>;
      },
    },
    {
      selector: 'Net',
      name: t('net'),
      right: true,
      grow: 1,
      cell: (row) => {
        return (
          <div className="stubs-content-data">{row?.Net ? row?.Net : '$0.00'}</div>
        );
      },
    },
  ];
  useEffect(() => {
    setLoading(true);
    prepareData(tableData);
    setLoading(false);
  }, []);

  const prepareData = (responseData) => {
    const data = responseData?.map((object, index) => ({
      ...object,
      id: index + 1,
    }));
    setPaymentStubData(data);
  };

  return (
    <>
      <PaymentStubsTable
        columns={columns}
        icon={icon}
        row={paymentStubData}
        pagination={paymentStubData?.length > 5 ? true : false}
        noHeader={true}
        showHeader={false}
        noTableHead={false}
        progressPending={loading}
        noDataMessage={t('empty-payment-stub-content')}
      />
    </>
  );
};
const CurrentAndYTD = ({ fields, t, profileInfo, tableData }) => {
  const { title } = fields;
  const [open, setOpen] = useState(true);

  return (
    <div>
      <DashboardAccordion
        id={title?.value}
        title={title}
        isOpen={open}
        handleToggle={() => setOpen(!open)}
        content={
          <PaymentStubTable
            profileInfo={profileInfo}
            fields={fields}
            t={t}
            tableData={tableData}
          />
        }
      />
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(CurrentAndYTD));
