import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { mediaApi, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Components } from '../Utils/helper';
import classNames from 'classnames';
//import { assountSettingMock } from './assountSettingMock';
//import { mockData } from './dashboardMock';
//import { profileMock } from './profileMock';
import './dashboard.scss';
import { checkMobileSource, getImg } from '../../../utils/helperUtils';
import PrivateRoute from '../../core/PrivateRoute';
import { getProfilePicApi } from '../../../services/apiServices/candidateService';
import 'slick-carousel/slick/slick.css';
import '../../core/CardScrolls/slick-theme.scss';

/**
 * @description - Dashboard component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const Dashboard = ({ rendering, sitecoreContext }) => {
  //rendering = profileMock;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';

  const { placeholders = [], fields, params } = rendering;
  const { bgcolor = '' } = params;
  const dispatch = useDispatch();

  useEffect(() => {
    getProfilePicApi(dispatch);
  }, []);

  return (
    <PrivateRoute countryName={countryName}>
      <section className="dashboard">
        <div className="container-fluid">
          <div className="row">
            {/* Dashboard Sidebar */}
            {!checkMobileSource() && (
              <div className={'col-lg-2 dashboard__sidebar ' + brandName}>
                {placeholders &&
                  placeholders['jss-left'].map((block) => {
                    const Component = block.componentName
                      ? Components[block.componentName]
                      : '';
                    if (!Component) return false;
                    return <Component key={block?.uid} fields={block?.fields} />;
                  })}
              </div>
            )}
            <div
              className={'col-lg-10 dashboard__content ' + brandName}
              style={
                brandName === 'manpower'
                  ? { background: bgcolor ? bgcolor : '#ffffff' }
                  : {
                      backgroundImage: `url(${mediaApi.updateImageUrl(
                        getImg(fields.backgroundImage)
                      )})`,
                    }
              }
            >
              <div className="row">
                {placeholders &&
                  placeholders['jss-content'].map((block) => {
                    const width = block?.fields?.width?.value || '100';
                    const Component = block.componentName
                      ? Components[block.componentName]
                      : '';
                    if (!Component) return false;
                    return (
                      <div
                        key={block?.uid}
                        className={classNames({
                          'col-lg-12': width === '100',
                          'col-lg-6': width === '50',
                          'col-lg-4': width === '25',
                        })}
                      >
                        <Component
                          key={block?.uid}
                          fields={block?.fields}
                          sitecoreContext={sitecoreContext}
                          data={
                            block?.placeholders && block?.placeholders['jss-content']
                              ? block?.placeholders['jss-content']
                              : []
                          }
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </PrivateRoute>
  );
};

Dashboard.defaultProps = {
  sitecoreContext: {},
  contentBlock: [],
  params: {
    bgcolor: '',
  },
};

Dashboard.propTypes = {
  sitecoreContext: PropTypes.shape({}),
  contentBlock: PropTypes.arrayOf(PropTypes.node).isRequired,
  params: PropTypes.shape({
    bgcolor: PropTypes.string,
  }),
};

export default withSitecoreContext()(withTranslation()(Dashboard));
