/* eslint-disable require-jsdoc */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import ActionBarJob from '../../ActionBars/ActionBarJob';
import { SEARCH, GTM } from '../../../../constants';
import { socialShareMapper } from '../../../../factory/jobSearchFilterFactory';
import { jobVacancyEvent } from '../../../../../src/services/apiServices/gtmService';
import { useSelector } from 'react-redux';
import './JobBlock.scss';
import '../articleblock.scss';
import '../../Breadcrumb/breadcrumb.scss';
import JobHeader from '../JobHeader/JobHeader';
import JobDetailsInfo from '../JobDetails';
import { isObjNotEmpty } from '../../../../utils/helperUtils';

/**
 *@description to show detailed job
 * @param {*} param0 -
 * @returns {node} - html
 */
const JobBlock = ({ fields, t, JobDetailSidebar, sitecoreContext }) => {
  const history = useHistory();
  const [bcItems, setBcItems] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [alertmsgMsg, setAlertmsgMsg] = useState();
  const [isInLineText, setisInLineText] = useState(false);
  const [isGoogleMapAvail, setGoogleMapAvail] = useState(null);
  const { socialPlatforms, items, icons, applyJobUrl } = fields;


  const loginReducer = useSelector((state) => state.loginReducer);
  const loginState = loginReducer?.state;

  const jobDetails = items || {};
  const hideParams = true;

  const expiredSearchPageUrl = !jobDetails?.isExpired ?
    sitecoreContext?.Country?.expiredJobSearchURL?.toLowerCase() : '';

  const socialItems = socialShareMapper(socialPlatforms?.socialShares);
  //const type = sitecoreContext?.LoginType;
  //const isApplied = jobDetails?.isApplied && type?.toUpperCase() !== 'ROMA';

  useEffect(() => {
    return () => {
      if (sessionStorage) sessionStorage.removeItem('applyButtonClickedToLogin');
    };
  }, []);

  /**
   * Redirect to Expired Search Page.
   * @param {object} jobObjItem The Job details.
   * @param {boolean} hideSeparator The true or false.
   * @returns {string} Job Details URL Params.
   */
  const getJobDetailsParams = (jobObjItem, hideSeparator = false) => {
    const separatorSymb = hideSeparator ? '' : ' _ ';
    return jobObjItem === '' || jobObjItem === null || jobObjItem === undefined
      ? ''
      : jobObjItem + separatorSymb;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (typeof window !== 'undefined' && window?.google?.maps) {
        setGoogleMapAvail(window?.google?.maps);
        clearInterval(interval);
      }
    }, 100);
  }, []);
  /**
   * @description - get user's current location
   * @param {boolean} params object lat and long.
   * @returns {void} - void.
   */
  const saveURLParams = async (params) => {
    let latlng = {};
    if (navigator.geolocation && window?.google?.maps) {
      latlng = {
        lat: +params?.lat,
        lng: +params?.lng,
      };
      const geocoder = new window.google.maps.Geocoder();
      await geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const result =
            results.find(
              (value) =>
                value.types &&
                value.types[0] === 'locality' &&
                value.types[1] === 'political'
            ) || results[3];
          if (result) {
            Object.assign(latlng, { place: result?.formatted_address || '' });
          }
        }
      });
    }
    return latlng;
  };

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      jobDetails?.isExpired &&
      expiredSearchPageUrl
    ) {
      handleRedirect(jobDetails);
    }
  }, [isGoogleMapAvail]);
  /**
   * Redirect to Expired Search Page.
   * @param {string} jobObj The Job Object.
   * @param {string} Location The Job Location.
   * @returns {json} Job Search result.
   */
  const handleRedirect = async (jobObj) => {
    let searchLocation = {
      lat: jobObj?.lattitude || '',
      lng: jobObj?.longitude || '',
      place: jobObj?.jobLocation || '',
    };
    if (searchLocation?.lat && searchLocation?.lng) {
      const locationObj = await saveURLParams(searchLocation);
      searchLocation = locationObj?.place !== '' ? locationObj : searchLocation;
    }
    const jobDetailsParams =
      getJobDetailsParams(jobObj?.salaryRate) +
      getJobDetailsParams(searchLocation?.place) +
      `${
        getJobDetailsParams(jobObj?.jobType)
          ? getJobDetailsParams(jobObj?.jobType)
          : getJobDetailsParams(jobObj?.employmentType)
      }` +
      getJobDetailsParams(jobObj?.domain, hideParams);
    const isSeparatorAtEnd = jobDetailsParams?.trim().slice(-1) === '_';
    const finalParams = isSeparatorAtEnd
      ? jobDetailsParams?.trim().substring(0, jobDetailsParams?.trim().length - 1)
      : jobDetailsParams?.trim();
    const searchPageUrl = expiredSearchPageUrl
      ? expiredSearchPageUrl +
        `?page=1&searchKeyword=${jobObj?.jobTitle}&latitude=${
          searchLocation?.lat || ''
        }&longitude=${searchLocation?.lng || ''}&place=${
          searchLocation?.place || ''
        }&jobDetails=${finalParams}`
      : sitecoreContext?.Country?.jobSearchURL;

    history.push(searchPageUrl || '/');
  };

  useEffect(() => {
    const searchParams = localStorage.getItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_PARAMS);
    setBcItems({
      BreadCrumbs: [
        {
          id: '',
          name: `${fields?.items?.jobTitle}`,
          path: {
            pathname: sitecoreContext?.Country?.jobSearchURL,
            search: searchParams,
            focusResult: true,
          },
          IsJobInclude: true,
        },
      ],
    });
    jobVacancyEvent(
      GTM.EVENTS.JOB_DETAILS,
      fields?.items,
      sitecoreContext,
      loginState
    );
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: 'JobDetailsView',
        eventCategory: 'Job Details',
        eventAction: fields?.items?.jobID,
        eventLabel: fields?.items?.jobTitle,
      });
    }
  }, [t]);

  const actionBarElement = () => {
    return (
      <ActionBarJob
        classDetails="job-details-cta"
        apply={t('apply-now')}
        expapply={t('exp-apply-now')}
        isApplyButton
        applyCtaUrl="#"
        socialFields={{ items: socialItems }}
        jobDetails={jobDetails}
        icons={icons?.mediaIcons}
        applyJobUrl={{ href: applyJobUrl?.applyJobUrl }}
        emailContent={{ headline: { value: items?.jobTitle } }}
        fieldsData={fields}
        inlineText={(data) => setisInLineText(data)}
        errormsg={(errMsg) => setErrorMsg(errMsg)}
        alertmsg={(alrtMsg) => setAlertmsgMsg(alrtMsg)}
      />
    );
  };

  return (
    <>
      {isObjNotEmpty(jobDetails) && (
        <>
          <JobHeader
            icons={icons?.mediaIcons}
            headerDetails={fields}
            title={fields?.items?.jobTitle}
            bcItems={bcItems}
            actionBar={actionBarElement()}
            brandLabel={fields?.items?.brandLabel}
            isApplied={fields?.items?.isApplied}
            t={t}
          />

          {isInLineText && !jobDetails.isExpired && (
            <section
              className={`job-details-block ${alertmsgMsg ? '' : 'errorwrap'}`}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                    <div className={`applied-job ${alertmsgMsg ? '' : 'alerttxt'}`}>
                      <div className={`${alertmsgMsg ? 'icon' : 'error'}`} />
                      <div>{`${alertmsgMsg ? alertmsgMsg : errorMsg}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {/*(isApplied || (isInLineText && !jobDetails.isExpired)) && (    
            <section
              className={`job-details-block ${
                alertmsgMsg || isApplied ? '' : 'errorwrap'
              }`}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                    {!isApplied ? (
                      <div className="applied-job">
                        <div className="icon" />
                        
                        {<RichText field={{ value: t('dashboardURL') }} tag="div" />}

                      </div>
                    ) : (
                      <div
                        className={`applied-job ${alertmsgMsg ? '' : 'alerttxt'}`}
                      >
                        <div className={`${alertmsgMsg ? 'icon' : 'error'}`} />
                        <div>{`${alertmsgMsg ? alertmsgMsg : errorMsg}`}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
                    )*/}

          {jobDetails.isExpired ? (
            <section className="job-details-block">
              <div className="row">
                <div className="col">
                  <div className="expired-job">
                    <div className="icon" />
                    <div>{t('job-expired-message')}</div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}

          <JobDetailsInfo
            fieldsData={fields}
            jobListing={false}
            jobDetailsObj={jobDetails}
            sideBarDetails={JobDetailSidebar}
          />
        </>
      )}
    </>
  );
};

JobBlock.defaultProps = {
  t: () => {},
  JobDetailSidebar: [],
  sitecoreContext: {},
  fields: {
    socialPlatforms: {},
    icons: {},
    items: {
      metaTitle: '',
      metaDescription: '',
    },
    applyJobUrl: {},
  },
};

JobBlock.propTypes = {
  t: PropTypes.func,
  JobDetailSidebar: PropTypes.arrayOf([]),
  sitecoreContext: PropTypes.shape({}),
  fields: PropTypes.shape({
    socialPlatforms: PropTypes.shape({}),
    icons: PropTypes.shape({}),
    items: PropTypes.shape({
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.string,
    }),
    applyJobUrl: PropTypes.shape({}),
  }),
};

export default withSitecoreContext()(
  withTranslation()(
    withPlaceholder([{ placeholder: 'jss-right', prop: 'JobDetailSidebar' }])(
      JobBlock
    )
  )
);
