/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PaymentStubsTable from '../../../../../core/PaymentStubsTable';
import DashboardAccordion from '../../DashboardAccordion';
import './payment-stubs.scss';
import { DATE_FORMATS } from '../../../../../../utils/enums';
import moment from 'moment';

/**
 * @description - PaymentStubs component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const PaymentStubs = ({
  t,
  fields,
  goToDetailedView,
  sitecoreContext,
  loading,
  data,
  profileInfo,
}) => {
  const { icon } = fields;
  const [paymentStubsData, setPaymentStubsData] = useState([]);
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  const entity = profileInfo?.PersonalDetails?.data[0]?.bhBranchEntity;

  const columns = [
    {
      selector: 'CHECKDDNUMBER',
      name: t('checkNumber'),
      grow: 0.9,
      innerWidth: 100,
      cell: (row) => {
        return (
          <div className="stubs-contents">
            <button
              className={`primary btn orange-sd payment-stub-form-button ${
                entity !== 'MPUSA' && entity !== 'MPCAN' ? 'underline' : ''
              } stubs-content`}
              onClick={
                entity !== 'MPUSA' && entity !== 'MPCAN'
                  ? () => {
                      goToDetailedView(
                        row?.CHECKDDNUMBER,
                        row?.EMPLOYEENUMBER,
                        row?.GK_PAYSTUBSJDE1
                      );
                    }
                  : null
              }
            >
              {row?.CHECKDDNUMBER}
            </button>
          </div>
        );
        // Replace 'dynamicColumn${index}' with the appropriate key
      },
    },
    {
      selector: 'PERIODENDDATE',
      name: t('weekEnding'),
      grow: 0.8,
      cell: (row) => {
        return (
          <div className="stubs-content">
            {countryName === 'ca'
              ? moment(row?.PERIODENDDATE).format(DATE_FORMATS.ROBOTS_FORMAT_REV)
              : moment(row?.PERIODENDDATE).format(
                  DATE_FORMATS.DEFAULT_US_MM_DD_YYYY
                )}
          </div>
        );
      },
    },
    // {
    //   selector: 'RELEASE_DATE',
    //   name: t('releaseDate'),
    //   grow: 0.8,
    //   cell: (row) => {
    //     return (
    //       <div className="stubs-content">
    //         {' '}
    //         {countryName === 'ca'
    //           ? moment(row?.RELEASE_DATE).format(DATE_FORMATS.CANADA_FORMAT)
    //           : moment(row?.RELEASE_DATE).format(DATE_FORMATS.DEFAULT_US_MM_DD_YYYY)}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   selector: 'REG_HOURS',
    //   name: t('stub-hours'),
    //   grow: 0.5,
    //   cell: (row) => {
    //     return <div className="stubs-content">{row?.REG_HOURS}</div>;
    //   },
    // },
    // {
    //   selector: 'OVT_HOURS',
    //   name: t('otHours'),
    //   grow: 0.6,
    //   cell: (row) => {
    //     return <div className="stubs-content">{row?.OVT_HOURS}</div>;
    //   },
    // },
    {
      selector: 'TOTALCURREARN',
      name: t('gross'),
      grow: 0.7,
      right: true,
      cell: (row) => {
        return <div className="stubs-content">{row?.TOTALCURREARN}</div>;
      },
    },
    // {
    //   selector: 'TOTALCURRTAXES',
    //   name: t('taxes'),
    //   grow: 0.7,
    //   right: true,
    //   cell: (row) => {
    //     return <div className="stubs-content">{row?.TOTALCURRTAXES}</div>;
    //   },
    // },
    // {
    //   selector: 'TOTALDEDUCTIONSCUR',
    //   name: t('deductions'),
    //   grow: 0.7,
    //   right: true,
    //   cell: (row) => {
    //     return <div className="stubs-content">{row?.TOTALDEDUCTIONSCUR}</div>;
    //   },
    // },
    // {
    //   selector: 'TOTALNONTAXPAYCURRENT',
    //   name: t('nonTax'),
    //   grow: 0.5,
    //   right: true,
    //   cell: (row) => {
    //     return <div className="stubs-content">{row?.TOTALNONTAXPAYCURRENT}</div>;
    //   },
    // },
    {
      selector: 'TOTALCURREXPENSES',
      name: t('expenses'),
      grow: 0.7,
      right: true,
      cell: (row) => {
        return <div className="stubs-content">{row?.TOTALCURREXPENSES}</div>;
      },
    },
    {
      selector: 'TOTALNETEARNINGSCURRENT',
      name: t('netPayment'),
      right: true,
      grow: 0.8,
      cell: (row) => {
        return <div className="stubs-content">{row?.TOTALNETEARNINGSCURRENT}</div>;
      },
    },
  ];
  useEffect(() => {
    if (data) {
      prepareData(data);
    }
  }, [data]);

  const prepareData = (responseData) => {
    const paymentStubsTableData = responseData?.map((object, index) => ({
      ...object,
      id: index + 1,
    }));
    setPaymentStubsData(paymentStubsTableData);
  };

  return (
    <>
      <PaymentStubsTable
        columns={columns}
        icon={icon}
        row={paymentStubsData}
        pagination={paymentStubsData?.length > 5 ? true : false}
        noHeader={true}
        showHeader={false}
        noTableHead={false}
        progressPending={loading}
        noDataMessage={t('empty-payment-stubs-data')}
      />
    </>
  );
};
const MyTaxFormsTable = ({
  fields,
  t,
  profileInfo,
  goToDetailedView,
  loading,
  paymentStubsData,
}) => {
  const { title, titleEntity } = fields;
  const [open, setOpen] = useState(true);

  const entity = profileInfo?.PersonalDetails?.data[0]?.bhBranchEntity;
  const [mainTitle, setMainTitle] = useState(null);

  useEffect(() => {
    if (entity !== undefined) {
      if (entity && (entity === 'MPUSA' || entity === 'MPCAN')) {
        setMainTitle(title);
      } else {
        setMainTitle(titleEntity);
      }
    }
  }, [entity]);

  return (
    <div>
      <DashboardAccordion
        id={title?.value}
        title={mainTitle}
        isOpen={open}
        handleToggle={() => setOpen(!open)}
        content={
          <PaymentStubs
            profileInfo={profileInfo}
            fields={fields}
            t={t}
            goToDetailedView={goToDetailedView}
            loading={loading}
            data={paymentStubsData}
          />
        }
      />
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(MyTaxFormsTable));
