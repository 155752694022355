/* eslint-disable require-jsdoc */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Loader from '../../../../core/ErrorBoundary/Loader';
import ContentTemplate from './ContentTemplate';
import ButtonLink from '../../../../core/globals/link/ButtonLink';
import { dataFetcher } from '../../../../../dataFetcher';
import { API_STATUS_CODES, DASHBOARD } from '../../../../../constants';

const DashboardCurrentAssignments = ({ t, fields }) => {
  const { title } = fields;
  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);
  const [assignments, setAssignments] = useState([]);
  const [profileDataLoading, setProfileDataLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [corpDetails, setCorpDetails] = useState({});

  useEffect(() => {
    if (profileInfo && profileInfo.Id && profileInfo.AssociateDetails) {
      const assignmentsData = profileInfo?.AssociateDetails;
      const currentAssignments = assignmentsData?.filter((assignment) => {
        const startDate = moment(parseInt(assignment?.dateBegin));
        const endDate = moment(parseInt(assignment?.dateEnd));
        const currentDate = moment(Date.now());
        return (
          assignment.dateBegin &&
          startDate <= currentDate &&
          (assignment?.dateEnd ? endDate >= currentDate : true)
        );
      });
      const sortedAssignments = currentAssignments
        ? currentAssignments.sort((a, b) => a.dateBegin - b.dateBegin)
        : [];
      setAssignments(sortedAssignments);
      const recId = assignmentsData[0]?.customText11;
      if (recId) {
        dataFetcher(
          `${DASHBOARD.GET_RECRUITER_DETAILS.RECRUITER_DETAILS}?entity=CorporateUser&id=${recId}&forceUpdate=false`,
          'GET'
        )
          .then((response) => {
            if (response.status === API_STATUS_CODES.OK) {
              setCorpDetails(response?.data?.data?.CorporateUserDetails?.data[0]);
            }

            setProfileDataLoading(false);
          })
          .catch((error) => {
            setProfileDataLoading(false);

            throw error;
          });
      }
    }
  }, [profileInfo]);
  if (!profileInfo?.isAssociate) {
    return null;
  }
  return (
    <>
      <div className="dashboard-block normal custom-table currentApplication">
        <div className={'table-header-block manpower'}>
          <h2>{title?.value}</h2>
        </div>
        {profileDataLoading ? (
          <Loader
            loaderMsg={t('fetching-your-data')}
            loaderWithMsg
            customLoaderStyle={{
              width: '4rem',
              height: '4rem',
              color: '#C25700',
            }}
            customTextStyle={{ color: '#C25700' }}
          />
        ) : (
          assignments &&
          assignments.length > 0 ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 px-4">
                    {assignments.map((item, index) => {
                      return (
                        <ContentTemplate
                          key={index}
                          t={t}
                          title={item.customText4}
                          content={item}
                          assignment={corpDetails}
                          isActive={activeIndex === index}
                          onShow={() =>
                            setActiveIndex(activeIndex === index ? '-1' : index)
                          }
                        />
                      );
                    })}
                  </div>
                  <div className="col-lg-12 px-4 pb-4 text-center">
                    <ButtonLink
                      cssClass="orange"
                      cssArrowClass="styleArrow"
                      ctaText={t('view-all-assignment')}
                      ctaUrl={{
                        href: '/candidate/my-assignments',
                        linktype: 'internal',
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : <div className="no-data text-center">{t('no-current-assignment')}</div>
        )}
      </div>
    </>
  );
};

export default withTranslation()(withSitecoreContext()(DashboardCurrentAssignments));
