/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import {
  withSitecoreContext,
  Image,
  withPlaceholder,
} from '@sitecore-jss/sitecore-jss-react';

import SiteLogo from '../../../assets/images/logo__mp.svg';
import MobileNavAccordion from './MobileNavAccordion';
import {
  checkArray,
  checkRouteField,
  checkUrl,
  setAltUrlForImg,
  pushNavToDataLayer,
  stickyHeader,
  checkMobileSource,
} from '../../../utils/helperUtils';
import MenuLink from '../globals/MenuLink';
import HeaderSubMenu from '../HeaderSubMenu';

import './siteheader.scss';
import './siteheader-manpower.scss';

/**
 * @description - Get full route items.
 * @param {Array} routes - Array of routes.
 * @returns {Array} - Final route array.
 */
const getFullRouteData = (routes) => {
  let finalRoute = [];
  checkArray(routes).map((r) => {
    finalRoute = finalRoute.concat(...checkRouteField(r));
  });
  return finalRoute;
};

/**
 * @description - Check whether the current link is active.
 * @param {string} url - Current url.
 * @param {string} currentPath - Active Path.
 * @param {string} sitecoreRoute - Route from sitecore context.
 * @returns {boolean} - Returns whether the link is active or not.
 */
const isActiveLink = (url, currentPath, sitecoreRoute) => {
  if (url && currentPath) {
    const urlVal = url.split('?')[0];
    const urlLower = urlVal.toLowerCase();
    const pathLower = currentPath.toLowerCase();
    const sitecoreLower = `/${sitecoreRoute && sitecoreRoute.toLowerCase()}`;
    return (
      urlLower === pathLower ||
      pathLower.startsWith(urlLower) ||
      sitecoreLower.startsWith(urlLower)
    );
  }
};
/**
 *
 * @param {*} props - prop
 * @returns {*} - data
 */
const SiteHeaderManpower = (props) => {
  const { fields, sticky, sitecoreContext, notFixed } = props;
  const { location, match, rightComponents = [] } = props;
  const { route } = sitecoreContext;
  const placeholders = route?.placeholders;
  const common = placeholders && placeholders['jss-common'];
  const { items = [] } = (common && common[0]?.fields) || {};
  const leftNavItems = items[0]?.fields?.items || [];
  const rightNavItems = items[1]?.fields?.items || [];

  const [menuHover, setMenuHover] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const leftFullRoutes = getFullRouteData(leftNavItems);
  const rightFullRoutes = getFullRouteData(rightNavItems);
  const baseUrl = sitecoreContext?.language ? `/${sitecoreContext?.language}` : '/';
  const { logo, mobileLogo } = fields;
  const logoVal = setAltUrlForImg(logo, 'Site Logo') || {
    value: { src: SiteLogo, alt: 'Site Logo' },
  };
  const transparentLogoVal = setAltUrlForImg(mobileLogo, 'Site Logo') || {
    value: { src: SiteLogo, alt: 'Site Logo' },
  };
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';

  const { topMenuComps } = props;

  useEffect(() => {
    let lastScrollTop = 0;
    const siteHeader = document.querySelector('.site-header');
    const transparentHeader = document.querySelector('.transparent');
    if (transparentHeader) {
      document.getElementsByTagName('body')[0].style.cssText = 'padding-top:0px';
    } else {
      if (!checkMobileSource()) {
        stickyHeader(siteHeader);
      }
    }

    window.addEventListener(
      'scroll',
      function () {
        const st = window.pageYOffset || document.documentElement.scrollTop;

        if (st > lastScrollTop) {
          siteHeader.classList.remove('sticky');
        } else {
          if (!checkMobileSource()) {
            stickyHeader(siteHeader);
          }
        }
        lastScrollTop = st <= 0 ? 0 : st;
      },
      false
    );
  }, [route?.fields?.IsNavigationTransparent]);

  useEffect(() => {
    const bodyElement = document.querySelector('body');

    if (menuOpen) {
      bodyElement.classList.add('menu-open');
    } else {
      bodyElement.classList.remove('menu-open');
    }
  });

  /**
   *
   * @param {*} event - event
   * @returns {*} - any
   */
  const handleMenuToggle = (event) => {
    event.preventDefault();

    if (
      event.target.className.includes('hamburger-menu-toggle') ||
      event.target.className.includes('line1') ||
      event.target.className.includes('line2') ||
      event.target.className.includes('line3')
    ) {
      setMenuOpen(!menuOpen);
      setMenuHover(!menuHover);
    }
  };
  /**
   * @returns {*} - any
   */
  const handleMobileClose = () => {
    setMenuOpen(false);
    setMenuHover(false);
  };

  useEffect(() => {
    if (checkMobileSource()) {
      document.querySelector('header').style.display = 'none';
      document.querySelector('footer').style.display = 'none';
      document.getElementsByTagName('body')[0].style.cssText = 'padding-top:0px';
    }
  }, []);

  return (
    <>
      <div className="bg-blackout"></div>
      <header
        className={`site-header ${
          route?.fields?.IsNavigationTransparent?.value == true ? 'transparent' : ''
        } ${props?.cssClass ? props.cssClass : ''} ${sticky ? 'sticky' : ''} ${
          notFixed ? 'not-fixed' : ''
        } `}
      >
        <div className="container-xl">
          {/* <a
            href="#main"
            className="skip-to-main-content sr-only"
            aria-label="skip to the main content"
          >
            skip to the main content
          </a> */}
          <div className="row site-header-bar">
            <div className="col-auto">
              <Link
                to={baseUrl}
                aria-label="Go to Home"
                onClick={() => pushNavToDataLayer('Top Nav', 'logo')}
              >
                <Image
                  field={logoVal}
                  className={`site-logo mobl-logo-header ${brandName}`}
                />
                <Image
                  field={transparentLogoVal}
                  className={`site-logo--white ${brandName}`}
                />
              </Link>
            </div>
            <div className="col">
              {rightComponents.map((component) => component)}
              <nav className="main-nav mobl" aria-label="Main Navigation">
                {checkArray(leftFullRoutes).map((route, index) => {
                  const { name } = route;
                  const { url, title } = route.fields || { title: {}, url: {} };
                  const isRouteActive = isActiveLink(
                    checkUrl(route),
                    location.pathname,
                    match?.params?.sitecoreRoute
                  );
                  if (checkArray(route.fields.items).length) {
                    return (
                      <HeaderSubMenu
                        menuItem={route.fields}
                        key={`${name}_${index}`}
                        isParentActive={isRouteActive}
                      />
                    );
                  }
                  return (
                    <div className="main-nav-item" key={`${name}_${index}`}>
                      <MenuLink
                        url={url}
                        routeName={title}
                        activeLinkClass={isRouteActive ? 'header-link-active' : ''}
                        clickHandler={pushNavToDataLayer}
                        actionParams={['Top Nav', title?.value]}
                      />
                    </div>
                  );
                })}

                {checkArray(rightFullRoutes).map((route, index) => {
                  const { name } = route;
                  const { url, title, NavigationIcon } = route.fields || {
                    title: {},
                    url: {},
                    NavigationIcon: {},
                  };
                  const isRouteActive = isActiveLink(
                    checkUrl(route),
                    location.pathname,
                    match?.params?.sitecoreRoute
                  );
                  if (checkArray(route.fields.items).length) {
                    return (
                      <HeaderSubMenu
                        menuItem={route.fields}
                        key={`${name}_${index}`}
                        isParentActive={isRouteActive}
                        className={index == 0 ? 'right-section' : ''}
                        navIcon={NavigationIcon}
                      />
                    );
                  }
                  return (
                    <div
                      className={`main-nav-item ${
                        index == 0 ? 'right-section' : ''
                      }`}
                      key={`${name}_${index}`}
                    >
                      <MenuLink
                        url={url}
                        routeName={title}
                        activeLinkClass={isRouteActive ? 'header-link-active' : ''}
                        clickHandler={pushNavToDataLayer}
                        actionParams={['Top Nav', title?.value]}
                      />
                    </div>
                  );
                })}

                {topMenuComps.map((component) => component)}

                <div className="main-nav-item hamburger">
                  <div
                    className={`hamburger-menu-toggle ${menuOpen ? 'active' : ''}`}
                    role="button"
                    onClick={(e) => handleMenuToggle(e)}
                    aria-label="hamburger menu"
                    tabIndex={0}
                    onKeyPress={() => setMenuOpen(!menuOpen)}
                    aria-expanded={menuOpen}
                  >
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className={`main-nav-slideout ${menuOpen ? 'active' : ''}`}>
          <div className="mobile--actives" />
          <div className="container">
            <div className="mobile-nav-header">
              <button
                aria-label="close"
                className="close-text"
                onClick={() => handleMobileClose()}
              >
                <div className="close__icon" />
              </button>
            </div>
            <ul className="navlist__mobile" role="navigation">
              {checkArray([...leftFullRoutes, ...rightFullRoutes]).map(
                (route, index) => {
                  const { name } = route;
                  const { url, title } = route.fields || { title: {}, url: {} };
                  const isRouteActive = isActiveLink(
                    checkUrl(route),
                    location.pathname,
                    match?.params?.sitecoreRoute
                  );
                  if (checkArray(route.fields.items).length) {
                    return (
                      <MobileNavAccordion
                        menuItem={route.fields}
                        key={`${name}_${index}`}
                        isParentActive={isRouteActive}
                        hideMenu={handleMobileClose}
                      />
                    );
                  }
                  return (
                    <li className="navitem__mobile" key={`${name}_${index}`}>
                      <MenuLink
                        url={url}
                        routeName={title}
                        activeLinkClass={
                          isRouteActive
                            ? 'main-nav-link header-link-active'
                            : 'main-nav-link'
                        }
                        clickHandler={pushNavToDataLayer}
                        actionParams={['Top Nav', title?.value]}
                        callback={handleMobileClose}
                      />
                    </li>
                  );
                }
              )}
              <li className="navlink__mobile country">
                {rightComponents.map((component) => {
                  return component?.props?.rendering?.componentName ===
                    'ShowCountryLinksManpower'
                    ? component
                    : '';
                })}
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

SiteHeaderManpower.defaultProps = {
  sticky: true,
  loggedIn: false,
  notFixed: false,
  loginFormSubmitUrl: '/',
  fields: {
    items: [],
    logo: {
      value: {
        src: SiteLogo,
      },
    },
  },
  sitecoreContext: {
    Country: {
      brandName: '',
    },
    route: {
      name: '',
      fields: {},
    },
  },
  showLogin: true,
  rightComponents: [],
  searchTabs: [],
  topMenuComps: [],
};

SiteHeaderManpower.propTypes = {
  rightComponents: PropTypes.arrayOf(PropTypes.shape({})),
  /** Sets the navigation to be visible/fixed. */
  sticky: PropTypes.bool,

  /** Sets the state of the user dropdown */
  loggedIn: PropTypes.bool,

  /** Removes the fixed position. Should not be used outside of Storybook. */
  notFixed: PropTypes.bool,
  loginFormSubmitUrl: PropTypes.string,
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    logo: PropTypes.shape({}),
    mobileLogo: PropTypes.shape({}),
  }),
  sitecoreContext: PropTypes.shape({
    pageEditing: PropTypes.bool,
    Country: PropTypes.shape({
      brandName: PropTypes.string,
    }),
    route: PropTypes.shape({
      name: PropTypes.string,
      fields: PropTypes.shape({}),
    }),
  }),
  showLogin: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({}).isRequired,
  searchTabs: PropTypes.arrayOf(PropTypes.node),
  topMenuComps: PropTypes.arrayOf(PropTypes.node),
};

const containerComp = withSitecoreContext()(
  withPlaceholder([
    { placeholder: 'jss-right', prop: 'rightComponents' },
    { placeholder: 'jss-content', prop: 'searchTabs' },
    { placeholder: 'jss-left', prop: 'topMenuComps' },
  ])(withRouter(SiteHeaderManpower))
);

export default containerComp;
