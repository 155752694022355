/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import './dashboardAccordion.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const DashboardAccordion = ({
  id,
  title,
  noContent,
  content,
  isOpen,
  handleToggle,
}) => {
  /**
   * @description - to handle accordian toggle
   * @param {*} event - click eventി
   * @returns {undefined} - nothing
   */
  const handleToggleClick = (event) => {
    if (event.keyCode === 13) {
      handleToggle();
    }
  };

  return (
    <div className="accordion-section gradient-us manpower dashboard-accordion ">
      <div
        className={
          'accordion-block dashboard-accordion-block-radius  ' +
          (isOpen ? 'active' : '')
        }
        role="listitem"
        id={`accordion${id}`}
      >
        <div
          className="icon"
          onKeyDown={handleToggleClick}
          onClick={handleToggle}
          role="button"
          tabIndex={0}
          aria-label={`toggle accordian ${title?.value}`}
          aria-expanded={isOpen}
        ></div>
        <Text
          field={title}
          tag="h3"
          className="accordion-title"
          onClick={handleToggle}
        />
        <div id="dashboard-accordion" className="accordion-content clearfix">
          {content &&
          content.props.tableData &&
          content.props.tableData.length <= 0 ? (
            <div className="no-content-data">{noContent}</div>
          ) : (
            <>{content}</>
          )}
        </div>
      </div>
    </div>
  );
};

DashboardAccordion.defaultProps = {
  open: true,
  id: '',
};
DashboardAccordion.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default DashboardAccordion;
