/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Loader from '../../../../../core/ErrorBoundary/Loader';
import Error from '../../../../../core/ErrorBoundary/Error';
import { getCandidateDetailsApi } from '../../../../../../services/apiServices/candidateService';
import { clearProfileInfo } from '../../../../../../redux/actions/actions';
import ChangePaymentOption from './ChangePaymentOption';
import CurrentPaymentOptions from './CurrentPaymentOptions';
import { useHistory } from 'react-router-dom';

/**
 * @description - Payment Option Component
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const PaymentOptions = ({ fields, type, t, profileInfo, dispatch }) => {
  const { title, description, descriptionEntity } = fields;
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [descriptionData, setDescriptionData] = useState(null);
  const entity = profileInfo?.PersonalDetails?.data[0]?.bhBranchEntity;
  const [error, setError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (entity !== undefined) {
      if (entity === 'MPUSA' || entity === 'MPCAN') {
        setDescriptionData(description);
      } else {
        setDescriptionData(descriptionEntity);
      }
    }
  }, [entity]);

  useEffect(() => {
    if (profileInfo?.Id) {
      setprofileDataLoading(false);
      if (profileInfo && !profileInfo?.isAssociate) {
        history.push('/en/page-not-found');
      }
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);

  const handleApiRefresh = () => {
    dispatch(clearProfileInfo());
    setTimeout(() => getCandidateDetailsApi({}, dispatch), 500);
  };
  return (
    <section
      className="profile-accordion-section"
      aria-labelledby="title"
      aria-describedby="info"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 pt-5">
            {title?.value && (
              <div className="row pt-2">
                <div className="col-md-12">
                  <Text field={title} tag="h2" className="title-small" id="info" />
                </div>
              </div>
            )}
            {descriptionData?.value && (
              <div className="row py-2">
                <div className="col-md-12">
                  <RichText
                    field={descriptionData}
                    className="description"
                    id="infodesc"
                  />
                </div>
              </div>
            )}
            {profileDataLoading ? (
              <Loader
                loaderMsg={t('fetching-your-data')}
                loaderWithMsg
                customLoaderStyle={{
                  width: '4rem',
                  height: '4rem',
                  color: '#C25700',
                }}
                customTextStyle={{ color: '#C25700' }}
              />
            ) : error ? (
              <div className="apiError">
                <Error
                  errorMsg={t('error-message')}
                  t={t}
                  refreshApi={true}
                  handleApiRefresh={handleApiRefresh}
                />
              </div>
            ) : (
              <div>
                <CurrentPaymentOptions fields={fields} t={t} />
                <ChangePaymentOption fields={fields} t={t} />
              </div>
            )}
            <div className="row my-4">
              <div className="col">
                <p className="user-details-data">
                  {type === 'PayCard'
                    ? `${fields?.payCardModalBottomText?.value}`
                    : `${fields?.directDepositModalBottomText?.value}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

PaymentOptions.defaultProps = {
  t: (val) => val,
  fields: {},
  profileInfo: {},
  dispatch: () => {},
};

PaymentOptions.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(PaymentOptions));
