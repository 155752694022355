import { LOGIN, ADB2C } from './actionTypes';

/**
 * @description - Action to login INITIALIZING.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const initializing = () => {
  return { type: LOGIN.INITIALIZING };
};

/**
 * @description - Action to login INITIALIZED.
 * @returns {Object} - Action.
 */
export const initialized = () => {
  return { type: LOGIN.INITIALIZED };
};

/**
 * @description - Action to login LOGINERROR.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const loginError = () => {
  return { type: LOGIN.LOGINERROR };
};

/**
 * @description - Action to login GETIDTOKENERROR.
 * @returns {Object} - Action.
 */
export const tokenError = () => {
  return { type: LOGIN.GETIDTOKENERROR };
};

/**
 * @description - Action to login LOGOUTSUCCESS.
 * @returns {Object} - Action.
 */
export const logoutSuccess = () => {
  return { type: LOGIN.LOGOUTSUCCESS };
};

/**
 * @description - Action to login AUTHSTATECHANGED.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const authStateChange = (data) => {
  return { type: LOGIN.AUTHSTATECHANGED, payload: data };
};

/**
 * @description - Action to set ADB2C Redirect.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const adb2cRedirect = (data) => {
  return { type: LOGIN.ISREDIRECT, payload: data };
};

/**
 * @description - Action to login GETIDTOKENSUCCESS.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const tokenSuccess = (data) => {
  return { type: LOGIN.GETIDTOKENSUCCESS, payload: data };
};

/**
 * @description - Action to password reset.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const passwordReset = (data) => {
  return { type: LOGIN.PASSWORDRESET, payload: data };
};

/**
 * @description - Action to persist state.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const initializeState = (data) => {
  return { type: LOGIN.PERSISTEDSTATE, payload: data };
};

/**
 * @description - Action to edit profile.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const editProfileStatus = (data) => {
  return { type: LOGIN.EDITPROFILE, payload: data };
};

/**
 * @description - Action to set location.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const setLocation = (data) => {
  return { type: LOGIN.LOCATION, payload: data };
};

/**
 * @description - Action to save adb2c config.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const setADB2CConfig = (data) => {
  return { type: ADB2C.SETCONFIG, payload: data };
};

/**
 * @description - Action to save auth functions.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const setAuthFunctions = (data) => {
  return { type: ADB2C.SETAUTHFUNC, payload: data };
};

/**
 * @description - Action to save show login.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const setShowLogin = (data) => {
  return { type: ADB2C.SHOWLOGIN, payload: data };
};

/**
 * @description - Action to UPDATE SESSION.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const setUpdateSession = (data) => {
  return { type: LOGIN.UPDATESESSION, payload: data };
};

/**
 * @description - Action to update phone.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const updatePhone = (data) => {
  return { type: LOGIN.UPDATEPHONE, payload: data };
};

/**
 * @description - Action to SET CANDIDATE ID.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const setCandidateId = (data) => {
  return { type: LOGIN.CANDIDATEID, payload: data };
};

/**
 * @description - Action to POPUP TRIGGERED.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const isPopupDetailsTriggered = (data) => {
  return { type: LOGIN.ISPOPUPDETAILSTRIGGERED, payload: data };
};

