/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import DashboardAccordion from '../../DashboardAccordion';
import PaymentStubsTable from '../../../../../core/PaymentStubsTable';

/**
 * @description - PaymentStubTable component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const PaymentStubTable = ({ t, fields, tableData }) => {
  const { icon } = fields;
  const [paymentStubData, setPaymentStubData] = useState([]);
  const [loading, setLoading] = useState(null);

  const columns = [
    {
      selector: 'Type',
      name: t('type'),
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.Type}</div>;
      },
    },
    {
      selector: 'Marital',
      name: t('marital'),
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.Marital}</div>;
      },
    },
    {
      selector: 'Exempt',
      name: t('exempt'),
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.Exempt}</div>;
      },
    },
    {
      selector: 'AdditionalWH',
      right: true,
      name: t('additionalWH'),
      grow: 1,
      cell: (row) => {
        return <div className="stubs-content-data">{row?.AdditionalWH}</div>;
      },
    },
  ];
  useEffect(() => {
    setLoading(true);
    prepareData(tableData);
    setLoading(false);
  }, []);

  const prepareData = (responseData) => {
    const data = responseData?.map((object, index) => ({
      ...object,
      id: index + 1,
    }));
    setPaymentStubData(data);
  };

  return (
    <>
      <PaymentStubsTable
        columns={columns}
        icon={icon}
        row={paymentStubData}
        pagination={paymentStubData?.length > 5 ? true : false}
        noHeader={true}
        showHeader={false}
        noTableHead={false}
        progressPending={loading}
        noDataMessage={t('empty-payment-stub-content')}
      />
    </>
  );
};
const FilingInformation = ({ fields, t, profileInfo, tableData }) => {
  const tableDataFilter = tableData.filter((i) => i.Type !== null);
  const { title } = fields;
  const [open, setOpen] = useState(true);

  return (
    <div>
      <DashboardAccordion
        id={title?.value}
        title={title}
        isOpen={open}
        handleToggle={() => setOpen(!open)}
        content={
          <PaymentStubTable
            profileInfo={profileInfo}
            fields={fields}
            t={t}
            tableData={tableDataFilter}
          />
        }
      />
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(FilingInformation));
