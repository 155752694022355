/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Button from '../../../../core/globals/buttons/Button';
import Table from '../../../../core/Table';
import TextLink from '../../../../core/globals/link/TextLink';
import TextButton from '../../../../core/globals/buttons/TextButton';
import { API_STATUS_CODES, CREATE_PROFILE } from '../../../../../constants';
import {
  formObjIteration,
  defineKeyValueObjFromObjectArray,
} from '../../../Utils/helper';
import { dataFetcher } from '../../../../../dataFetcher';
import Modal from '../../../../core/Modal';
import DynamicForm from '../../../../core/Forms/DynamicForm';
import { toast } from 'react-toastify';
// import { mockData } from './mockData';
import { DEFAULT_APPLICATION_COLUMNS } from '../../../../../utils/enums';

/**
 * @description - DashboardApplication component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const DashboardApplications = ({ t, fields, sitecoreContext }) => {
  const { title, icon, subTitle, text, items, isWithdraw, columnSettings } = fields;
  const [jobData, setJobData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [tableRow, selectedTableRow] = useState('');
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [jobSubmissionLoading, setJobSubmissionLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const tempArray =
    columnSettings?.length > 0
      ? Object.values(defineKeyValueObjFromObjectArray(columnSettings))
      : DEFAULT_APPLICATION_COLUMNS;

  const { register, handleSubmit, getValues, errors, setValue, control } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
  });

  useEffect(() => {
    getCandidateApplications();
  }, []);

  /**
   * @description - Call To get applcations
   * @param {object} queryParams - request body
   * @returns {undefined}
   */
  const getCandidateApplications = () => {
    const samplePath = window.location.pathname;

    const queryParams = {
      entity:
        samplePath === '/en/candidate/my-assignments'
          ? 'Placement'
          : 'CandidateJobSubmission',
      forceUpdate: false,
    };
    dataFetcher(
      `${CREATE_PROFILE.GET_CREATE_PROFILE.CANDIDATE_DETAILS}`,
      'GET',
      {},
      queryParams
    ).then(
      (response) => {
        if (response?.data?.status === API_STATUS_CODES.SUCCESS)
          prepareData(response.data.data);
        else {
          setprofileDataLoading(false);
          setShowError(true);
        }
      },
      () => {
        setprofileDataLoading(false);
        setShowError(true);
      }
    );
    //prepareData(mockData);
  };

  const columns = [
    {
      selector: 'dateAdded',
      name: t('date-applied'),
      grow: 1.5,
      sortable: true,
    },
    {
      selector: 'position',
      name: t('position'),
      grow: 1.5,
      sortable: true,
    },
    {
      selector: 'companyName',
      name: t('company'),
      grow: 1.5,
      sortable: true,
    },
    {
      selector: 'employmentType',
      name: t('employment-type'),
      grow: 2.3,
      sortable: true,
    },
    {
      selector: 'status',
      name: t('status'),
      grow: 1,
      sortable: true,
      /**
       * @description Function to generate Application Status template.
       * @param {Object} row - Job details.
       * @returns {Node} HTML Template.
       */
      cell: function applicationStatus(row) {
        return (
          <div className="status-block" style={{ color: `${row?.statusColour}` }}>
            {row.status}
          </div>
        );
      },
    },
    {
      selector: 'path',
      name: '',
      grow: isWithdraw?.value ? 2.5 : 2,
      /**
       * @description Function to generate Action column template.
       * @param {Object} row - Job details.
       * @returns {Node} HTML Template.
       */
      cell: function actionColumn(row) {
        return (
          <div className="view-or-remove">
            <TextLink
              ctaText={t('view')}
              ctaLabel="Application view opens in a new window/tab"
              cssClass="primary"
              ctaUrl={row?.jobURL}
            />
            {row?.isWithdrawable === true && isWithdraw?.value === true && (
              <>
                <div className="vertical-seperator" />
                <TextButton
                  cssClass="light-gray"
                  text={t('withdraw')}
                  ctaLabel={`${t('withdraw')} pop-up box opens`}
                  handleTextClick={() => handleWithdrawClick(row)}
                />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const newColumns =
    tempArray.length > 0
      ? columns.filter((item) => tempArray.includes(item.selector))
      : columns;

  /**
   * @description - Handle Modal Close.
   * @returns {undefined}
   */
  const handleModal = () => {
    setShowModal(false);
  };

  /**
   * @description - Handle Widthdraw Click.
   * @param {Object} row - Job details.
   * @returns {undefined}
   */
  const handleWithdrawClick = (row) => {
    selectedTableRow(row);
    setShowModal(true);
  };

  /**
   * @description - Withdraw application Submit handler.
   * @param {Object} formData - Form Data.
   * @returns {undefined}
   */
  const onSubmit = (formData) => {
    setJobSubmissionLoading(true);
    const postData = {
      id: tableRow?.jobDataId,
      candidateId: tableRow?.candidateId,
      jobId: tableRow?.jobId,
      jobItemId: tableRow?.jobItemId,
      ...formData,
    };

    dataFetcher(
      `${CREATE_PROFILE.POST_CREATE_PROFILE.JOB_APPLY_WITHDRAW}`,
      'POST',
      postData
    ).then(
      (response) => {
        if (response.data && response.data?.status === API_STATUS_CODES.SUCCESS) {
          tableRow.status = t(response?.data?.data?.status);
          tableRow.statusColour = t(response?.data?.data?.statusColor);
          tableRow.isWithdrawable = false;
          setShowModal(false);
          toast.success(fields?.message?.value || t('api-success-message'));
        } else {
          toast.error(response?.data?.errorMessage || t('check-back-later'));
        }
        setJobSubmissionLoading(false);
      },
      () => {
        setprofileDataLoading(false);
        setShowModal(false);
        toast.error(t('check-back-later'));
        setJobSubmissionLoading(false);
      }
    );
  };

  /**
   * @description - Function for transforming formdata.
   * @param {func} onSubmitFunc - Form submit function as input.
   * @returns {*} - Calls Onsubmit function.
   */
  const transform = (onSubmitFunc) => (data) => {
    const transformData = formObjIteration(data);
    onSubmitFunc(transformData);
  };

  /**
   * @description prepare data for Current application
   * @param {Object} applicationData - api response
   * @returns {undefined} - no return
   */
  const prepareData = (applicationData) => {
    const candidateApplication = {
      id: applicationData?.Id,
      data: [],
    };
    applicationData &&
      applicationData.JobDetails &&
      applicationData.JobDetails.data &&
      applicationData.JobDetails.data.map((job, index) => {
        candidateApplication.data[index] = {
          id: index,
          jobDataId: job?.id,
          candidateId: job?.candidate?.id,
          dateAdded: job.dateAdded,
          jobId: job?.jobOrder?.id,
          jobItemId: job?.jobOrder?.jobItemId,
          jobURL: job?.jobOrder?.jobUrl || sitecoreContext?.Country?.jobSearchURL,
          position: job?.jobOrder?.position,
          companyName: job?.jobOrder?.companyName,
          employmentType: job?.jobOrder?.employmentType,
          status: job?.status ? job.status : '',
          statusColour: job?.statusColour ? job.statusColour : '',
          isWithdrawable: job?.isWithdraw,
        };
      });
    setprofileDataLoading(false);
    setJobData(candidateApplication);
  };

  return (
    <div className="dashboard-block normal custom-table currentApplication">
      <Table
        columns={newColumns}
        icon={icon}
        row={jobData?.data}
        pagination={jobData?.data?.length > 5 ? true : false}
        title={title}
        noHeader={false}
        count={jobData?.data?.length}
        noTableHead={false}
        progressPending={profileDataLoading}
        noDataMessage={t('empty-current-jobs')}
        error={showError}
        handleRefresh={() => {
          setShowError(false);
          setprofileDataLoading(true);
          setTimeout(() => getCandidateApplications(), 500);
        }}
      />
      <Modal
        showModal={showModal}
        handleModal={handleModal}
        size="lg"
        className="application-modal"
      >
        <div className="main-container" key="body">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <h4 className="title">{subTitle?.value}</h4>
              </div>
              <div className="col-xl-10 offset-xl-1">
                <div className="subTitle">{text?.value}</div>
              </div>
              <div className="col-xl-8 offset-xl-2">
                <DynamicForm
                  formData={items}
                  register={register}
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  control={control}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container" key="footer">
          <Button
            handleButtonClick={handleModal}
            text={t('cancel-withdrawal')}
            cssClass="cancel-button"
            isDisabled={jobSubmissionLoading}
          ></Button>
          <TextButton
            cssClass="blue"
            text={t('withdraw-application')}
            handleTextClick={handleSubmit(transform(onSubmit))}
            isLoading={jobSubmissionLoading}
            isDisabled={jobSubmissionLoading}
          />
        </div>
      </Modal>
    </div>
  );
};

DashboardApplications.defaultProps = {
  fields: {
    title: { value: 'CURRENT APPLICATIONS' },
    icon: { value: '' },
    isWithdraw: {
      value: true,
    },
  },
  t: (val) => val,
};

DashboardApplications.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(DashboardApplications);
