import React from 'react';
import PropTypes from 'prop-types';

import './boundaries.scss';
import { withTranslation } from 'react-i18next';

/**
 * @description - Loader component;
 * @param {*} param0 - site params;
 * @returns{Node} - return html block;
 */
const Loader = ({
  loaderMsg,
  loaderWithMsg = false,
  t,
  customLoaderStyle = {},
  customTextStyle = {},
}) =>
  loaderWithMsg ? (
    <div className="container-fluid customLoader">
      <div className="row justify-content-center">
        <div className="spinner-border" style={customLoaderStyle} role="status">
          <span className="sr-only">{t(loaderMsg)}</span>
        </div>
      </div>
      <div className="row justify-content-center" style={customTextStyle}>
        <strong>{t(loaderMsg)}</strong>
      </div>
    </div>
  ) : (
    <div className="container-fluid text-center loader">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">{t(loaderMsg)}</span>
      </div>
    </div>
  );

Loader.propTypes = {
  loaderMsg: PropTypes.string,
  loaderWithMsg: PropTypes.bool,
  customLoaderStyle: PropTypes.shape({}),
  customTextStyle: PropTypes.shape({}),
};

Loader.defaultProps = {
  loaderMsg: 'Loading',
  loaderWithMsg: false,
  customLoaderStyle: {},
  customTextStyle: {},
};

export default withTranslation()(Loader);
