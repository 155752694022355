import React, { useEffect } from 'react';
import Loader from '../ErrorBoundary/Loader';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './stub-table.scss';
import Error from '../ErrorBoundary/Error';

/**
 * @description - Table Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const PaymentStubsTable = (props) => {
  const {
    t,
    row,
    columns,
    noTableHead,
    count,
    title,
    icon,
    sitecoreContext,
    progressPending,
    pagination = false,
    paginationPerPage,
    noDataMessage,
    paginationOptions = {
      rowsPerPageText: t('rows-per-page'),
      rangeSeparatorText: t('of'),
      selectAllRowsItem: true,
      selectAllRowsItemText: t('select-all'),
    },
    paginationServer,
    paginationTotalRows,
    onChangePage,
    showHeader = true,
    error,
    handleRefresh = () => {},
  } = props;

  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';

  useEffect(() => {
    Array.isArray(columns) &&
      columns.forEach((column) => {
        const label = `column-${column.selector}`;
        const element = document.getElementById(label);
        if (element) {
          element.setAttribute('aria-sort', 'descending');
        }
      });
  }, [columns]);

  useEffect(() => {
    const element = document.getElementsByClassName('rdt_TableCell');
    const noData = document.getElementsByClassName('no-data');
    if (noData && noData.length > 0) {
      for (let i = 0; i < noData.length; i++) {
        noData[i].setAttribute('role', 'none');
        noData[i].setAttribute('tabindex', '0');
      }
    }
    setTimeout(() => {
      if (element && element.length > 0) {
        for (let i = 0; i < element.length; i++) {
          element[i].setAttribute('role', 'cell');
        }
      }
    }, 0);
  }, [row]);

  /**
   * @description - to read sort order by screen reader
   * @param {*} column
   * @param {*} sortOrder
   */
  const onSort = (column, sortOrder) => {
    const label = `column-${column.selector}`;
    const element = document.getElementById(label);
    if (element) {
      element.setAttribute(
        'aria-sort',
        `${sortOrder === 'asc' ? 'ascending' : 'descending'}`
      );
    }
  };

  const customStyles = {
    headCells: {
      style: {
        display: 'inline-flex',
      },
    },
    cells: {
      style: {
        display: 'inline-flex',
      },
    },
  };

  return (
    <React.Fragment>
      {showHeader && (
        <div className={'table-header-block ' + brandName}>
          {icon?.value?.src && <img src={icon?.value?.src} alt={icon?.value?.alt} />}
          <h2>
            {title?.value} {count ? `(${count})` : ''}
          </h2>
        </div>
      )}

      {error ? (
        <div className="error-container-table">
          <Error handleApiRefresh={handleRefresh} refreshApi={true} />
        </div>
      ) : (
        <DataTable
          className="custom-table-wrapper payment-stub-table"
          columns={columns}
          paginationPerPage={paginationPerPage || 20}
          paginationRowsPerPageOptions={[5, 10, 20, 50]}
          data={row}
          customStyles={customStyles}
          striped={true}
          noTableHead={noTableHead}
          pagination={pagination}
          paginationComponentOptions={paginationOptions}
          progressComponent={
            <Loader
              loaderMsg={t('fetching-your-data')}
              loaderWithMsg
              customLoaderStyle={{
                width: '4rem',
                height: '4rem',
                color: '#C25700',
              }}
              customTextStyle={{ color: '#C25700' }}
            />
          }
          noDataComponent={
            <div className="no-data">{noDataMessage || t('no-data-exists')}</div>
          }
          progressPending={progressPending}
          paginationServer={paginationServer}
          paginationTotalRows={paginationTotalRows}
          onChangePage={onChangePage}
          onSort={(column, sortOrder) => onSort(column, sortOrder)}
        />
      )}
    </React.Fragment>
  );
};

PaymentStubsTable.propTypes = {
  t: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string,
      text: PropTypes.string,
      sort: PropTypes.bool,
      style: PropTypes.shape(),
      sortCaret: PropTypes.string,
    })
  ),
  row: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  bordered: PropTypes.bool,
  noTableHead: PropTypes.bool,
  onRowCLick: PropTypes.func,
  count: PropTypes.number,
  img: PropTypes.string,
  sitecoreContext: PropTypes.shape({}),
};

PaymentStubsTable.defaultProps = {
  t: (val) => val,
  columns: [],
  row: [],
  bordered: false,
  noTableHead: false,
  onRowCLick: () => {},
  sitecoreContext: {},
  error: false,
};

export default withSitecoreContext()(withTranslation()(PaymentStubsTable));
