/* eslint-disable require-jsdoc */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import { withTranslation } from 'react-i18next';
import AssignmentContent from './AssignmentContent';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Loader from '../../../../core/ErrorBoundary/Loader';

const UserAssignments = ({
  t,
  assignments,
  profileDataLoading,
  sitecoreContext,
  noContent
}) => {
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  return (
    <>
      {profileDataLoading ? (
        <Loader
          loaderMsg={t('fetching-your-data')}
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#C25700',
          }}
          customTextStyle={{ color: '#C25700' }}
        />
      ) : assignments && assignments.length > 0 ? (
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 px-4">
                {assignments.map((item, index) => {
                  return (
                    <AssignmentContent
                      key={index}
                      item={item}
                      countryName={countryName}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="no-content-data">
           {noContent?.value === "Past Assignments"
            ? t(`no-past-assignment-data`) :
            noContent?.value === "Upcoming Assignments"
            ? t(`no-upcoming-assignment-data`) : 
            noContent?.value === "Current Assignments"
            ? t(`no-current-assignment-data`) : 
             t(`no-assignment-data`)}
        </div>
      )}
    </>
  );
};

UserAssignments.defaultProps = {
  sitecoreContext: {},
};

export default withSitecoreContext()(withTranslation()(UserAssignments));
