/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withTranslation } from 'react-i18next';
import { dropDownFormat } from './helper/dataFactory';
import DateInputField from '../FormFields/DateInputField';
import Textbox from '../FormFields/Textbox';
import LookUpWithTextbox from '../FormFields/GoogleApiField/LookUpWithTextbox';
import TextareaComplex from '../FormFields/Textarea';
import UploadBlock from '../FormFields/UploadBlock';
import UploadBlockDocument from '../FormFields/UploadBlock/UploadBlockDocument';
import SelectComplex from '../FormFields/SelectComplex';
import { SORTORDER } from '../../../../utils/enums';
import RadioToggle from '../FormFields/RadioToggle';
import Checkbox from '../FormFields/Checkbox';
import CheckboxBoolean from '../FormFields/CheckboxBoolean';
import DatePickerField from '../FormFields/DatePickerField';
import SkillsPicker from '../FormFields/SkillsPicker';
import MonthYearDropdown from '../FormFields/MonthYearDropdown';
import './dynamicForm.scss';
import ContactPreference from '../FormFields/ContactPreference';
import JobPriorities from '../FormFields/JobPriorities';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import LookUpWithTextboxMailingLocation from '../FormFields/GoogleApiField/LookUpWithTextboxMailingLocation';
import TextNumberbox from '../FormFields/TextNumberbox';

/**
 * @description - Dynamic form component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const DynamicForm = ({
  formData,
  register,
  unregister,
  errors,
  setError,
  clearErrors,
  customIndex,
  getValues,
  setValue,
  trigger,
  values = {},
  watch,
  handleSelect = () => {},
  handleUpload = () => {},
  handleDelete = () => {},
  control,
  formName,
  validateOnLoad,
  APIQuerryParams,
  readOnlyFields,
  fieldsToHide,
  apiCollection,
  apiList,
  handleChange,
  contentLoading,
  formState,
  reset,
  t,
  sitecoreContext = {},
}) => {
  const [formValues, setFormValues] = useState({});
  const [apiData, setApiData] = useState({});
  const countryName = sitecoreContext?.Country?.name;

  /**
   * @description - Function to calculate width of field.
   * @param {string} width - Custom width.
   * @returns {string} - Field width.
   */
  const getWidth = (width) => (width && width !== '' ? width + '%' : '100%');

  useEffect(() => {
    if (values?.data !== formValues) setFormValues({ ...values?.data });
  }, [values?.data]);

  useEffect(() => {
    setApiData(apiCollection);
  }, [apiCollection]);

  /**
   * @description Function to get data for dropdown from API.
   * @param {boolean} isApi - Whether api required or not.
   * @param {Array} formValues - Default values from form if not to be taken from api.
   * @param {string} type - Type of dropdown.
   * @param {string} dateFormat - Date format.
   * @returns {Array} - Formatted data.
   */
  const getData = (isApi = false, formValues, type, dateFormat = 'LL') => {
    let options = [];
    if (!isApi) {
      options = dropDownFormat(formValues, type, 0, 0, '', dateFormat);
    }
    return options;
  };

  /**
   * @description - Get APi
   * @param {string} apiEndPoint - endpoint
   * @returns {string} - Formatted data.

   */
  const getApi = (apiEndPoint) => {
    let api = apiEndPoint;
    if (
      Object.keys(APIQuerryParams).length > 0 &&
      apiEndPoint &&
      apiEndPoint !== null
    ) {
      Object.keys(APIQuerryParams).forEach((param) => {
        api = api.replace(`{${param}}`, `${param}=${APIQuerryParams[param]}`);
      });
    }
    return api;
  };

  /**
   * @description Function to get Field Name.
   * @param {string} fieldName - Field name.
   * @returns {string} fieldName.
   */
  const getFieldName = (fieldName) => {
    if (formName && customIndex !== null) {
      return `${formName}[${customIndex}][${fieldName}]`;
    } else if (formName) {
      return `${formName}[${fieldName}]`;
    }
    return fieldName;
  };

  /**
   * @description Function to get Form Name.
   * @returns {string} formName.
   */
  const getFormName = () => {
    let result = '';
    if (formName && customIndex !== null) {
      result = `${formName}[${customIndex}]`;
    } else if (formName) {
      result = formName;
    }
    return result;
  };

  /**
   * @description - Function to get Field details.
   * @param {Object} form - Form Fields data.
   * @returns {Object} - Form Field Props.
   */
  const getFormProps = (form) => {
    const displayField =
      form?.fields?.inputType?.value === 'Hidden' ? { display: 'none' } : {};
    // form?.fields?.inputType?.value === 'Hidden' ||
    // (form?.fields?.groupName?.value === 'MailingAddress' &&
    //   checkBox16 &&
    //   isNorthAmericanCountry(countryNameUsCa))
    //   ? { display: 'none' }
    //   : {};

    const removePlusFromPhoneNumberForItaly =
      countryName === 'Italy' && form?.name === 'Phone';

    return {
      name: getFieldName(form?.fields?.name?.value),
      formName: getFormName(),
      fieldName: form?.fields?.name?.value,
      id: customIndex !== null ? `${form?.id}_${customIndex}` : form?.id,
      key: customIndex !== null ? `${form?.id}_${customIndex}` : form?.id,
      placeholder: form?.fields?.placeholderText?.value,
      label: form?.fields?.label?.value,
      text: form?.fields?.text,
      readOnly: form?.fields?.readonly?.value
        ? form?.fields?.readonly?.value
        : readOnlyFields && readOnlyFields.includes(form?.fields?.name?.value),
      disableIfDataExist: form?.fields?.disableIfDataExist?.value,

      value: removePlusFromPhoneNumberForItaly
        ? (
            form?.fields?.value?.value ||
            get(formValues, form?.fields?.name?.value) ||
            ''
          ).replace(/\+39|(\+)/, '')
        : form?.fields?.value?.value || get(formValues, form?.fields?.name?.value),

      customStyle: {
        width: getWidth(form?.fields?.width?.value),
        ...displayField,
      },
      dataValidations: form?.fields?.Validations,
      connectorField: form?.fields?.connectorField?.value,
      connectorType: form?.fields?.connectorType?.value,
      connectorMessage: form?.fields?.connectorMessage?.value,
      connectorPattern: form?.fields?.connectorPattern?.value,
      dateFormat: form?.fields?.dateFormat?.value,
      isHideField: form?.fields?.isHideField?.value
        ? form?.fields?.isHideField?.value
        : fieldsToHide && fieldsToHide.includes(form?.fields?.name?.value),
      showBasedOnFieldName: form?.fields?.showBasedOnFieldName?.value,
      hideBasedOnFieldName: form?.fields?.hideBasedOnFieldName?.value,
      className: form?.fields?.name?.value,
      requiredValidation: form?.fields?.CustomRequiredValidator,
      isApi: form?.fields?.isApi?.value,
      subType: form?.fields?.subType?.value,
      apiEndPoint: getApi(form?.fields?.apiEndPoint?.value),
      isMulti: form?.fields?.isMultiple?.value,
      hintText: form?.fields?.hintText?.value,
      list: form?.fields?.values,
      clearErrors: clearErrors,
      text: form?.fields?.text,
      hasApiCollection: apiList?.includes(form?.fields?.apiEndPoint?.value),
      apiData: apiData?.[getApi(form?.fields?.apiEndPoint?.value)],
      register: register,
      control: control,
      customError: errors,
      getValues: getValues,
      setValue: setValue,
      watch: watch,
      validateOnLoad: validateOnLoad,
      isSearchEnabled: false,
      onEnter: false,
      trigger: trigger,
      isClearable: true,
      isSearchable: false,
      setError: setError,
      handleChange: handleChange,
      contentLoading: contentLoading,
      formState: formState,
      reset: reset,
      t: t,
      unregister: unregister,
      sitecoreContext: sitecoreContext,
      maxLength: form?.fields?.maxLength?.value,
    };
  };

  return (
    <div className="dynamic-form">
      <div className="normalForm">
        {formData.map((form) => {
          const formProps = getFormProps(form);
          switch (form?.fields?.inputType?.value) {
            case 'Text':
              return <Textbox type="text" {...formProps} />;
            case 'Number':
              return <TextNumberbox type="number" {...formProps} />;
            case 'Hidden':
              return <Textbox type="hidden" {...formProps} />;
            case 'Password':
              return <Textbox type="password" {...formProps} />;
            case 'Email':
              return <Textbox type="email" {...formProps} />;
            case 'MultiLineText':
              return <TextareaComplex {...formProps} />;
            case 'Date':
              return (
                <DateInputField
                  {...formProps}
                  format={form?.fields?.format?.value}
                />
              );
            case 'NormalDropdown':
            case 'DateDropdown':
            case 'NumberDropdown':
              return (
                <SelectComplex
                  {...formProps}
                  options={
                    form?.fields?.inputType?.value === 'NumberDropdown'
                      ? dropDownFormat(
                          [],
                          'NumberDropdown',
                          form.fields?.start.value !== ''
                            ? parseInt(form.fields?.start?.value)
                            : 0,
                          form.fields?.incre.value !== ''
                            ? parseInt(form.fields?.incre?.value)
                            : 0,
                          form.fields?.sortOrder?.value &&
                            form.fields?.sortOrder?.value !== '' &&
                            form.fields?.sortOrder?.value !== null
                            ? form.fields?.sortOrder?.value?.toUpperCase()
                            : SORTORDER.ASCENDING
                        )
                      : getData(
                          form?.fields?.isApi?.value,
                          form?.fields?.values,
                          form?.fields?.inputType?.value,
                          form?.fields?.dateFormat?.value
                        )
                  }
                />
              );
            case 'MonthYearDropdown':
              return (
                <MonthYearDropdown
                  {...formProps}
                  monthList={getData(
                    form?.fields?.isApi?.value,
                    form?.fields?.values,
                    'NormalDropdown',
                    form?.fields?.dateFormat?.value
                  )}
                  yearList={dropDownFormat(
                    [],
                    'NumberDropdown',
                    form.fields?.start.value !== ''
                      ? parseInt(form.fields?.start?.value)
                      : 0,
                    form.fields?.incre.value !== ''
                      ? parseInt(form.fields?.incre?.value)
                      : 0,
                    form.fields?.sortOrder?.value &&
                      form.fields?.sortOrder?.value !== '' &&
                      form.fields?.sortOrder?.value !== null
                      ? form.fields?.sortOrder?.value?.toUpperCase()
                      : SORTORDER.ASCENDING
                  )}
                  incrementValue={
                    form.fields?.incre.value && form.fields?.incre.value !== null
                      ? parseInt(form.fields?.incre?.value)
                      : 0
                  }
                />
              );
            case 'TypeaheadDropdown':
              return (
                <SelectComplex
                  {...formProps}
                  options={getData(
                    form?.fields?.isApi?.value,
                    form?.fields?.values,
                    'TypeaheadDropdown'
                  )}
                  isSearchable={true}
                  handleSelect={(selected) => handleSelect(selected)}
                />
              );
            case 'FileUpload':
              return (
                <UploadBlock
                  {...formProps}
                  downloadApiEndPoint={getApi(
                    form?.fields?.downloadApiEndPoint?.value
                  )}
                  optionsData={form?.fields?.values}
                  handleUpload={handleUpload}
                  handleDelete={handleDelete}
                  isMultifiles={form?.fields?.isMultifiles?.value}
                  showOnload={form?.fields?.showOnload?.value}
                  toolTipText={form?.fields?.toolTip?.value}
                  limit={
                    form?.fields?.maxFileCount?.value
                      ? parseInt(form?.fields?.maxFileCount?.value)
                      : 5
                  }
                  filterReq={form?.fields?.isAttachmentFilterRequired?.value}
                  value={
                    form?.fields?.isAttachmentFilterRequired?.value
                      ? values?.attachment
                      : get(formValues, form?.fields?.name?.value)
                  }
                  fileTypes={form?.fields?.fileTypes?.value}
                  isHardDelete={form?.fields?.isHardDelete?.value}
                />
              );
            case 'RadioButton':
              return (
                <RadioToggle
                  {...formProps}
                  options={dropDownFormat(form?.fields?.values, 'radio')}
                  inlineLabel={
                    form?.fields?.labelPosition?.value &&
                    form?.fields?.labelPosition?.value?.toLowerCase() === 'inline'
                  }
                  toggleStyle={{
                    flexDirection:
                      form?.fields?.position?.value &&
                      form?.fields?.position?.value === 'horizontal'
                        ? 'row'
                        : 'column',
                  }}
                  showLabelText
                />
              );
            case 'CheckBox':
              return <Checkbox {...formProps} />;
            case 'ContactPreference':
              return (
                <ContactPreference
                  formProps={formProps}
                  form={form}
                  register={register}
                  options={dropDownFormat(
                    form?.fields?.values,
                    'MultiCheckboxContactPreference'
                  )}
                />
              );
            case 'CheckBoxBoolean':
              return <CheckboxBoolean {...formProps} />;
            case 'DatePicker':
              return (
                <DatePickerField
                  {...formProps}
                  locale={form?.fields?.locale?.value}
                  maxDate={form?.fields?.maxDate?.value}
                  minDate={form?.fields?.minDate?.value}
                />
              );
            case 'Picker':
              return (
                <SkillsPicker
                  {...formProps}
                  isSearchable={true}
                  titleText={form?.fields?.hintText?.value}
                  hintText=""
                  options={getData(
                    form?.fields?.isApi?.value,
                    form?.fields?.values,
                    'TypeaheadDropdown'
                  )}
                />
              );
            case 'JobPriorities':
              return (
                <JobPriorities
                  formProps={formProps}
                  form={form}
                  register={register}
                  options={dropDownFormat(form?.fields?.values, 'JobPriorities')}
                />
              );
            case 'LookupWithGoogleApi':
              return (
                <LookUpWithTextbox
                  {...formProps}
                  lookupField={form?.fields?.lookupField}
                  value={
                    form?.fields?.value?.value ||
                    get(formValues, form?.fields?.name?.value)
                  }
                  formValues={formValues}
                  showCurrentLocation={form?.fields?.showCurrentLocation?.value}
                  countryCodes={form?.fields?.countryCodes?.value}
                />
              );
            case 'LookupWithGoogleApiUS': //NA specific form field
              return (
                <LookUpWithTextbox
                  {...formProps}
                  lookupField={form?.fields?.lookupField}
                  value={
                    form?.fields?.value?.value ||
                    get(formValues, form?.fields?.name?.value)
                  }
                  formValues={formValues}
                  showCurrentLocation={form?.fields?.showCurrentLocation?.value}
                  countryCodes={form?.fields?.countryCodes?.value}
                />
              );
            case 'LookupWithGoogleApiUSMailingLocation':
              return (
                <LookUpWithTextboxMailingLocation
                  {...formProps}
                  lookupField={form?.fields?.lookupField}
                  value={
                    form?.fields?.value?.value ||
                    get(formValues, form?.fields?.name?.value)
                  }
                  formValues={formValues}
                  showCurrentLocation={form?.fields?.showCurrentLocation?.value}
                  countryCodes={form?.fields?.countryCodes?.value}
                />
              );
            default:
              return '';
          }
        })}
      </div>
      <div className="document-container">
        {formData.map((form) => {
          const formProps = getFormProps(form);
          switch (form?.fields?.inputType?.value) {
            case 'FileUploadWithDocType':
              return (
                <UploadBlockDocument
                  {...formProps}
                  secondaryLabel={form?.fields?.secondaryLabel?.value}
                  apiEndPoint={getApi(form?.fields?.apiEndPoint?.value)}
                  downloadApiEndPoint={getApi(
                    form?.fields?.downloadApiEndPoint?.value
                  )}
                  optionsData={form?.fields?.values}
                  handleUpload={handleUpload}
                  handleDelete={handleDelete}
                  toolTipText={form?.fields?.toolTip?.value}
                  limit={form?.fields?.maxFileCount?.value}
                  value={values?.attachment}
                  fileTypes={form?.fields?.fileTypes?.value}
                />
              );
            default:
              return '';
          }
        })}
      </div>
    </div>
  );
};
DynamicForm.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  customIndex: PropTypes.number,
  getValues: PropTypes.func.isRequired,
  validateOnLoad: PropTypes.bool,
  setValue: PropTypes.func,
  trigger: PropTypes.func,
  reset: PropTypes.func,
  values: PropTypes.shape({}),
  APIQuerryParams: PropTypes.shape({}),
  watch: PropTypes.func,
  handleSelect: PropTypes.func,
  handleUpload: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDownload: PropTypes.func,
  downloading: PropTypes.bool,
  control: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  isMultiFiles: PropTypes.bool,
  formName: PropTypes.string,
  fileArray: PropTypes.arrayOf(PropTypes.shape({})),
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  fieldsToHide: PropTypes.arrayOf(PropTypes.string),
  apiCollection: PropTypes.shape({}),
  apiList: PropTypes.string,
  handleChange: PropTypes.func,
  contentLoading: PropTypes.func,
  formState: PropTypes.shape({}),
  t: PropTypes.func,
  sitecoreContext: PropTypes.shape({}),
};

DynamicForm.defaultProps = {
  formData: [],
  errors: {},
  setError: () => {},
  clearErrors: () => {},
  register: () => {},
  unregister: () => {},
  isMultiFiles: false,
  validateOnLoad: false,
  customIndex: null,
  handleSelect: () => {},
  handleUpload: () => {},
  handleDelete: () => {},
  handleDownload: () => {},
  watch: () => {},
  control: () => {},
  reset: () => {},
  setValue: () => {},
  trigger: () => {},
  values: {},
  formName: '',
  fileArray: [],
  downloading: false,
  APIQuerryParams: {},
  readOnlyFields: [],
  fieldsToHide: [],
  apiCollection: {},
  apiList: '',
  handleChange: () => {},
  contentLoading: () => {},
  formState: {},
  t: () => {},
  sitecoreContext: {},
};

export default withSitecoreContext()(withTranslation()(DynamicForm));
