/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../core/globals/buttons/Button';
import Modal from '../../../core/Modal';
import axios from 'axios';
import { logoutSuccess } from '../../../../redux/actions/loginActions';
import { APPLICATION_LOGOUT } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';

const RouteLeavingGuard = ({
  when,
  t,
  navigate,
  shouldBlockNavigation,
  handleChange,
}) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const authReducer = useSelector((state) => state.authFunctionsReducer);
  const closeModal = () => {
    sessionStorage.removeItem('isRouteLeavingGuardActive');
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      sessionStorage.setItem('isRouteLeavingGuardActive', true);
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    if (sessionStorage.getItem('isLogoutClick') === 'true') {
      authReducer.logout();
      sessionStorage.removeItem('isLogoutClick');
    }
    closeModal();
    handleChange(false);
    setConfirmedNavigation(true);
  };

  const handleNoClick = () => {
    if (sessionStorage.getItem('isLogoutClick') === 'true') {
      history.back();
      // history.replaceState(null, '', window.location.href.replace(/#$/, ''));
      sessionStorage.removeItem('isLogoutClick');
    }
    closeModal();
  };
  useEffect(() => {
    if (!modalVisible) {
      sessionStorage.removeItem('isLogoutClick');
    }
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal showModal={modalVisible} handleModal={closeModal} size="md">
        <div className="inner-modal-container" key="body">
          {t('you-will-loose-do-you-wish-to-continue')}
          <div className="confirm-button-container">
            <Button
              cssClass="yes-button"
              handleButtonClick={handleConfirmNavigationClick}
              text={t('yes')}
            />
            <Button handleButtonClick={handleNoClick} text={t('no')} />
          </div>
        </div>
      </Modal>
    </>
  );
};

RouteLeavingGuard.defaultProps = {
  when: false,
  t: () => {},
  shouldBlockNavigation: () => {},
  navigate: () => {},
};

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool,
  t: PropTypes.func.isRequired,
  shouldBlockNavigation: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default RouteLeavingGuard;
