/* eslint-disable require-jsdoc */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DashboardAccordion from '../../DashboardAccordion';
import { isNullorEmpty, maskNumber } from '../../../../../../utils/helperUtils';
import { mediaApi, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - Current Payment Details Component
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */

const CurrentPayment = ({ t, sitecoreContext, icons }) => {
  const profileInfoReducer = useSelector((state) => state.profileInfoReducer);
  const userData = profileInfoReducer?.data?.PersonalDetails?.data[0];
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  const profileUrl =
    sitecoreContext &&
    sitecoreContext?.route?.placeholders['jss-main'][0].placeholders[
      'jss-content'
    ][1].fields?.profileUrl.value;
  return (
    <>
      {userData && userData?.customText33 ? (
        <div className="current-payment">
          <div className="row">
            <div className="col-md-4">
              <h5 className="title pb-1">{t('payment-method')}</h5>
              <p className="sub-title">{userData?.paymentMethod}</p>
            </div>
            {userData && userData?.customText33 === 'Direct Deposit' ? (
              <div className="col-md-4">
                <h5 className="title pb-1">{t('transit-routing')}</h5>
                {countryName !== 'ca' ? (
                  <p className="sub-title">
                    {isNullorEmpty(userData?.customEncryptedText1)
                      ? '-'
                      : maskNumber(userData?.customEncryptedText1, -4, 'X')}
                  </p>
                ) : (
                  <p className="sub-title">
                    {isNullorEmpty(userData?.customEncryptedText3)
                      ? '-'
                      : maskNumber(userData?.customEncryptedText3, -4, 'X')}
                  </p>
                )}
              </div>
            ) : null}
            {userData && userData?.customText33 === 'Direct Deposit' ? (
              <div className="col-md-4">
                {countryName !== 'ca' ? (
                  <div>
                    <h5 className="title pb-1">{t('account-type')}</h5>
                    <p className="sub-title">{userData.customText34}</p>
                  </div>
                ) : (
                  <div>
                    <h5 className="title pb-1">{t('bank-code')}</h5>
                    <p className="sub-title">{userData.customEncryptedText4}</p>
                  </div>
                )}
              </div>
            ) : userData &&
              userData?.MailingAddress?.zip &&
              userData?.customText16 !== 'true' ? (
              <div className="col-md-8">
                <p className="title pb-1">{t('mailing-address')}</p>
                <div className="location-payment">
                  <span className="search-icon-payment"></span>
                  <p className="location-item">
                    {userData?.MailingAddress?.address1 &&
                      `${userData?.MailingAddress?.address1}`}
                    {userData?.MailingAddress?.address1 && <br />}
                    {userData?.MailingAddress?.address2 &&
                      `${userData?.MailingAddress?.address2}`}
                    {userData?.MailingAddress?.address2 && <br />}
                    {userData?.MailingAddress?.city &&
                      `${userData?.MailingAddress?.city},`}
                    {userData?.MailingAddress?.state &&
                      `${userData?.MailingAddress?.state} `}
                    {userData?.MailingAddress?.zip &&
                      `${userData?.MailingAddress?.zip}`}
                  </p>
                </div>
                <p className="information-text">
                  <img
                    className="icon-info"
                    src={mediaApi.updateImageUrl(icons.value.src)}
                    alt=""
                  />
                  {t('mailing-address-click-here-text')}{' '}
                  <a href={profileUrl}>{t('mailing-address-click-here')}</a>{' '}
                  {t('mailing-address-click-here-update')}
                </p>
              </div>
            ) : userData && userData?.customText16 === 'true' ? (
              <div className="col-md-8">
                <p className="title pb-1">{t('mailing-address')}</p>
                <div className="location-payment">
                  <span className="search-icon-payment"></span>
                  <p className="location-item">
                    {userData?.address?.address1 && `${userData?.address?.address1}`}
                    {userData?.address?.address1 && <br />}
                    {userData?.address?.address2 && `${userData?.address?.address2}`}
                    {userData?.address?.address2 && <br />}
                    {userData?.address?.city && `${userData?.address?.city},`}
                    {userData?.address?.state && `${userData?.address?.state} `}
                    {userData?.address?.zip && `${userData?.address?.zip}`}
                  </p>
                </div>
                <p className="information-text">
                  <img
                    className="icon-info"
                    src={mediaApi.updateImageUrl(icons.value.src)}
                    alt=""
                  />
                  {t('mailing-address-click-here-text')}{' '}
                  <a href={profileUrl}>{t('mailing-address-click-here')}</a>{' '}
                  {t('mailing-address-click-here-update')}
                </p>
              </div>
            ) : null}
          </div>
          {userData && userData?.customText33 === 'Direct Deposit' ? (
            <div className="row">
              <div className="col-md-4">
                <p className="title pb-1">{t('bank-name')}</p>
                <p className="sub-title">{userData?.customText35}</p>
              </div>
              <div className="col-md-4">
                <p className="title pb-1">{t('account-number')}</p>
                <p className="sub-title">
                  {isNullorEmpty(userData?.customEncryptedText2)
                    ? '-'
                    : maskNumber(userData?.customEncryptedText2, -4, 'X')}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="no-content-data">{t(`empty-payment-options`)}</div>
      )}
    </>
  );
};

/**
 * @description - Current Payment Component
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */

const CurrentPaymentOptions = ({ fields, t, sitecoreContext }) => {
  const { currentPaymentTitle, infoIcon } = fields;
  const [open, setOpen] = useState(true);

  return (
    <div>
      <DashboardAccordion
        id={currentPaymentTitle?.value}
        title={currentPaymentTitle}
        isOpen={open}
        handleToggle={() => setOpen(!open)}
        content={
          <CurrentPayment t={t} icons={infoIcon} sitecoreContext={sitecoreContext} />
        }
      />
    </div>
  );
};

CurrentPaymentOptions.defaultProps = {
  t: (val) => val,
  sitecoreContext: {},
  fields: {},
};

CurrentPaymentOptions.propTypes = {
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}),
  fields: PropTypes.shape({}).isRequired,
};

export default withSitecoreContext()(withTranslation()(CurrentPaymentOptions));
