/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { useStore } from 'react-redux';

import TextboxComplex from '../../../globals/forms/TextboxComplex';
import RadioToggle from '../../../globals/forms/RadioToggle';
import Button from '../../../globals/buttons/Button';
import { usePostData } from '../../../../../hooks/http-client';
import AlertInline from '../../../ErrorBoundary/AlertInline';
import { SEARCH, API_STATUS_CODES } from '../../../../../constants';
import { getPayLoad } from '../../../../../utils/jwt';
import { parseFilterData } from '../../../../../utils/helperUtils';
import './job-alert.scss';

/**
 * @description to enable job alerts
 * @returns {node} - html
 */
const JobAlert = ({
  jobAlertOptions,
  t,
  jobAlertSuccessMessage,
  dashboardUrl,
  handleModal,
  showModal,
  searchCriteria,
}) => {
  const searchKey = new URLSearchParams(useLocation().search).get('searchKeyword');
  const filter = typeof window !== 'undefined' ? parseFilterData() : undefined;
  const filterValues = filter || {};
  const alertoptions = [];
  const store = useStore();
  const history = useHistory();

  const [jobAlert, setJobAlert] = useState(false);
  const [alertName, setAlertName] = useState([]);
  const [user, setUser] = useState();
  const [message, setMessage] = useState({});

  const { loading, error, write } = usePostData();
  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    mode: 'onClick',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      jobAlertFrequency: jobAlertOptions[0]?.fields?.key?.value,
    },
  });

  getPayLoad(store).then((payload) => setUser(payload?.firstName));

  useEffect(() => {
    const jobTypes = [];
    const employmentType = [];
    const remoteJobs = [];
    const industries = [];
    const specialization = [];
    if (filterValues || searchCriteria) {
      Array.isArray(filterValues.jobTypes) &&
        filterValues.jobTypes.forEach((filter) => {
          jobTypes.push(filter.value);
        });
      Array.isArray(filterValues.employmentType) &&
        filterValues.employmentType.forEach((type) => {
          employmentType.push(type.value);
        });
      Array.isArray(filterValues.remoteJobs) &&
        filterValues.remoteJobs.forEach((type) => {
          remoteJobs.push(type.value);
        });
      Array.isArray(filterValues.industries) &&
        filterValues.industries.forEach((type) => {
          industries.push(type.value);
        });
      Array.isArray(filterValues.specialization) &&
        filterValues.specialization.forEach((type) => {
          specialization.push(type.value);
        });
      setJobAlertName(
        jobTypes,
        employmentType,
        remoteJobs,
        industries,
        specialization,
        searchKey,
        filterValues.place
      );

      if (searchCriteria) {
        setValue('jobAlert', decodeURIComponent(searchCriteria.trim()), {
          shouldValidate: true,
        });
        setAlertName(searchCriteria);
      }
    }
  }, [showModal]);

  /**
   * @description - to create job alert name
   * @param {array} jobTypes - job type array
   * @param {array} employmentType - employment type array
   * @param {string} searchKey - search keyword
   * @param {string} place - place used for search
   * @returns {undefined} - no return
   */
  const setJobAlertName = (
    jobTypes,
    employmentType,
    remoteJobs,
    industries,
    specialization,
    searchKey,
    place
  ) => {
    const jobAlertName = [
      `${employmentType.length > 0 ? employmentType.join(',') : ''}`,
      `${jobTypes.length > 0 ? jobTypes.join(',') : ''}`,
      `${remoteJobs.length > 0 ? remoteJobs.join(',') : ''}`,
      `${industries.length > 0 ? industries.join(',') : ''}`,
      `${specialization.length > 0 ? specialization.join(',') : ''}`,
      `${searchKey ? searchKey : ''}`,
      `${place ? place : ''}`,
    ]
      .filter((el) => {
        return el !== '';
      })
      .join(', ');
    jobAlertName &&
      setValue('jobAlert', decodeURIComponent(jobAlertName.trim()), {
        shouldValidate: true,
      });
    setAlertName(decodeURIComponent(jobAlertName));
  };

  /**
   * @description to set job alert options
   * @returns {unedfined} - no return
   */
  const setAlertOptions = () => {
    jobAlertOptions.forEach((option, index) => {
      alertoptions[index] = {
        id: option?.fields?.key?.value,
        text: option?.fields?.label?.value,
        value: option?.fields?.key?.value,
      };
    });
  };

  setAlertOptions();

  /**
   * @description - data to be send for job alert
   * @param {object} values - form values captured
   * @returns {object} - data prepared
   */
  const prepareData = (values) => {
    const searchParam = new URLSearchParams(
      localStorage.getItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_PARAMS)
    );
    searchParam.delete('page');
    return {
      Frequency: values['jobAlertFrequency'],
      AlertName: getValues('jobAlert'),
      filters: { ...filterValues, ...{ SearchKeyword: searchKey } },
      queryString: decodeURIComponent(`?${String(searchParam)}`),
    };
  };

  /**
   * @description to handle job alert enable
   * @param {object} values - form data captured
   * @returns {undefined} - no return
   */
  const handleCreateAlert = (values) => {
    setMessage({});
    write(`${SEARCH.JOB_SEARCH.JOB_ALERT_API}`, prepareData(values), handleResponse);
  };

  /**
   * @description - post save action
   * @param {object} response - response get from api
   * @returns {undefined} - no returns
   */
  const handleResponse = (response) => {
    switch (response.data.status) {
      case API_STATUS_CODES.SUCCESS:
        setJobAlert(true);
        if (window?.dataLayer) {
          window.dataLayer.push({
            event: 'jobAlertConfirmed',
          });
        }
        break;
      case API_STATUS_CODES.DATA_EXIST:
        setMessage({
          ...message,
          ...{ type: 'error', message: t('job-alert-exist') },
        });
        break;
      case API_STATUS_CODES.LIMIT_EXCEEDS:
        setMessage({
          ...message,
          ...{ type: 'error', message: t('alert-limit-reached') },
        });
        break;
      case API_STATUS_CODES.ERROR:
        setMessage({
          ...message,
          ...{ type: 'error', message: t('error-job-alert') },
        });
        break;
    }
  };

  return (
    <>
      <div className="close-alert-div">
        <div className="close-icon-x" onClick={() => handleModal()} aria-hidden="true"></div>
      </div>
      {!alertName ? (
        <section className="job-alert">
          <div className="alert-title">{t('create-job-alert')}</div>
          <div className="no-alert">{t('no-job-alert-message')}</div>
          <div className="btn-ok">
            <Button text={t('close')} handleButtonClick={() => handleModal()} />
          </div>
        </section>
      ) : (
        <section className="job-alert">
          {!jobAlert ? (
            <>
              <div className="alert-title">{t('create-job-alert')}</div>
              <div className="alert-description">
                {alertName && t('job-alert-request-text') + ' ' + alertName}
              </div>
              <div className="job-alert-form">
                <div className="input-alert">
                  <TextboxComplex
                    label={t('job-alert-input-label')}
                    isSearchEnabled={false}
                    register={register({ required: true })}
                    name="jobAlert"
                    value={alertName}
                    showOrgVal
                    readonly={false}
                  />
                  {errors['jobAlert'] && (
                    <div className="error-msg">{t('valid-job-name')}</div>
                  )}
                </div>
                <div className="input-frequency">
                  <p>{t('frequency text')}</p>
                  <RadioToggle
                    options={alertoptions}
                    name="jobAlertFrequency"
                    inputRef={register}
                    selectedValue={getValues('jobAlertFrequency')}
                    showOrgVal
                  />
                </div>
                <div className="btn-job-alert">
                  <div>
                    {' '}
                    <Button
                      text={t('get-job-emails')}
                      handleButtonClick={handleSubmit(handleCreateAlert)}
                      isLoading={loading}
                    />
                  </div>
                </div>
                {error && (
                  <AlertInline message={t('error-job-alert')} type="error" />
                )}
                {message?.type && (
                  <AlertInline message={message?.message} type={message?.type} />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="alert-title">
                {t('you-are-all-set').replace('(_)', user)}
              </div>
              <div className="alert-description">
                {' '}
                <RichText field={jobAlertSuccessMessage} />
              </div>
              <div className="success-page-btn">
                <Button
                  text={t('view-dashboard')}
                  handleButtonClick={() => {
                    handleModal();
                    history.push(dashboardUrl.href);
                  }}
                />
              </div>
            </>
          )}
        </section>
      )}
    </>
  );
};

JobAlert.defaultProps = {
  jobAlertOptions: [],
  t: () => {},
  jobAlertSuccessMessage: '',
  dashboardUrl: '',
  handleModal: () => {},
  showModal: () => {},
  searchCriteria: '',
};

JobAlert.propTypes = {
  jobAlertOptions: PropTypes.arrayOf(),
  t: PropTypes.func,
  jobAlertSuccessMessage: PropTypes.string,
  dashboardUrl: PropTypes.string,
  handleModal: PropTypes.func,
  showModal: PropTypes.func,
  searchCriteria: PropTypes.string,
};

export default withTranslation()(JobAlert);
