import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sitecore-jss/sitecore-jss-react';

import TextLink from '../globals/link/TextLink';
import { isiOS } from '../../../utils/helperUtils';
import './page-banners.scss';
import { bannerLoaded } from '../../../redux/actions/actions';
import { useDispatch } from 'react-redux';

/**
 * @description - My Path banner.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const MyPathBanner = ({ fields }) => {
  const {
    title,
    logo,
    text,
    ctaUrl,
    ctaText,
    backgroundImage,
    foregroundImage,
  } = fields;
  const [isIos, setIsIos] = useState('');
  const dispatch = useDispatch();
  const hasImage = foregroundImage.value.src !== undefined ? 'hasImage' : '';

  useEffect(() => {
    if (isiOS()) {
      setIsIos('ios');
    }
    dispatch(bannerLoaded('MyPathBanner', true));
  }, []);

  return (
    <section className={`splashbanner animate--in mypath ${hasImage} ${isIos}`}>
      <div className="gradient__overlay"></div>
      <div className="rectangle"></div>
      <div className="rectangle2"></div>
      <Image field={backgroundImage} className="bknd__image" />
      <div className="content__wrap container">
        <div className="content">
          <span className="headline__eyebrow">{title?.value}</span>
          <h1 className="banner-logo">
            <span className="sr-only">{logo?.value?.alt}</span>
            <Image field={logo} aria-hidden="true" />
          </h1>
          <p className="text">{text?.value}</p>
          <TextLink
            cssClass="dark-gray button_white"
            ctaText={ctaText?.value}
            ctaUrl={ctaUrl?.value}
          />
        </div>
        <div className="person__imagewrap">
          <Image field={foregroundImage} className="person__image" />
        </div>
      </div>
    </section>
  );
};

MyPathBanner.defaultProps = {
  fields: {
    title: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: { value: null },
    backgroundImage: {
      value: { src: '' },
    },
    foregroundImage: {
      value: { src: '' },
    },
    logo: {
      value: { src: '' },
    },
  },
};

MyPathBanner.propTypes = {
  fields: PropTypes.shape({
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({ value: PropTypes.object }),
    backgroundImage: PropTypes.shape({ value: PropTypes.object.isRequired }),
    foregroundImage: PropTypes.shape({ value: PropTypes.object.isRequired }),
    logo: PropTypes.shape({ value: PropTypes.object.isRequired }),
  }),
};

export default MyPathBanner;
