import {
  PROFILE_INFO,
  FORM_DATA,
  PUBLIC_POSITION,
  CANDIDATE_ID,
  SCREENER_QUESTIONS,
  JOB_DETAILS,
  SAVED_JOBS,
  PROFILE_PIC,
  UPLOAD_CONFIG,
  BUILD_PROFILE_FORM,
  PROFILE_INFO_FORM,
  PROFILE_EMPTY_FIELDS,
  COVER_LETTER,
  RESUME,
  IS_FORM_SUBMITTING,
  PARSE,
  UPLOAD_FIELDS,
  JOBAPPLY_FORM_DATA,
  JOBAPPLY_FORM_STATE,
  REMOVE_FOCUS,
  BANNER_LOADED,
  SAVE_UPDATED_ZIP,
} from './actionTypes';

/**
 * @description - Action to save build profile form data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveFormActions = (data) => {
  return {
    type: BUILD_PROFILE_FORM.PERSONAL_SAVE,
    payload: data,
  };
};

/**
 * @description - Action to clear build profile form data.
 * @returns {Object} - Action.
 */
export const clearBuildProfileForm = () => {
  return {
    type: BUILD_PROFILE_FORM.CLEAR,
  };
};

/**
 * @description - Action to save profile data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveProfileInfo = (data) => {
  return {
    type: PROFILE_INFO.SAVE,
    payload: data,
  };
};

/**
 * @description - Action to save NIEExists Boolean data.
 * @param {Boolean} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveNIEInfo = (data) => {
  return {
    type: PROFILE_INFO.SAVE_NIE,
    payload: data,
  };
};

/**
 * @description - Action to clear profile data.
 * @returns {Object} - Action.
 */
export const clearProfileInfo = () => {
  return {
    type: PROFILE_INFO.CLEAR,
  };
};

/**
 * @description - Action to save form data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveFormData = (data) => {
  return {
    type: FORM_DATA.SAVE,
    payload: data,
  };
};

/**
 * @description - Action to clear form data.
 * @returns {Object} - Action.
 */
export const clearFormData = () => {
  return {
    type: FORM_DATA.CLEAR,
  };
};

/**
 * @description - Action to be triggered when api returns error.
 * @returns {Object} - Action.
 */
export const errorProfileInfo = () => {
  return {
    type: PROFILE_INFO.ERROR,
  };
};

/**
 * @description - Action to save profile data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveProfileInfoForm = (data) => {
  return {
    type: PROFILE_INFO_FORM.SAVE,
    payload: data,
  };
};

/**
 * @description - To store updated zip code
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveUpdatedZipCode = (data) => {
  return {
    type: SAVE_UPDATED_ZIP.SAVE,
    payload: data,
  };
};

/**
 * @description - Action to save profile data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveProfileInfoFormName = (data) => {
  return {
    type: PROFILE_INFO_FORM.SAVE_COUNT,
    payload: data,
  };
};

/**
 * @description - Action to save data if frm is valid.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveProfileInfoFormNameIfFormValid = (data) => {
  return {
    type: PROFILE_INFO_FORM.SAVE_COUNT_IF_VALID,
    payload: data,
  };
};

/**
 * @description - Action to save profile data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const clearProfileInfoFormCount = (data) => {
  return {
    type: PROFILE_INFO_FORM.CLEAR_COUNT,
    payload: data,
  };
};

/**
 * @description - Action to clear profile data.
 * @returns {Object} - Action.
 */
export const clearProfileInfoForm = () => {
  return {
    type: PROFILE_INFO_FORM.CLEAR,
  };
};

/**
 * @description - Action to save profile data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveProfieEmptyFields = (data) => {
  return {
    type: PROFILE_EMPTY_FIELDS.SAVE,
    payload: data,
  };
};

/**
 * @description - Action to clear profile data.
 * @returns {Object} - Action.
 */
export const clearProfieEmptyFields = () => {
  return {
    type: PROFILE_EMPTY_FIELDS.CLEAR,
  };
};

/**
 * @description - Action to save public position data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const savePublicPosition = (data) => {
  return {
    type: PUBLIC_POSITION.SAVE,
    payload: data,
  };
};

/**
 * @description - Action to clear public position data.
 * @returns {Object} - Action.
 */
export const clearPublicPosition = () => {
  return {
    type: PUBLIC_POSITION.CLEAR,
  };
};

/**
 * @description - Action to save profile data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveCandidateId = (data) => {
  return {
    type: CANDIDATE_ID.SAVE,
    payload: data,
  };
};

/**
 * @description - Action to clear profile data.
 * @returns {Object} - Action.
 */
export const clearCandidateId = () => {
  return {
    type: CANDIDATE_ID.CLEAR,
  };
};

/**
 * @description - Action to screener question data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveScreenerQuestions = (data) => {
  return {
    type: SCREENER_QUESTIONS.SAVE,
    payload: data,
  };
};

/**
 * @description - Action to clear  screener question data.
 * @returns {Object} - Action.
 */
export const clearScreenerQuestions = () => {
  return {
    type: SCREENER_QUESTIONS.CLEAR,
  };
};

/**
 * @description - Action to save job details data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveJobDetails = (data) => {
  return {
    type: JOB_DETAILS.SAVE,
    payload: data,
  };
};

/**
 * @description - Action to clear job details data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const clearJobDetails = () => {
  return {
    type: JOB_DETAILS.CLEAR,
  };
};

/**
 * @description - Action to handlepage redirect.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const handleRedirect = () => {
  return {
    type: JOB_DETAILS.PAGEREDIRECT,
  };
};

/**
 * @description - Action to add saved job data.
 * @param {Object} jobItemId - Payload Data.
 * @returns {Object} - Action.
 */
export const addSavedJob = (jobItemId) => {
  return {
    type: SAVED_JOBS.ADD,
    payload: jobItemId,
  };
};

/**
 * @description - Action to remove saved job data.
 * @param {Object} jobItemId - Payload Data.
 * @returns {Object} - Action.
 */
export const removeSavedJob = (jobItemId) => {
  return {
    type: SAVED_JOBS.REMOVE,
    payload: jobItemId,
  };
};

/**
 * @description - Action to clear saved job data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const clearSavedJobs = () => {
  return {
    type: SAVED_JOBS.CLEAR,
  };
};

/**
 * @description - Action to get profile pic data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const getProPic = (data) => {
  return {
    type: PROFILE_PIC.GET,
    payload: data,
  };
};

/**
 * @description - Action to save configuration data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveConfig = (data) => {
  return {
    type: UPLOAD_CONFIG.GET,
    payload: data,
  };
};

/**
 * @description - Action to save resume data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const resumeSave = (data) => {
  return {
    type: RESUME.GET,
    payload: data,
  };
};

/**
 * @description - Action to remove resume data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const removeResume = (data) => {
  return {
    type: RESUME.CLEAR,
    payload: data,
  };
};

/**
 * @description - Action to save cover letter data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const coverSave = (data) => {
  return {
    type: COVER_LETTER.GET,
    payload: data,
  };
};

/**
 * @description - Action to remove cover letter data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const removeCover = (data) => {
  return {
    type: COVER_LETTER.CLEAR,
    payload: data,
  };
};

/**
 * @description - Action to save form submitting data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveFormSubmitting = (data) => {
  return {
    type: IS_FORM_SUBMITTING.SET,
    payload: data,
  };
};

/**
 * @description - Action to save parse data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveParse = (data) => {
  return {
    type: PARSE.SET,
    payload: data,
  };
};

/**
 * @description - Action to save fields data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveField = (data) => {
  return {
    type: UPLOAD_FIELDS.SAVE,
    payload: data,
  };
};

/**
 * @description - Action to save Job apply form data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveJobApplyFormData = (data) => {
  return {
    type: JOBAPPLY_FORM_DATA.SAVE,
    payload: data,
  };
};

/**
 * @description - Action to save data if form is valid.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveJobApplyValidFormList = (data) => {
  return {
    type: JOBAPPLY_FORM_DATA.SAVE_VALID_FORM_NAME,
    payload: data,
  };
};

/**
 * @description - Action to clear Job apply form data.
 * @returns {Object} - Action.
 */
export const clearJobApplyFormData = () => {
  return {
    type: JOBAPPLY_FORM_DATA.CLEAR,
  };
};

/**
 * @description - Action to save Triggered state of Job apply form.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveJobApplyFormTriggerData = (data) => {
  return {
    type: JOBAPPLY_FORM_DATA.SAVE_TRIGGERED,
    payload: data,
  };
};

/**
 * @description - Action to save profile data.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const clearJobApplyFormTriggerData = (data) => {
  return {
    type: JOBAPPLY_FORM_DATA.CLEAR_TRIGGERED,
    payload: data,
  };
};

/**
 * @description - Action to save Job apply formstate.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveJobApplyFormState = (data) => {
  return {
    type: JOBAPPLY_FORM_STATE.SAVE,
    payload: data,
  };
};

/**
 * @description - Action to clear Job apply formstate.
 * @returns {Object} - Action.
 */
export const clearJobApplyFormState = () => {
  return {
    type: JOBAPPLY_FORM_STATE.CLEAR,
  };
};

export const removeFocus = (data) => {
  return {
    type: REMOVE_FOCUS.FOCUS,
    payload: data,
  };
};

export const bannerLoaded = (banner, value) => {
  return {
    type: BANNER_LOADED.LOADED,
    banner: banner,
    value: value,
  };
};

export const resumeLength = (data) => {
  return {
    type: BUILD_PROFILE_FORM.RESUME_LENGTH,
    payload: data,
  };
};
