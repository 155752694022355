/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  patternRegexCheck,
  validateDNI,
} from '../DynamicForm/helper/validationFactory';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';
import { isNullorEmpty } from '../../../../utils/helperUtils';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - Dynamic TextNumberbox component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const TextNumberbox = ({
  id,
  name,
  label,
  requiredValidation = [],
  type,
  icon,
  customStyle,
  readOnly,
  customError,
  hintText,
  dataValidations,
  getValues,
  setValue,
  watch,
  showBasedOnFieldName,
  hideBasedOnFieldName,
  isHideField,
  value,
  validateOnLoad,
  formName,
  t,
  control,
  disableIfDataExist,
  maxLength
}) => {
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  const accountNumber = watch('customEncryptedText2');
  const pin = watch('pin');
  const reEnterPin = watch('reEnterPin');
  const verfiyAccountNumber = watch('reEnterAccountNumber');
  const fieldError = get(customError, name);
  const patternData = getValidationDatabyType(dataValidations, 'pattern');
  const isSameAccountNumber = getValidationDatabyType(
    dataValidations,
    'isAccountNumberSame'
  );
  const isSameAsPin = getValidationDatabyType(
    dataValidations,
    'isAccountNumberSame'
  );
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const maximumData = getValidationDatabyType(dataValidations, 'maximum');
  const minimumData = getValidationDatabyType(dataValidations, 'minimum');
  const dniValidator = getValidationDatabyType(dataValidations, 'dni');

  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;
  const readOnlyBasedOnValue = (disabled) =>
    disableIfDataExist && !isNullorEmpty(value) ? true : disabled;

  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    param = value ? { ...param, shouldDirty: true } : param;
    setValue(name, value, param);
  }, [value]);

  return (
    <div
      className={`form-block complex ${fieldError ? 'error withoutBg' : ''} ${
        !showField || hideField ? 'hide' : ''
      }`}
      style={customStyle}
    >
      <Controller
        name={name}
        control={control}
        render={({ onChange, onBlur, value, }) => (
          <input
            className={getValues && getValues(name) ? 'filled' : ''}
            value={value || ''}
            id={id}
            type={type}
            autoComplete="off"
            readOnly={readOnlyBasedOnValue(readOnly)}
            aria-required={
              showField && requiredData?.fields?.value?.value ? !hideField : false
            }
            {...(fieldError
              ? { 'aria-invalid': true, 'aria-describedby': `err_${id}` }
              : '')}
            {...((getValues && getValues(name)) || value
              ? {}
              : {
                  'aria-label': requiredData?.fields?.value?.value
                    ? ''
                    : `${label} edit blank`,
                })}
            onBlur={(e) => {
              onBlur(e);
            }}
            onChange={(selected) => {
              let selectedVal = selected?.target?.value
                ? selected?.target?.value
                : selected;
              onChange(selectedVal);
            }}
            onInput={(e) => {
              if(name === "pin" || "reEnterPin"){
                if (e.target.value.length > maxLength)
                e.target.value = e.target.value.slice(0,maxLength);
              }else{
                e.target.value
              }
            }}
            onKeyDown={ e => ( e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 109 || e.keyCode === 189)  && e.preventDefault() }
          />
        )}
        rules={{
          required:
            showField && requiredData?.fields?.value?.value ? !hideField : false,
          validate: {
            dniValidationCheck: (value) =>
              (dniValidator?.fields?.value?.value === 'true' ||
              dniValidator?.fields?.value?.value === true
                ? validateDNI(value)
                : true) ||
              dniValidator?.fields?.message?.value ||
              t('validation-failed'),
            isSameAccountNumber: () => {
              return accountNumber !== verfiyAccountNumber
                ? isSameAccountNumber?.fields?.message?.value
                : null;
            },
            isSameAsPin: () => {
              return pin !== reEnterPin ? isSameAsPin?.fields?.message?.value : null;
            },
          },
          maxLength: {
            value: maximumData?.fields?.value?.value || null,
            message: maximumData?.fields?.message?.value || t('validation-failed'),
          },
          minLength: {
            value: minimumData?.fields?.value?.value || null,
            message: minimumData?.fields?.message?.value || t('validation-failed'),
          },
          pattern: {
            value: patternData?.fields?.value?.value
              ? patternRegexCheck(patternData.fields.value.value)
              : '',
            message: patternData?.fields?.message?.value || t('validation-failed'),
          },
        }}
      />

      {label && (
        <label htmlFor={id}>
          <div className="label-text">
            {icon && (
              <div className="input-icon">
                <span className="input-icon"></span>
              </div>
            )}
            {label}
          </div>
        </label>
      )}
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
      {hintText && (
        <div>
          {disableIfDataExist ? (
            <div className={`hintText ${value ? '' : 'hide'}`}>
              {<RichText field={{ value: hintText }} />}
            </div>
          ) : (
            <div className="hintText">{hintText}</div>
          )}
        </div>
      )}
    </div>
  );
};

TextNumberbox.defaultProps = {
  id: '',
  name: '',
  label: '',
  placeholder: '',
  type: 'number',
  icon: '',
  maxLength: '',
  readOnly: false,
  setValue: () => {},
  t: () => {},
  trigger: () => {},
  handleChange: () => {},
};

TextNumberbox.propTypes = {
  id: PropTypes.string.isRequired,
  t: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  handleChange: PropTypes.func,
  maxLength: PropTypes.string,
};

export default TextNumberbox;
