import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AccordionBlock from '../../../Accordion';
import Loader from '../../../../core/ErrorBoundary/Loader';
import Error from '../../../../core/ErrorBoundary/Error';
import { getCandidateDetailsApi } from '../../../../../services/apiServices/candidateService';
import { clearProfileInfo } from '../../../../../redux/actions/actions';
import { useHistory } from 'react-router-dom';

/**
 * @description -DashboardAccordionAssociateContentcomponent
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const DashboardProfileAccordion = ({ fields, data, t, profileInfo, dispatch }) => {
  const { text, title, textEntity } = fields;
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [error, setError] = useState(false);
  const [descriptionData, setDescriptionData] = useState(null);
  const history = useHistory();
  const entity = profileInfo?.PersonalDetails?.data[0]?.bhBranchEntity;

  useEffect(() => {
    if (entity !== undefined) {
      if (entity && (entity === 'MPUSA' || entity === 'MPCAN')) {
        setDescriptionData(text);
      } else {
        setDescriptionData(textEntity);
      }
    }
  }, [entity]);

  const filteredData1 = data.filter(
    (item) =>
      item.fields.entity.value === 'MPUSA' || item.fields.entity.value === 'MPCAN'
  );
  const filteredData2 = data.filter(
    (item) =>
      item.fields.entity.value !== 'MPUSA' && item.fields.entity.value !== 'MPCAN'
  );

  useEffect(() => {
    if (profileInfo?.Id) {
      setprofileDataLoading(false);
      if (profileInfo && !profileInfo?.isAssociate) {
        history.push('/en/page-not-found');
      }
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);

  const handleApiRefresh = () => {
    dispatch(clearProfileInfo());
    setTimeout(() => getCandidateDetailsApi({}, dispatch), 500);
  };
  return (
    <section
      className="profile-accordion-section"
      aria-labelledby="title"
      aria-describedby="info"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 pt-5">
            {title?.value && (
              <div className="row pt-2">
                <div className="col-md-12">
                  <Text field={title} tag="h2" className="title-small" id="info" />
                </div>
              </div>
            )}
            {descriptionData?.value && (
              <div className="row">
                <div className="col-md-12">
                  <Text
                    field={descriptionData}
                    tag="h1"
                    className="text"
                    id="info"
                  />
                </div>
              </div>
            )}
            {profileDataLoading ? (
              <Loader
                loaderMsg={t('fetching-your-data')}
                loaderWithMsg
                customLoaderStyle={{
                  width: '4rem',
                  height: '4rem',
                  color: '#C25700',
                }}
                customTextStyle={{ color: '#C25700' }}
              />
            ) : error ? (
              <div className="apiError">
                <Error
                  errorMsg={t('error-message')}
                  t={t}
                  refreshApi={true}
                  handleApiRefresh={handleApiRefresh}
                />
              </div>
            ) : (
              <>
                {data && data.length > 0 && (
                  <>
                    <div className="row">
                      <div className="col">
                        <AccordionBlock
                          isAssociateContentPage={true}
                          allowMultipleOpen={true}
                          data={
                            entity === 'MPCAN' || entity === 'MPUSA'
                              ? filteredData1
                              : filteredData2
                          }
                          showExpandButton={true}
                          t={t}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

DashboardProfileAccordion.defaultProps = {
  t: (val) => val,
  fields: {},
  profileInfo: {},
  dispatch: () => {},
  data: [],
};

DashboardProfileAccordion.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.arrayOf({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(
  connect(mapStateToProps)(DashboardProfileAccordion)
);
