export const formattSkillsData = (response) => {
  const formattedData =
    response?.map((item) => {
      if (item) {
        const tempObj = {
          value: item.id,
          label: item.name,
        };
        return tempObj;
      }
    }) || [];
  return formattedData;
};

const getBranchId = (locationName, branchLocationList) => {
  const location = branchLocationList.find(
    (location) => location?.fields?.bhBranchName?.value === locationName
  );
  return location ? location.fields.branchId.value : null;
};
export const checkUserHaveBranchAssigned = (profileInfo) => {
  const data = profileInfo?.PersonalDetails?.data?.[0];
  if (
    Array.isArray(data?.skills) &&
    data?.skills?.length &&
    data?.address?.zip !== null &&
    data?.customText25 === null
  ) {
    return true;
  }
  return false;
};
export const postPersonalInfoData = (
  locationAdressFromApi,
  sitecoreContext,
  branchId,
  allSkills,
  locationAdress,
  personalData,
  branchLocationList
) => {
  const personalInfo = personalData?.PersonalDetails?.data?.[0];
  if (personalInfo && personalInfo?.customText25 !== null) {
    const branchID = getBranchId(personalInfo?.customText25, branchLocationList);
    const dataForAlreadyHaveBranch = {
      EditPersonalInfo: {
        address: {
          address1: locationAdressFromApi?.address1
            ? locationAdressFromApi?.address1
            : '',
          address2: locationAdressFromApi?.address2
            ? locationAdressFromApi?.address2
            : '',
          zip: locationAdress?.zip,
          city: locationAdress?.city,
          state: locationAdress?.state,
        },
        Branch: branchID,
        countryCode: sitecoreContext?.Country?.name,
        country:
          sitecoreContext?.Country?.name === 'US' ? 'United States' : 'Canada',
      },
      EditExpertiseAndSkills: { skills: allSkills },
    };
    return dataForAlreadyHaveBranch;
  }
  const notHavingBranchData = {
    EditPersonalInfo: {
      address: {
        address1: locationAdressFromApi?.address1
          ? locationAdressFromApi?.address1
          : '',
        address2: locationAdressFromApi?.address2
          ? locationAdressFromApi?.address2
          : '',
        zip: locationAdress?.zip,
        city: locationAdress?.city,
        state: locationAdress?.state,
      },
      countryCode: sitecoreContext?.Country?.name,
      country: sitecoreContext?.Country?.name === 'US' ? 'United States' : 'Canada',
      Branch: branchId,
    },
    EditExpertiseAndSkills: { skills: allSkills },
  };

  return notHavingBranchData;
};
