/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React from 'react';
import TextLink from '../../../../core/globals/link/TextLink';
import { Text, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { LocationUrl } from '../../../../../utils/helperUtils';

const LocatorCard = ({ cardDetails, location, t }) => {
  const { fields = {} } = cardDetails;
  const { title, locationIcon, phoneIcon, ctaText, ctaUrl } = fields;

  const branchUrl = `${ctaUrl?.value?.href}/${location && location?.branchName?.replace(/\s+/g, '-').toLowerCase()}`

  return (
    <>
      {
        <div className="card col-md-6">
          <div className="card-body local-card">
            {title && (
              <Text field={title} tag="h5" className="title" role="heading" />
            )}
            {location?.zip && (
              <a
                href={LocationUrl(location)}
                className="locator-card-icon"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Location opens in a new window/tab"
              >
                {locationIcon?.value?.src || locationIcon?.value?.alt ? (
                  <img
                    className={locationIcon?.value?.src ? 'location-icon' : ''}
                    src={
                      locationIcon?.value?.src &&
                      mediaApi.updateImageUrl(locationIcon?.value?.src)
                    }
                    alt={locationIcon?.value?.alt ?? 'location'}
                  />
                ) : (
                  ''
                )}
                <div className="location-text">
                  <a
                    className="location-item"
                    href={LocationUrl(location)}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="location opens in a new window/tab"
                  >
                    {location?.address1 && `${location?.address1}`}
                    {location?.address2 && `${location?.address2}`}
                    <br />
                    {location?.city && `${location?.city},`}{' '}
                    {location?.state && `${location?.state} `}
                    {location?.zip && `${location?.zip}`}
                  </a>
                </div>
              </a>
            )}
            {location?.phone !== '-' && location?.phone && (
              <a
                href={`tel: ${location?.phone}`}
                className="locator-card-icon"
                aria-label="Phone number opens in a new window/tab"
              >
                {phoneIcon?.value?.src || phoneIcon?.value?.alt ? (
                  <img
                    className={phoneIcon?.value?.src ? 'phone-icon' : ''}
                    src={
                      phoneIcon?.value?.src &&
                      mediaApi.updateImageUrl(phoneIcon?.value?.src)
                    }
                    alt={phoneIcon?.value?.alt ?? 'phone'}
                  />
                ) : (
                  <div className="icon">
                    <div className="icon-phone"></div>
                  </div>
                )}
                <div className="phone-text">{location?.phone}</div>
              </a>
            )}
            {location?.phone === undefined && (
              <div className="no-data text-center">
                {' '}
                {t('no-location-available')}{' '}
              </div>
            )}
            <div className="card-button">
              <TextLink ctaText={ctaText} ctaUrl={branchUrl} arrow />
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default LocatorCard;
