/* eslint-disable require-jsdoc */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import UserAssignments from '../UserAssignments';
import DashboardAccordion from '../../DashboardAccordion';

const UserPastAssignments = ({ fields }) => {
  const { title, noContent } = fields;
  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);
  const [profileDataLoading, setProfileDataLoading] = useState(true);
  const [pastAssignments, setPastAssignments] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (profileInfo && profileInfo.Id && profileInfo.AssociateDetails) {
      const assignmentsData = profileInfo?.AssociateDetails;
      const pastAssignments = assignmentsData?.filter((assignment) => {
        const endDate = moment(parseInt(assignment?.dateEnd));
        const currentDate = moment(Date.now());
        return endDate < currentDate;
      });
      const sortedAssignments = pastAssignments
        ? pastAssignments.sort((a, b) => a.dateEnd - b.dateEnd)
        : [];
      setPastAssignments(sortedAssignments);
      setProfileDataLoading(false);
    }
  }, [profileInfo]);
  if (!profileInfo?.isAssociate) {
    return null;
  }

  return (
    <>
      <div>
        <DashboardAccordion
          id={title?.value}
          title={title}
          isOpen={open}
          handleToggle={() => setOpen(!open)}
          content={
            <UserAssignments
              title={title}
              noContent={noContent}
              assignments={pastAssignments}
              profileDataLoading={profileDataLoading}
            />
          }
        />
      </div>
    </>
  );
};

export default withSitecoreContext()(UserPastAssignments);
