/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Modal from '../../../../../core/Modal';
import DashboardAccordion from '../../DashboardAccordion';
import './tax-forms.scss';
import ChangePinTemplate from './ChangePinTemplate';
import { maskNumber } from '../../../../../../utils/helperUtils';

/**
 * @description - Payment Option Component
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */

const ChangePin = ({ fields, t }) => {
  const {
    directDepositTitle,
    directDepositDescription,
    directDepositConsent,
    directDepositFields,
    CancelCTAText,
    SubmitCTAText,
  } = fields;

  const [currentModal, setCurrentModal] = useState();
  const profileInfoReducer = useSelector((state) => state.profileInfoReducer);
  const userData = profileInfoReducer?.data?.PersonalDetails.data[0];
  const workAddress = profileInfoReducer?.data?.TaxFormAddresses?.data;
  const [selectedOption, setSelectedOption] = useState(1);
  const [selectedWorkAddress, setSelectedWorkAddress] = useState(workAddress[0]);
  console.log(workAddress, 'workAddress');
  const radioOptions = workAddress.map((object, index) => ({
    ...object,
    id: index + 1,
  }));
  const handleOptionChange = (id, item) => {
    setSelectedOption(id);
    setSelectedWorkAddress(item);
  };
  const handleModalOpen = () => {
    setCurrentModal(true);
  };

  const isModalOpen = () => {
    return currentModal;
  };

  const handleCloseModal = () => {
    setCurrentModal(null);
  };

  return (
    <div className="payment-options">
      <Modal
        showModal={isModalOpen()}
        handleModal={handleCloseModal}
        size="md"
        hideFooter
        backdrop="static"
      >
        <ChangePinTemplate
          key="body"
          t={t}
          fields={fields}
          userData={userData}
          items={directDepositFields}
          handleModal={handleCloseModal}
          selectedWorkAddress={selectedWorkAddress}
          title={directDepositTitle}
          description={directDepositDescription}
          consent={directDepositConsent}
          submitCta={SubmitCTAText?.value}
          cancelCta={CancelCTAText?.value}
        />
      </Modal>
      <div className="row row-divider">
        <div className="col-md-12">
          <p className="user-details-data">{`${fields?.description?.value}`}</p>
        </div>
        <div className="row col-md-12 mt-4">
          <div className="col-md-4">
            <p className="user-details-title">{t('full-name')}</p>
            <p className="user-details-data">{`${userData?.firstName} ${userData?.lastName}`}</p>
          </div>
          <div className="col-md-5">
            <p className="user-details-title">{t('residence-address')}</p>
            <p className="location-item">
              {userData?.address?.address1 && `${userData?.address?.address1}`}
              {userData?.address?.address1 && <br />}
              {userData?.address?.address2 && `${userData?.address?.address2}`}
              {userData?.address?.address2 && <br />}
              {userData?.address?.city && `${userData?.address?.city},`}
              {userData?.address?.state && `${userData?.address?.state} `}
              {userData?.address?.zip && `${userData?.address?.zip}`}
            </p>
          </div>
          <div className="col-md-3">
            <p className="user-details-title">{t('ssn')}</p>
            {userData?.ssn ? (
              <p className="user-details-data">
                {maskNumber(userData?.ssn, -4, 'X')}
              </p>
            ) : (
              <p className="user-details-data">{t('no-ssn')}</p>
            )}
          </div>
        </div>
        <div className="row col-md-12 pt-4">
          <div className="col-8">
            <p className="user-details-title">{t('work-address')}</p>
            <span>{t('work-address-note')}</span>
          </div>
          <div className="col-md-10 col-12  row work-location form-check radio-toggle">
            {radioOptions &&
              radioOptions.length > 0 &&
              radioOptions.map((item, index) => (
                <div
                  className="col-md-4 col-sm-12 form-radio-group checklist pr-4"
                  key={index}
                >
                  <div className="checklist__item">
                    <div
                      className="form-check radio-toggle flex"
                      role="button"
                      tabIndex={0}
                      onClick={() => handleOptionChange(item.id, item)}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        value={item?.id}
                        checked={selectedOption == item?.id}
                        onChange={(e) => handleOptionChange(e?.target?.value, item)}
                      />
                      <p className="user-details-title pl-2">{item?.JobTitle}</p>
                      <div className="user-details-data pl-2">
                        {item?.WorkLocation?.address1 ? (
                          <p className="work-address-para">
                            {' '}
                            {`${item?.WorkLocation?.address1},`}
                          </p>
                        ) : (
                          ''
                        )}
                        {item?.WorkLocation?.address2 ? (
                          <p className="work-address-para">
                            {' '}
                            {`${item?.WorkLocation?.address2},`}
                          </p>
                        ) : (
                          ''
                        )}
                        {
                          <p className="work-address-para">
                            {' '}
                            {`${
                              item?.WorkLocation?.city
                                ? item?.WorkLocation?.city
                                : ''
                            } ${
                              item?.WorkLocation?.state
                                ? item?.WorkLocation?.state
                                : ''
                            } ${
                              item?.WorkLocation?.zip ? item?.WorkLocation?.zip : ''
                            } `}{' '}
                          </p>
                        }
                        {item?.WorkLocation?.countryName && (
                          <p className="work-address-para">
                            {' '}
                            {`${item?.WorkLocation?.countryName}`}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className=" row justify-content-end col-12">
          <div className="create-form-button-container">
            <button
              onClick={() => handleModalOpen()}
              className="primary-button orange-sd float-right "
            >
              {fields?.createFormCTA?.value}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * @description - Change Payment Option Component
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const CreateNewTaxForm = ({ fields, t }) => {
  const { createFormTitle, changePinFields } = fields;
  const [open, setOpen] = useState(true);

  return (
    <div>
      <DashboardAccordion
        id={createFormTitle?.value}
        title={createFormTitle}
        isOpen={open}
        handleToggle={() => setOpen(!open)}
        content={<ChangePin data={changePinFields} fields={fields} t={t} />}
      />
    </div>
  );
};

CreateNewTaxForm.defaultProps = {
  t: (val) => val,
  fields: {},
};

CreateNewTaxForm.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
};

export default withTranslation()(CreateNewTaxForm);
