/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { Controller, useController } from 'react-hook-form';
import Select, { components } from 'react-select';
import {
  RichText,
  mediaApi,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import NormalUpload from './Drive/NormalUpload';
import DropBox from './Drive/DropBox';
import GoogleDrive from './Drive/GoogleDrive';
import OneDriveComponent from './Drive/OneDriveComponent';
import FileList from './FileList';
import Modal from '../../../Modal';
import TooltipComponent from '../../../Tooltip';
import Button from '../../../globals/buttons/Button';
import {
  dataURLtoFile,
  base64ArrayBuffer,
  getImg,
  filterArrayByType,
  isNorthAmericanCountry,
} from '../../../../../utils/helperUtils';
import { fileTypesAllowed } from '../../../../../utils/enums';
import { getValidationDatabyType } from '../../DynamicForm/helper/filter';
import {
  attachementDownloadApi,
  attachementViewPdf,
} from '../../../../../services/apiServices/candidateService';
import './upload-block.scss';
import { set } from 'lodash';
import { resumeLength } from '../../../../../redux/actions/actions';

const { ValueContainer, Placeholder } = components;

/**
 * @description - Value Container
 * @param {*} param0
 */
const NewValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder
        {...props}
        isFocused={props.selectProps.menuIsOpen || props.hasValue}
      >
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? (
          <div className="dummy-input-wrapper">{child}</div>
        ) : null
      )}
    </ValueContainer>
  );
};

const StyleObject = (error) => {
  return {
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
    }),
    group: (provided, state) => ({
      ...provided,
      borderBottom:
        state?.headingProps?.id === 'react-select-2-group-0-heading'
          ? '1px solid rgba(103, 105, 111, 0.5)'
          : 'none',
    }),
    groupHeading: (styles) => ({
      ...styles,
      color: '#939498',
      fontWeight: 'bold',
      textTransform: 'none',
    }),
    option: (styles) => ({
      ...styles,
      textAlign: 'left',
      zIndex: 999999,
      color: '#939498',
      backgroundColor: 'none',
      '&:hover': {
        backgroundColor: '#DEEBFF',
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      color: 'rgba(103, 105, 111, 0.5)',
      top: state.isSelected || state.selectProps.inputValue ? 15 : '50%',
      transition: 'top 0.1s, font-size 0.1s',
      whiteSpace: 'nowrap',
      fontSize: state.isSelected || state.selectProps.inputValue ? 11 : 16,
    }),
    control: (base) => ({
      ...base,
      height: 49,
      minHeight: 49,
      cursor: 'pointer',
      ...(error
        ? {
            border: '1px solid  #9d323d',
            '&:hover': { borderColor: '#9d323d' },
            boxShadow: 'none',
          }
        : { boxShadow: 'none' }),
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 49,
      cursor: 'pointer',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      top: 35,
    }),
  };
};

/**
 * @description - UploadBlock Component with File list in the Dropdown.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const UploadBlock = (props) => {
  const {
    label,
    customStyle,
    optionsData,
    handleUpload,
    name,
    value,
    t,
    handleDelete,
    config,
    isMultifiles,
    dataValidations,
    requiredValidation,
    setValue,
    customError,
    className,
    control,
    limit,
    downloadApiEndPoint,
    cssClass,
    toolTipText,
    filterReq,
    clearErrors,
    setError,
    validateOnLoad,
    fileTypes,
    readOnly,
    formState,
    id,
    hintText,
    showOnload,
    isHardDelete,
    sitecoreContext,
  } = props;

  const fieldError = get(customError, name);
  const [file, setFile] = useState([]);
  const [src, setSrc] = useState('#');
  const [selectedFile, setSelectedFile] = useState({});
  const [options, setOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewPdfLoading, setViewPdfLoading] = useState(-1);

  const [removeFileIndex, setFileIndex] = useState('');
  const [confirmDownload, setConfirmDownload] = useState(false);
  const [downloadIndex, setDownloadIndex] = useState(0);
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  const requiredData = requiredValidation
    ? requiredValidation[0]
    : getValidationDatabyType(dataValidations, 'required');
  const validFileTypes = fileTypes
    ? fileTypes.toUpperCase().split(',')
    : fileTypesAllowed;
  const normal = React.useRef();
  const dbox = React.useRef();
  const gDrive = React.useRef();
  const oneD = React.useRef();
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const dispatch = useDispatch();

  const { field } = useController({
    name,
    control,
    rules: {
      required: requiredData?.fields?.value?.value || false,
    },
  });

  /**
   * @description - Option component
   * @param {*} icon
   * @param {*} text
   */
  const optionComponent = (text, icon = null) => (
    <div className="upload-container">
      <div
        style={{
          backgroundImage: `url(${mediaApi.updateImageUrl(getImg(icon))})`,
        }}
        className="upload-icon"
      />
      {text || null}
    </div>
  );

  useEffect(() => {
    if (!isMultifiles) {
      const optionsArray = [
        {
          label: t('from-my-documents'),
          options: [],
        },
        {
          label: t('upload-file'),
          options: [],
        },
      ];
      file?.map((item) => {
        optionsArray?.[0]?.options.push({
          value: item?.id,
          label: optionComponent(item?.name),
          data: item,
        });
      });
      if (file?.length < limit) {
        optionsData?.map((item) => {
          optionsArray?.[1]?.options.push({
            value: item?.fields?.key?.value || 'upload',
            label: optionComponent(item.fields.label.value, item.fields.img),
            data: item.fields,
          });
        });
      } else {
        optionsArray?.[1]?.options.push({
          label: t('reached-max-number'),
          isDisabled: true,
        });
      }
      setOptions(optionsArray);
    } else {
      const temp = [];
      optionsData.map((item, index) => {
        temp.push({
          value: item?.fields?.key?.value || 'upload',
          label: optionComponent(item.fields.label.value, item.fields.img),
          data: item.fields,
        });
      });
      setOptions(temp);
    }
    if (name === 'Resume') {
      dispatch(resumeLength(file?.length));
    }
  }, [optionsData, file]);

  useEffect(() => {
    param = value ? { ...param, shouldDirty: true } : param;
    if (value && Array.isArray(value)) {
      let data = [];
      if (filterReq) {
        data = filterArrayByType(value, name);
      } else {
        data = value;
      }
      if (data?.length > 0 && data?.[0]?.id && showOnload) {
        setSelectedFile(data?.[0]);
        setValue(
          name,
          {
            value: data?.[0]?.id,
            label: optionComponent(data[0]?.name),
            data: data?.[0],
          },
          param
        );
      }

      setFile(data);
    } else {
      setValue(name, null, param);
    }
  }, [value]);

  /**
   * @description On file select
   */
  const onSuccess = (val) => {
    if (val !== null) {
      const extn = val?.name.split('.').pop();
      const fileSize = val?.size || val?.bytes || val?.sizeBytes;
      var filterFileName =
        file.filter((f) => {
          return f.name === val.name;
        }).length > 0;

      if (checkValidFormat(extn)) {
        if (checkValidSize(fileSize)) {
          if (!filterFileName) {
            clearErrors(name);
            const fileObject = {
              name: val.name,
              type: name,
              file: val,
            };
            const fileArray = [fileObject, ...file];
            setFile(fileArray);
            setSrc(URL.createObjectURL(val));
            handleUpload(fileArray, name);
            setLoading(false);
            if (!isMultifiles) {
              setFile(fileArray);
              setSelectedFile(val);
              setValue(name, { value: 'upload', label: 'Upload File' }, param);
            }
          } else {
            setValue(name, null, param);
            setSelectedFile({});
            toast.error(t('file-name-already-exists'));
            setLoading(false);
          }
        } else {
          setValue(name, null, param);
          setSelectedFile({});
          toast.error(t('the-document-size-should-not-exceed-3mb'));
          setLoading(false);
        }
      } else {
        setValue(name, null, param);
        toast.error(t('please-upload-document-with-the-following-file-type'));
        setLoading(false);
      }
    }
  };

  /**
   * @description Loader
   */
  const setLoader = () => {
    setLoading(true);
  };

  /**
   * @description To check valid file types
   * @param {*} extn file extension
   * @returns {boolean} Boolean
   */
  const checkValidFormat = (extn) => validFileTypes.includes(extn?.toUpperCase());

  /**
   * @description To check valid file size
   * @param {*} extn file size
   * @returns {boolean} Boolean
   */

  const checkValidSize = (fileSize) => fileSize < 3000000;

  /**
   * @description - On dropdown select
   * @param {*} value
   */
  const onSelect = (value) => {
    switch (value?.value) {
      case 'upload':
        if (file?.length === limit && name === 'Resume') {
          toast.error(t('resume-limit-exceeds'), {
            position: 'top-center',
          });
          return;
        } else if (file?.length === limit && name === 'Other') {
          toast.error(t('certification-limit-exceeds'), {
            position: 'top-center',
          });
          return;
        }
        normal.current.trigger();
        break;
      case 'dbox':
        dbox.current.trigger();
        break;
      case 'onedrive':
        oneD.current.trigger();
        break;
      case 'googledrive':
        gDrive.current.trigger();
        break;
      default:
        setValue(name, value, param);
        setSelectedFile(value?.data);
        break;
    }
  };

  /**
   * @description handle success file upload from dropbox
   * @param {*} url path of file
   * @param {*} name name of file
   * @returns {undefined} nothing
   */
  const handleSuccess = (url, name) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function () {
      const type =
        file?.name?.split('.').pop() === 'pdf'
          ? 'application/pdf'
          : 'application/msword';
      var base64 = `data:${type};base64,` + base64ArrayBuffer(xhr.response);
      const temp = dataURLtoFile(base64, name);
      onSuccess(temp);
    };
    xhr.send();
  };

  /**
   * @description - dropbox
   */
  const openFile = () => {
    var options = {
      clientId: config?.ONEDRIVE?.apiKey,
      action: 'download',
      multiSelect: true,
      success: function (files) {
        setLoader();
        const url = files.value[0]['@microsoft.graph.downloadUrl'];
        handleSuccess(url, files?.value[0].name);
      },
      error: function (e) {
        console.log('Error occurred while picking a file: ' + e, e);
      },
      advanced: {
        redirectUri: history?.location?.pathname,
      },
    };
    OneDrive.open(options);
  };

  const downloadSuccess = (fileName) => {
    toast.success(`${t('download-success-message')} ${fileName}`, {
      delay: 2000,
    });
  };

  const errorCallBack = (fileName) => {
    toast.error(`${t('download-error-message')} ${fileName}`, {
      delay: 2000,
    });
  };

  const handleDownload = () => {
    toast.success(`${t('downloading')} ${file[downloadIndex]?.name}`, {
      autoClose: 3000,
    });
    var ua = navigator?.userAgent;
    var msie = ua?.indexOf('MSIE');
    if (file[downloadIndex]?.id) {
      attachementDownloadApi(
        file[downloadIndex]?.name,
        `${downloadApiEndPoint}&fileID=${file[downloadIndex]?.id}`,
        downloadSuccess,
        errorCallBack
      );
    } else if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      var blob = new Blob([file[downloadIndex]], {
        type: 'text/plain;charset=utf-8;',
      });
      if (navigator.msSaveBlob) {
        handleDownloadConfirm();
        downloadSuccess(file[downloadIndex]?.name);
        return navigator.msSaveBlob(blob, file[downloadIndex].name);
      }
    } else {
      var link = document?.createElement('a');
      link.download = file[downloadIndex]?.name;
      link.href = src;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link = null;
      downloadSuccess(file[downloadIndex]?.name);
    }
    handleDownloadConfirm();
  };

  const handleNoDownload = () => {
    handleDownloadConfirm();
  };

  /**
   * @description to show file remove alert dialog
   * @returns {undefined} - nothing
   */
  const handleModal = () => {
    setShowModal(!showModal);
  };

  /**
   * @description to show download alert dialog
   * @returns {undefined} - nothing
   */
  const handleDownloadConfirm = () => {
    setConfirmDownload(!confirmDownload);
  };

  /**
   * @description - Remove file
   * @returns {undefined} - nothing
   */
  const handleYes = () => {
    if (file && file?.length > 0 && file[removeFileIndex]?.id) {
      handleDelete(file[removeFileIndex].id, name, removeFileIndex);
    } else {
      handleDelete(null, name, removeFileIndex);
    }
    setFileIndex('');
    const temp = file;
    delete temp[removeFileIndex];
    const newtemp = temp.filter((item) => item);
    setFile(newtemp);
    setValue(name, null, param);
    setSelectedFile({});
    setSrc('#');
    handleModal();
    if (newtemp.length === 0) setValue(name, null, param);
    if (
      requiredData?.fields?.value?.value &&
      file?.filter(function (e) {
        return e;
      }).length === 0
    ) {
      setError(name, {
        type: 'required',
      });
    }
  };

  /**
   * @description to show job alert dialog
   * @returns {undefined} - nothing
   */
  const handleNo = () => {
    handleModal();
    setFileIndex('');
  };

  const removeFile = (index) => {
    handleModal();
    setFileIndex(index);
    field.onBlur();
  };

  const clearFile = (index) => {
    if (file && file?.length > 0 && !file[index].id) {
      handleDelete(null, name, index);
      const temp = file;
      delete temp[removeFileIndex];
      const newtemp = temp.filter((item) => item);
      setFile(newtemp);
    }
    setSrc('#');
    setSelectedFile({});
    setValue(name, null, param);
    if (requiredData?.fields?.value?.value) {
      setError(name, {
        type: 'required',
      });
    }
    field.onBlur();
  };

  const downloadFile = (index) => {
    handleDownloadConfirm();
    setDownloadIndex(index);
  };
  const viewFile = (index) => {
    setViewPdfLoading(index);
    if (file[index]?.id) {
      attachementViewPdf(
        file[index]?.name,
        `${downloadApiEndPoint}&fileID=${file[index]?.id}`,
        errorCallBack,
        setViewPdfLoading
      );
    }
  };
  return (
    <>
      <div
        className={`select-wrapper form-block complex ${cssClass}`}
        style={customStyle}
        role="combobox"
        aria-label={label}
      >
        <div
          className={classNames(
            className,
            !selectedFile?.name || isMultifiles ? '' : 'hide-field'
          )}
        >
          <Controller
            className={className}
            name={name}
            control={control}
            render={({ onChange, onBlur, value }) => (
              <Select
                onChange={(selected) => {
                  onSelect(selected);
                }}
                onBlur={onBlur}
                value={value || ''}
                options={options}
                placeholder={
                  <div className="upload-placeholder">
                    {label}
                    {toolTipText && (
                      <TooltipComponent text={toolTipText}>
                        <div className="info-icon">i</div>
                      </TooltipComponent>
                    )}
                  </div>
                }
                isMulti={false}
                isClearable={selectedFile?.name && !isMultifiles ? true : false}
                autocomplete={false}
                isSearchable={false}
                // isDisabled={
                //   limit && file?.length === limit && isMultifiles ? true : false
                // }
                styles={StyleObject(fieldError)}
                components={{ ValueContainer: NewValueContainer }}
              />
            )}
          />
        </div>
        {selectedFile?.name && !isMultifiles && (
          <div className="file-select-container">
            <div className="label-container">
              {' '}
              <div className="upload-placeholder">
                {label}
                {toolTipText && (
                  <TooltipComponent text={toolTipText}>
                    <div className="info-icon">i</div>
                  </TooltipComponent>
                )}
              </div>
            </div>
            <div className="file-container">
              <div className="input-wrapper" onClick={() => downloadFile(0)}>
                {selectedFile?.name}
              </div>
              <div className="download-icon-blue" onClick={() => downloadFile(0)} />
              {isHardDelete ? (
                <div className="close-icon" onClick={() => removeFile(0)} />
              ) : (
                <div className="close-icon" onClick={() => clearFile(0)} />
              )}
            </div>
          </div>
        )}
        {loading && (
          <span
            className="spinner-wrapper-upload spinner-border spinner-border-sm"
            role="status"
          ></span>
        )}
        {fieldError && (
          <div className="error-msg" aria-label="this field is required">
            {fieldError.type === 'required'
              ? requiredData?.fields?.message?.value
                ? requiredData.fields.message.value.replace(
                    '{0}',
                    label.replace('*', '')
                  )
                : t('mandatory-field-message')
              : fieldError.message.replace('{0}', label.replace('*', ''))}
          </div>
        )}
        {hintText && <RichText field={{ value: hintText }} className="hintText" />}
        <div style={{ display: 'none' }} className="upload-config-cls">
          {' '}
          <NormalUpload
            onSuccess={onSuccess}
            ref={normal}
            config={config}
            id={id}
            name={name}
          />
          <DropBox
            onSuccess={onSuccess}
            ref={dbox}
            config={config}
            setLoader={setLoader}
          />
          <GoogleDrive
            onSuccess={onSuccess}
            ref={gDrive}
            config={config}
            setLoader={setLoader}
          />
          <OneDriveComponent openFile={openFile} ref={oneD} />
        </div>
        <Modal showModal={showModal} handleModal={handleModal} size="md">
          <div className="inner-modal-container" key="body">
            {t('would-you-like-to-delete-the-attached-document')}
            <div className="confirm-button-container" role="button" tabIndex={0}>
              <Button
                cssClass="yes-button"
                handleButtonClick={handleYes}
                text={t('yes')}
              />
              <Button handleButtonClick={handleNo} text={t('no')} />
            </div>{' '}
          </div>
        </Modal>
        <Modal
          showModal={confirmDownload}
          handleModal={handleDownloadConfirm}
          size="md"
        >
          <div className="inner-modal-container" key="body">
            {isNorthAmericanCountry(countryName) && name === 'Other'
              ? t('download-certification-confirmation')
              : t('download-confirmation')}{' '}
            <div className="confirm-button-container" role="button" tabIndex={0}>
              <Button
                cssClass="yes-button"
                handleButtonClick={handleDownload}
                text={t('yes')}
              />
              <Button handleButtonClick={handleNoDownload} text={t('no')} />
            </div>{' '}
          </div>
        </Modal>
        {isMultifiles && file?.length > 0 ? (
          <FileList
            data={file}
            removeFile={removeFile}
            t={t}
            downloadFile={downloadFile}
            viewFile={viewFile}
            viewPdfLoading={viewPdfLoading}
          />
        ) : null}
      </div>
    </>
  );
};

UploadBlock.propTypes = {
  label: PropTypes.string.isRequired,
  customStyle: PropTypes.shape({}).isRequired,
  t: PropTypes.func,
  readOnly: PropTypes.bool,
  hintText: PropTypes.string,
  isHardDelete: PropTypes.bool,
  limit: PropTypes.number,
};

UploadBlock.defaultProps = {
  label: 'Upload Resume',
  customStyle: {},
  readOnly: false,
  hintText: '',
  trigger: () => {},
  isHardDelete: true,
  limit: 5,
};

const mapStateToProps = (state) => {
  return {
    config: state.uploadConfigReducer.data,
  };
};

export default connect(mapStateToProps)(withSitecoreContext()(UploadBlock));
