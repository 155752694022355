/* eslint-disable complexity */
/* eslint-disable require-jsdoc */
/* eslint-disable max-lines-per-function */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Text, RichText, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import Checkbox from '../../../../../core/globals/forms/Checkbox';
import '../PaymentOptions/paymentOptions.scss';
import { CREATE_PROFILE, DASHBOARD } from '../../../../../../constants';
import Loader from '../../../../../core/ErrorBoundary/Loader';
import Error from '../../../../../core/ErrorBoundary/Error';
import DynamicForm from '../../../../../core/Forms/DynamicForm';
import { dataFetcher } from '../../../../../../dataFetcher';
import PaymentSuccessTemplate from './PaymentSuccessTemplate';

const PaymentMethodTemplate = ({
  type,
  fields,
  t,
  userData,
  handleModal,
  title,
  icons,
  description,
  info,
  consent,
  submitCta,
  cancelCta,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    control,
    trigger,
    watch,
    getValues,
  } = useForm({
    mode: 'onClick',
    reValidateMode: 'onBlur',
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [noMailingAddress, setNoMailingAddress] = useState(false);
  const [applicantDetails, setApplicantDetails] = useState({});

  const handleSavePaymentInfo = (values) => {
    if (
      type !== 'DirectDeposit' &&
      !userData?.MailingAddress?.city &&
      userData?.customText16 !== 'true'
    ) {
      setNoMailingAddress(true);
      return;
    }
    setNoMailingAddress(false);

    const defaultValues = {
      customEncryptedText2:
        type === 'DirectDeposit' ? values?.customEncryptedText2 : null,
      customText34: type === 'DirectDeposit' ? values?.customText34?.value : null,
      customText35: type === 'DirectDeposit' ? values?.customText35 : null,
      customText37: type === 'PayCard' ? type : null,
      customEncryptedText3:
        type === 'DirectDeposit' ? values.customEncryptedText3 : null,
      customEncryptedText4:
        type === 'DirectDeposit' ? values.customEncryptedText4 : null,
    };

    const postData = {
      EditPaymentDetails: {
        ...defaultValues,
        customText33: type,
        customText36: '1',
        customEncryptedText1:
          userData.sourceCountry === 'CA'
            ? '0' + values.customEncryptedText4 + values.customEncryptedText3
            : type === 'DirectDeposit'
            ? values?.customEncryptedText1
            : null,
      },
    };

    const formData = new FormData();
    formData.append('profileData', JSON.stringify(postData));
    setLoading(true);
    dataFetcher(`${DASHBOARD.PAYMENT_OPTIONS.SAVE_PAYMENT_INFO}`, 'POST', formData)
      .then((response) => {
        if (response?.data?.status === 1000) {
          handleGetCandidateDetailsAPI();
        } else {
          setLoading(false);
          setError(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setShowSuccessMessage(false);
        setLoading(false);
        setError(true);
      });
  };

  const handleGetCandidateDetailsAPI = () => {
    const inputParams = {
      entity: 'all',
      forceUpdate: false,
    };
    dataFetcher(
      `${CREATE_PROFILE.GET_CREATE_PROFILE.CANDIDATE_DETAILS}`,
      'GET',
      {},
      inputParams
    )
      .then((response) => {
        if (response?.data?.status === 1000) {
          setApplicantDetails(response?.data?.data);
          setLoading(false);
          setShowSuccessMessage(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setLoading(false);
      });
  };
  const continueBtn = () => {
    window.location.reload();
    handleModal();
  };
  return (
    <div className="payment-method">
      {loading ? (
        <Loader
          loaderMsg={t('saving-payment-information')}
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#C25700',
          }}
          customTextStyle={{ color: '#C25700' }}
        />
      ) : error ? (
        <>
          <div className="apiError">
            <Error errorMsg={t('error-message-payment')} t={t} />
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <button
                onClick={handleModal}
                className="primary-button reversed-button close-btn ml-0"
              >
                {cancelCta}
              </button>
            </div>
          </div>
        </>
      ) : showSuccessMessage ? (
        <PaymentSuccessTemplate
          t={t}
          type={type}
          userData={applicantDetails?.PersonalDetails?.data[0]}
          fields={fields}
          handleModal={continueBtn}
        />
      ) : (
        <>
          <div className="row">
            <div className="col  d-flex justify-content-center">
              <Text field={title} tag="h5" className="title" />
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col">
              <RichText field={description} className="subtitle" />
            </div>
          </div>
          {type !== 'DirectDeposit' ? (
            <>
              <div className="row text">
                <div className="col-md-4">
                  <p className="user-details-title">{t('full-name')}</p>
                  <p className="user-details-data">{`${userData?.firstName} ${userData?.lastName}`}</p>
                </div>
                {userData?.MailingAddress?.zip &&
                userData?.customText16 !== 'true' ? (
                  <div className="col-md-8">
                    <p className="user-details-title">{t('mailing-address')}</p>

                    <p className="user-details-data">
                      {userData?.MailingAddress?.address1 &&
                        `${userData?.MailingAddress?.address1}`}
                      {userData?.MailingAddress?.address1 && <br />}
                      {userData?.MailingAddress?.address2 &&
                        `${userData?.MailingAddress?.address2}`}
                      {userData?.MailingAddress?.address2 && <br />}
                      {userData?.MailingAddress?.city &&
                        `${userData?.MailingAddress?.city},`}{' '}
                      {userData?.MailingAddress?.state &&
                        `${userData?.MailingAddress?.state} `}
                      {userData?.MailingAddress?.zip &&
                        `${userData?.MailingAddress?.zip}`}
                    </p>
                  </div>
                ) : null}
                {userData?.address?.zip && userData?.customText16 === 'true' ? (
                  <div className="col">
                    <p className="user-details-title">{t('mailing-address')}</p>
                    <div className="location-payment">
                      <span className="search-icon-payment"></span>
                      <p className="location-item">
                        {userData?.address?.address1 &&
                          `${userData?.address?.address1}`}
                        {userData?.address?.address1 && <br />}
                        {userData?.address?.address2 &&
                          `${userData?.address?.address2}`}
                        {userData?.address?.address2 && <br />}
                        {userData?.address?.city && `${userData?.address?.city},`}
                        {userData?.address?.state && `${userData?.address?.state} `}
                        {userData?.address?.zip && `${userData?.address?.zip}`}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-1">
                  <img
                    className="info-icon"
                    src={mediaApi.updateImageUrl(icons.value.src)}
                    alt=""
                  />
                </div>
                <div className="col-11 pl-2">
                  <RichText field={info} className="information" />
                </div>
              </div>
            </>
          ) : (
            <DynamicForm
              formData={fields?.directDepositFields}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              control={control}
              trigger={trigger}
              watch={watch}
            />
          )}
          <div className="row">
            <div className="col consent">
              <Checkbox
                id="payment_consent"
                name="PaymentConsent"
                text={consent}
                required={true}
                register={register}
                errors={errors}
                t={t}
                error
              />
            </div>
          </div>
          {noMailingAddress ? (
            <div className="row d-flex pb-2">
              <div className="col">
                <div className="error-msg">
                  {t('missing-mailing-information-text')}
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col d-flex justify-content-center">
              <button
                className="primary-button orange-sd"
                onClick={handleSubmit(handleSavePaymentInfo)}
              >
                {submitCta}
              </button>
              <button
                onClick={handleModal}
                className="primary-button reversed-button close-btn"
              >
                {cancelCta}
              </button>
            </div>
          </div>
          {/* <div className="row my-4">
            <div className="col">
              <p className="user-details-data">
                {type === 'PayCard'
                  ? `${fields?.payCardModalBottomText?.value}`
                  : `${fields?.directDepositModalBottomText?.value}`}
              </p>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

PaymentMethodTemplate.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  consent: PropTypes.string.isRequired,
  submitCta: PropTypes.string.isRequired,
  cancelCta: PropTypes.string.isRequired,
  handleModal: PropTypes.func.isRequired,
};

export default withTranslation()(PaymentMethodTemplate);
