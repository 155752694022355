/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { Components } from '../Utils/helper';

import PrivateRoute from '../../core/PrivateRoute';
import { checkMobileSource } from '../../../utils/helperUtils';

/**
 * @description - Build Profile component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const ExploreJobFit = ({ rendering, t, sitecoreContext, dispatch, ...props }) => {
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  const { placeholders = [] } = rendering;
  return (
    <PrivateRoute countryName={countryName}>
      {!checkMobileSource() && (
        <section className="candidate-flow">
          {placeholders &&
            placeholders['jss-content'].map((block, index) => {
              const Component = block.componentName
                ? Components[block.componentName]
                : '';

              if (!Component) return false;
              return (
                <Component
                  t={t}
                  key={block?.uid}
                  fields={block?.fields}
                  componentName={block.componentName}
                  data={
                    block?.placeholders && block?.placeholders['jss-content']
                      ? block?.placeholders['jss-content']
                      : []
                  }
                />
              );
            })}
        </section>
      )}
    </PrivateRoute>
  );
};

ExploreJobFit.defaultProps = {
  ProfileContent: [],
  t: (val) => val,
  sitecoreContext: {},
  renderContent: [],
};

ExploreJobFit.propTypes = {
  ProfileContent: PropTypes.arrayOf(PropTypes.shape()),
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}),
  renderContent: PropTypes.arrayOf(PropTypes.shape()),
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    profileInfoFormData: state.profileInfoFormReducer,
    buildProfileFormState: state.buildProfileFormReducer,
    uploadConfig: state.uploadConfigReducer.data,
  };
};

export default connect(mapStateToProps)(
  withTranslation()(withPlaceholder('jss-content')(ExploreJobFit))
);

export const BuildProfileContext = React.createContext();
